import {
  Avatar,
  Badge,
  Button,
  // Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Skeleton,
  Tooltip,
} from "@nextui-org/react";
import { EditIcon } from "../../assets/icons/PencilIcon";
import Moment from "react-moment";
import PrimaryButton from "../common/PrimaryButton";
import { MenuIcon } from "../../assets/icons/MenuIcon";
import SecondaryButton from "../common/SecondaryButton";
// import { LocationIcon } from "../../assets/icons/LockationIcon";
import CropperModal from "./CropperModal";
import { CopyIcon } from "../../assets/icons/CopyIcon";
import { BlockIcon } from "../../assets/icons/LockIcon";
import { FlagIcon } from "../../assets/icons/FlagIcon";
import { Fragment, Key, useEffect, useMemo, useState } from "react";
import {
  deleteFriendRequest,
  getBlockedUsers,
  getFriendRequests,
  getFriends,
  respondFriendRequest,
  sendFriendRequest,
} from "../../api/friends";
import { toast } from "react-toastify";
import { useAuthStore } from "../../stores/authStore";
import { infoDetals, useUserStore } from "../../stores/userStore";
import { sexs } from "../../utils/selectOptions";
import { SexualOrientation } from "../../interfaces/SexualOrientation";
import {
  DeleteFriend,
  FriendIcon,
  GenderFemale,
  GenderMale,
} from "../../assets/icons/UserIcon";
import { useHomeStore } from "../../stores/homeData";
import {
  BlockedData,
  FriendReq,
  RequestData,
  User,
} from "../../interfaces/Login";
import LightBoxImage from "../common/LightBoxImage";
import { MessageIcon } from "../../assets/icons/ChatIcon";
import FriendshipModal from "../friend/FriendshipModal";
import { UsersIcon } from "../../assets/icons/UsersIcon";
import ReportUserModalpModal from "./ReportUserModal";
import { useDateFormatter } from "@react-aria/i18n";
// import ProfileEditModal from "./EditProfileModal";
// import { useStepperStore } from "../../stores/stepperStore";
import { Link } from "react-router-dom";
// import { useRegisterStore } from "../../stores/registerData";
import BlockUserModal from "./BlockUserModal";
import BuildingIcon from "../../assets/icons/BuildingIcon";
import { getGlobal } from "../../api/user";

const menuItems = [
  {
    key: "copy",
    icon: <CopyIcon />,
    label: "Copiar enlace al perfil",
  },
];

interface Global {
  friends: number;
  visits: number;
  reactions: number;
}

export default function ProfileCard({
  orientations,
  child,
  mobileChild,
  onEditClick,
}: {
  orientations: SexualOrientation[];
  child?: React.ReactNode;
  mobileChild?: React.ReactNode;
  onEditClick: () => void;
}) {
  const [isSendingrequest, setIsSendingrequest] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenLightbox, setIsOpenLightbox] = useState(false);
  const [isOpenFriendModal, setIsOpenFriendModal] = useState(false);
  const [isOpenBlockModal, setIsOpenBlockModal] = useState(false);
  // const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenReportModal, setIsOpenReportModal] = useState(false);
  const [isLoadingBlocked, setIsLoadingBlocked] = useState(false);
  const [iamgeSrc, setImage] = useState<string | undefined>();
  const [editType, setEditType] = useState<"avatar" | "cover">("avatar");

  const [friendRequests, setFriendRequests] = useState<RequestData[]>([]);
  const [blocked, setBlocked] = useState<BlockedData[]>([]);
  const [friendRequestsSent, setFriendRequestsSent] = useState<RequestData[]>(
    []
  );
  const [isLoadingRequests, setIsLoadingRequests] = useState(false);
  const [isLoadingGlobal, setIsLoadingGlobal] = useState(false);
  const [globalData, setGlobalData] = useState({
    friends: 0,
    visits: 0,
    reactions: 0,
  });
  // const [lockData, setlockData] = useState()

  const authData = useAuthStore((state) => state.user);
  const userData = useUserStore((state) => state.user);

  const homeData = useHomeStore((state) => state.home);
  const setHomeData = useHomeStore((state) => state.setHome);
  // const setStep = useStepperStore((state) => state.setStep);

  // // all steps data
  // const setStepTwoData = useRegisterStore((state) => state.setStepTwoData);
  // const setStepThreeData = useRegisterStore((state) => state.setStepThreeData);
  // const setStepFourData = useRegisterStore((state) => state.setStepFourData);

  const sendRequest = async () => {
    setIsSendingrequest(true);
    if (userData) {
      const response = await sendFriendRequest({ to_id: userData?.id });
      if (response.error == "true") toast.error(response.info.message + " ");
      else {
        toast.success("Solicitud enviada con éxito");
        if (response.info?.data[0]?.to)
          setFriendRequestsSent([
            ...friendRequestsSent,
            {
              ...response.info.data[0].to,
              reqId: response.info.data[0].id,
            },
          ]);
      }
    }
    setIsSendingrequest(false);
  };
  const onCancelRequest = async () => {
    const friend = friendRequestsSent?.find((e) => e.id == userData?.id);
    // console.log("friend", friend);
    if (friend) {
      const reqId = friend.reqId;
      setIsSendingrequest(true);
      const response = await deleteFriendRequest(reqId);
      if (response.error == "true" || !response.info)
        toast.error(response?.info?.message || "Ha ocurrido un error");
      else {
        toast.success("Solicitud cancelada con éxito");
        setFriendRequestsSent(
          friendRequestsSent.filter((e) => e.reqId != reqId)
        );
      }
      setIsSendingrequest(false);
    }
  };
  const acceptRequest = async () => {
    const friend = friendRequests?.find((e) => e.id == userData?.id);
    // console.log("friend", friend);
    if (friend) {
      setIsSendingrequest(true);
      const response = await respondFriendRequest(friend?.reqId, {
        status: "accept",
      });
      if (response.error == "true" || !response.info)
        toast.error(response?.info?.message || "Ha ocurrido un error");
      else {
        // console.log("response?.info?", response?.info);
        toast.success("Solicitud aceptada con éxito");
        setHomeData({
          ...homeData,
          amigos: [...homeData.amigos, response?.info.data[0]],
        });
        setFriendRequests(friendRequests.filter((e) => e.id != friend.id));
      }

      setIsSendingrequest(false);
    }
  };

  const handleEditClick = (type: "avatar" | "cover") => {
    if (userData?.id == authData?.id) {
      setEditType(type);
      setIsOpenModal(true);
    }
  };

  useEffect(() => {
    const getData = async () => {
      // --- Blocked users
      setIsLoadingGlobal(true);
      if (userData?.id != authData?.id) {
        setIsLoadingBlocked(true);
        const blocked = await getBlockedUsers();
        if (blocked?.info?.data[0]?.data) {
          setBlocked(blocked?.info?.data[0]?.data);
        }
        setIsLoadingBlocked(false);
        // --- Friend Requests
        setIsLoadingRequests(true);
        // Requests receibed
        const reqresponse = await getFriendRequests(0);
        //console.log("reqresponse", reqresponse?.info?.data[0].data);
        const data = (reqresponse?.info?.data[0]?.data as FriendReq[]).map(
          (e) => {
            return {
              ...(e.from as User),
              reqId: e.id,
            };
          }
        );
        // console.log("req", data);
        setFriendRequests(data ?? []);
        // Requests sent
        const reqSent = await getFriendRequests(1);
        //console.log("reqSent", reqSent.info?.data[0]?.data);

        const dataReq = (reqSent.info?.data[0]?.data as FriendReq[]).map(
          (e) => {
            return {
              ...(e.to as User),
              reqId: e.id,
            };
          }
        );
        setFriendRequestsSent(dataReq ?? []);
        if (authData?.id) {
          const response = await getFriends(authData.id.toString());
          const friends = response?.info?.data[0].data as FriendReq[];
          setHomeData({
            ...homeData,
            amigos: friends ?? [],
          });
        }
        setIsLoadingRequests(false);
      }
      if (userData?.id) {
        const global = await getGlobal(userData?.id);
        if (global) setGlobalData(global as Global);
      }
      setIsLoadingGlobal(false);
    };

    if (userData) getData();
  }, []);

  const isYourFriend = useMemo(() => {
    if (userData?.id == authData?.id) return false;
    const friend = homeData.amigos.find(
      (e) => e.from_id == userData?.id || e.to_id == userData?.id
    );
    // console.log("friend", friend);
    if (friend?.id) return true;
    return false;
  }, [userData, homeData]);

  const isFriendRequest = useMemo(() => {
    if (userData?.id == authData?.id || !friendRequests.length) return false;
    const friend = friendRequests?.find((e) => e.id == userData?.id);

    console.log("friendreq", friend);
    if (friend?.id) return true;
    return false;
  }, [userData, friendRequests]);

  const isFriendRequestSent = useMemo(() => {
    if (userData?.id == authData?.id || !friendRequestsSent.length)
      return false;
    const friend = friendRequestsSent?.find((e) => e.id == userData?.id);

    console.log("friendsent", friend?.id);
    if (friend?.id) return true;
    return false;
  }, [userData, friendRequestsSent]);

  const age = () => {
    if (!userData?.info?.detalles?.birthDate) return "?";
    const birthday = new Date(userData?.info?.detalles?.birthDate);
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    if (
      userData.info.is_couple &&
      userData?.info?.detalles?.partner_birthDate
    ) {
      const partnerBirthday = new Date(
        userData?.info?.detalles?.partner_birthDate
      );
      const partnerAgeDifMs = Date.now() - partnerBirthday.getTime();
      const partnerAgeDate = new Date(partnerAgeDifMs); // miliseconds from epoch
      const uAge = Math.abs(ageDate.getUTCFullYear() - 1970);
      const pAge = Math.abs(partnerAgeDate.getUTCFullYear() - 1970);
      if (uAge == pAge) return `ambos ${pAge}`;
      return `${uAge} y ${pAge}`;
    }
    //partner_birthDate
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const handleOpenLightBox = (type: "avatar" | "cover") => {
    let src = undefined;
    if (type == "cover")
      src = userData?.portada_path
        ? userData?.portada_path.startsWith("http")
          ? userData?.portada_path
          : import.meta.env.VITE_BASE_URL + userData?.portada_path
        : undefined;
    else
      src = userData?.profile_path
        ? userData?.profile_path.startsWith("http")
          ? userData?.profile_path
          : import.meta.env.VITE_BASE_URL + userData?.profile_path
        : undefined;
    setImage(src);
    setIsOpenLightbox(true);
  };
  const onSuccessDelete = () => {
    setHomeData({
      ...homeData,
      amigos: [
        ...homeData.amigos.filter(
          (e) => e.from_id != userData?.id && e.to_id != userData?.id
        ),
      ],
    });
  };

  const orientation = useMemo(() => {
    if (orientations.length) {
      const myO =
        infoDetals()?.sexualOrentation?.value &&
        orientations.find(
          (e) => e.id.toString() == infoDetals()?.sexualOrentation?.value
        )?.name;

      if (!userData?.info?.is_couple) return myO ?? "?";

      const partnerO =
        infoDetals()?.partner_sexualOrentation?.value &&
        orientations.find(
          (e) =>
            e.id.toString() == infoDetals()?.partner_sexualOrentation?.value
        )?.name;

      if (myO && partnerO) {
        if (myO == partnerO) return myO;
        return myO == "bisexual" ? partnerO : myO;
      }
      return "?";
    }
  }, []);
  const menuDangerItems = useMemo(() => {
    if (isLoadingBlocked)
      return [
        {
          key: "report",
          icon: <FlagIcon fill="red" />,
          label: "Denunciar perfil",
        },
      ];
    else if (blocked.findIndex((e) => e.to.id == userData?.id) == -1) {
      return [
        {
          key: "lock",
          icon: <BlockIcon fill="red" />,
          label: "Bloquear perfil",
        },
        {
          key: "report",
          icon: <FlagIcon fill="red" />,
          label: "Denunciar perfil",
        },
      ];
    } else
      return [
        {
          key: "unlock",
          icon: <BlockIcon fill="red" />,
          label: "Desbloquear perfil",
        },
        {
          key: "report",
          icon: <FlagIcon fill="red" />,
          label: "Denunciar perfil",
        },
      ];
  }, [blocked]);

  const formatter = useDateFormatter({ dateStyle: "medium" });

  return (
    <>
      <div
        className={`h-96 bg-gradient-default w-full bg-center cursor-pointer bg-cover relative`}
        style={{
          backgroundImage: ` url(${
            userData?.portada_path
              ? userData?.portada_path.startsWith("http")
                ? userData?.portada_path
                : import.meta.env.VITE_BASE_URL + userData?.portada_path
              : undefined
          })`,
        }}
        onClick={() => handleOpenLightBox("cover")}
      >
        {userData?.id == authData?.id && (
          <Button
            onClick={() => handleEditClick("cover")}
            isIconOnly
            aria-label="edit-cover-photo"
            className="absolute right-2 bottom-2 bg-color8"
          >
            <EditIcon height={24} width={24} fill={"#344054"} />
          </Button>
        )}
      </div>
      <div className="w-full sm:pl-6 px-4">
        <div className="-mt-14  rounded-full w-max relative">
          {userData?.id == authData?.id ? (
            <>
              <Avatar
                onClick={() => handleOpenLightBox("avatar")}
                showFallback
                classNames={{
                  base: "bg-zinc-100",
                  icon: "text-black/50",
                }}
                src={
                  userData?.profile_path
                    ? userData?.profile_path.startsWith("http")
                      ? userData?.profile_path
                      : import.meta.env.VITE_BASE_URL + userData?.profile_path
                    : undefined
                }
                className={`w-[100px] h-[100px] xl:w-[114px] xl:h-[114px] border-4 border-white cursor-pointer`}
              />
              <div
                onClick={() => handleEditClick("avatar")}
                className="absolute right-2 top-[60px] xl:top-[70px] xl:right-2 bg-white/60 p-1 rounded-full cursor-pointer"
              >
                <EditIcon height={24} width={24} fill={"#344054"} />
              </div>
            </>
          ) : (
            <Badge
              isOneChar
              content={
                userData?.role_id == "3" ? (
                  <BuildingIcon height={16} width={16}></BuildingIcon>
                ) : userData?.info?.is_couple ? (
                  <UsersIcon stroke="white" />
                ) : userData?.info?.detalles?.sex?.value == "f" ? (
                  <GenderFemale onClick={() => console.log(userData?.info)} />
                ) : (
                  <GenderMale />
                )
              }
              color="primary"
              size="lg"
              shape="circle"
              placement="bottom-right"
              className={`z-20 bottom-5  ${
                userData?.role_id == "3"
                  ? "bg-green-500"
                  : userData?.info?.is_couple
                  ? "bg-primary"
                  : userData?.info?.detalles?.sex?.value == "f"
                  ? "bg-pink-400"
                  : "bg-blue-400"
              }`}
            >
              <Avatar
                onClick={() => handleOpenLightBox("avatar")}
                showFallback
                classNames={{
                  base: "bg-zinc-100",
                  icon: "text-black/50",
                }}
                src={
                  userData?.profile_path
                    ? userData?.profile_path.startsWith("http")
                      ? userData?.profile_path
                      : import.meta.env.VITE_BASE_URL + userData?.profile_path
                    : undefined
                }
                className={`w-[100px] h-[100px] xl:w-[114px] xl:h-[114px] border-4 border-white cursor-pointer`}
              />
            </Badge>
          )}
        </div>
        <div className="w-full -mt-4  xl:-mt-8 flex md:justify-between  sm:px-6">
          {userData?.id == authData?.id &&
            userData?.suscription?.vence != undefined &&
            new Date(userData?.suscription?.vence) >= new Date() && (
              <div className="hidden  md:flex   flex-col items-start justify-start md:flex-row ml-28  md:ml-24 w-10/12">
                <div className="flex items-center bg-color4 rounded-md p-1 pr-4 pl-4">
                  <label className="font-medium text-[12px] text-white">
                    Premium hasta el{" "}
                    {formatter.format(new Date(userData?.suscription?.vence))}
                  </label>
                </div>
              </div>
            )}

          <div className="hidden md:flex w-full items-center justify-end gap-4">
            {isLoadingGlobal ? (
              <div className=" gap-2 hidden lg:flex items-center">
                <Skeleton className=" rounded-lg">
                  <p className="w-16 h-10"></p>
                </Skeleton>
                <Skeleton className=" rounded-lg">
                  <p className="w-16 h-10"></p>
                </Skeleton>
                <Skeleton className=" rounded-lg">
                  <p className="w-16 h-10"></p>
                </Skeleton>
              </div>
            ) : (
              <div className="  hidden lg:flex  items-center gap-2 ">
                <Tooltip content="Visitas" placement="bottom">
                  <div className="flex items-center gap-2 border-1 border-color2/15 text-color1 font-semibold  px-2 py-1 rounded-lg">
                    <div className="">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M24.1777 14C29.2737 14 29.2737 22 24.1777 22C19.0827 22 17.0447 14 11.4388 14C6.85375 14 6.85375 22 11.4388 22C17.0447 22 19.0828 14 24.1788 14H24.1777Z"
                          stroke="#FF01EE"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <span className="">{globalData.visits}</span>
                  </div>
                </Tooltip>

                <Tooltip content="Amigos" placement="bottom">
                  <div className="flex items-center gap-1 border-1 border-color2/15 text-color1 font-semibold  px-2 py-1 rounded-lg">
                    <div>
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M24.75 20.8573C26.0239 21.4972 27.1161 22.5242 27.9133 23.8084C28.0712 24.0627 28.1501 24.1899 28.1774 24.366C28.2329 24.7238 27.9882 25.1637 27.6549 25.3053C27.4909 25.375 27.3064 25.375 26.9375 25.375M23 17.0907C24.2965 16.4464 25.1875 15.1085 25.1875 13.5625C25.1875 12.0165 24.2965 10.6786 23 10.0343M21.25 13.5625C21.25 15.7371 19.4871 17.5 17.3125 17.5C15.1379 17.5 13.375 15.7371 13.375 13.5625C13.375 11.3879 15.1379 9.625 17.3125 9.625C19.4871 9.625 21.25 11.3879 21.25 13.5625ZM11.2393 23.571C12.6343 21.4765 14.8357 20.125 17.3125 20.125C19.7893 20.125 21.9906 21.4765 23.3857 23.571C23.6913 24.0299 23.8441 24.2593 23.8265 24.5524C23.8128 24.7806 23.6632 25.06 23.4808 25.1979C23.2467 25.375 22.9246 25.375 22.2804 25.375H12.3445C11.7004 25.375 11.3783 25.375 11.1441 25.1979C10.9618 25.06 10.8122 24.7806 10.7985 24.5524C10.7809 24.2593 10.9337 24.0299 11.2393 23.571Z"
                          stroke="#0038E5"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <span className="">{globalData.friends}</span>
                  </div>
                </Tooltip>
                <Tooltip content="Likes" placement="bottom">
                  <div className="flex items-center gap-2 border-1 border-color2/15 text-color1 font-semibold px-2 py-1 rounded-lg">
                    <div>
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.5455 16.9254C22.9195 16.261 23.2313 15.6615 23.4236 15.2052C24.3573 12.9895 23.434 10.4408 21.1769 9.40112C18.9199 8.36148 16.6534 9.4395 15.6587 11.5209C13.7566 10.2157 11.2192 10.4074 9.81989 12.4442C8.42059 14.4811 8.85975 17.142 10.7763 18.594C11.6461 19.253 13.3664 20.2242 14.986 21.0884M23.2972 18.7499C22.8751 16.482 20.9454 14.8233 18.5156 15.2742C16.0859 15.725 14.5149 17.9171 14.8433 20.299C15.1072 22.2127 16.5639 26.7027 17.1264 28.394C17.2032 28.6248 17.2415 28.7402 17.3175 28.8206C17.3837 28.8907 17.4717 28.9416 17.5655 28.9638C17.6732 28.9894 17.7923 28.9649 18.0306 28.916C19.7765 28.5575 24.3933 27.574 26.1826 26.8457C28.4096 25.9392 29.5589 23.4841 28.6981 21.153C27.8372 18.8219 25.4723 17.9815 23.2972 18.7499Z"
                          stroke="#00C800"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <span className="">{globalData.reactions}</span>
                  </div>
                </Tooltip>
              </div>
            )}
            {isLoadingBlocked ? (
              <div className="flex gap-2">
                <Skeleton className=" rounded-lg">
                  <p className="w-28 h-10"></p>
                </Skeleton>
                <Skeleton className=" rounded-lg">
                  <p className="w-28 h-10"></p>
                </Skeleton>
              </div>
            ) : (
              blocked.findIndex((e) => e.to.id == userData?.id) == -1 && (
                <Fragment>
                  {authData?.id != userData?.id && (
                    <Link to="/chat">
                      <PrimaryButton>
                        <div className="flex items-center gap-2">
                          <MessageIcon />
                          <span className="hidden lg:inline">
                            Enviar un mensaje
                          </span>
                        </div>
                      </PrimaryButton>
                    </Link>
                  )}
                  {isLoadingRequests ? (
                    <PrimaryButton className="bg-primary/5 text-primary border-2 border-primary/20">
                      <div className="flex items-center gap-2">
                        <UsersIcon stroke="#A24BFD"></UsersIcon>
                        <span className="inline">Loading...</span>
                      </div>
                    </PrimaryButton>
                  ) : isYourFriend ? (
                    <PrimaryButton
                      className="bg-red-600/5 text-red-600 border-2 border-red-600/20"
                      onClick={() => setIsOpenFriendModal(true)}
                    >
                      <div className="flex items-center gap-200">
                        <span className="inline">Eliminar amistad</span>
                      </div>
                    </PrimaryButton>
                  ) : isFriendRequest ? (
                    <PrimaryButton
                      className="bg-primary/5 text-primary border-2 border-primary/20"
                      onClick={acceptRequest}
                      loading={isSendingrequest}
                    >
                      <div className="flex items-center gap-2">
                        <FriendIcon stroke="#A24BFD"></FriendIcon>
                        <span className="hidden lg:inline">
                          Aceptar solicitud
                        </span>
                      </div>
                    </PrimaryButton>
                  ) : isFriendRequestSent ? (
                    <PrimaryButton
                      className="bg-primary/5 text-primary border-2 border-primary/20"
                      onClick={onCancelRequest}
                      loading={isSendingrequest}
                    >
                      <div className="flex items-center gap-2">
                        <UsersIcon stroke="#A24BFD"></UsersIcon>
                        <span className="hidden lg:inline">
                          Cancelar solicitud
                        </span>
                      </div>
                    </PrimaryButton>
                  ) : !isYourFriend && authData?.id != userData?.id ? (
                    <PrimaryButton
                      onClick={sendRequest}
                      loading={isSendingrequest}
                      className="bg-primary/5 text-primary border-2 border-primary/20"
                    >
                      <div className="flex items-center gap-2">
                        <FriendIcon stroke="#A24BFD"></FriendIcon>
                        <span className="hidden lg:inline">
                          Solicitud de amistad
                        </span>
                      </div>
                    </PrimaryButton>
                  ) : (
                    <></>
                  )}
                </Fragment>
              )
            )}

            <Dropdown>
              <DropdownTrigger>
                <Button
                  className="border-1"
                  variant="bordered"
                  isIconOnly
                  aria-label="menu"
                >
                  <MenuIcon />
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                variant="flat"
                aria-label="Dropdown menu with icons"
                onAction={async (key: Key) => {
                  if (key == "report") {
                    setIsOpenReportModal(true);
                  } else if (key == "copy") {
                    await navigator.clipboard.writeText(location.href);
                  } else if (key == "lock" || key == "unlock") {
                    setIsOpenBlockModal(true);
                  }
                }}
              >
                <DropdownSection
                  title=""
                  showDivider={userData?.id != authData?.id}
                >
                  {menuItems.map((e) => (
                    <DropdownItem
                      key={e.key}
                      startContent={e.icon}
                      color="primary"
                    >
                      {e.label}
                    </DropdownItem>
                  ))}
                </DropdownSection>

                <DropdownSection
                  title=""
                  className={userData?.id == authData?.id ? "hidden" : ""}
                >
                  {menuDangerItems.map((e) => (
                    <DropdownItem
                      key={e.key}
                      className="text-danger"
                      color="danger"
                      startContent={e.icon}
                    >
                      {e.label}
                    </DropdownItem>
                  ))}
                </DropdownSection>
                {/* <DropdownSection
                  className={userData?.id == authData?.id ? "hidden" : ""}
                >
                  <DropdownItem isReadOnly>
                    {isLoadingRequests ? (
                      <div className="flex items-center gap-2">
                        <UsersIcon stroke="#A24BFD"></UsersIcon>
                        <span className="inline text-primary">Loading...</span>
                      </div>
                    ) : isYourFriend ? (
                      <Button
                        size="sm"
                        className="bg-transparent m-0 text-danger-500 font-normal border-0 text-sm p-0"
                        onClick={() => setIsOpenFriendModal(true)}
                      >
                        <div className="flex items-center gap-2">
                          <span className="inline">Cancelar amistad</span>
                        </div>
                      </Button>
                    ) : isFriendRequest ? (
                      <Button
                        className=" bg-transparent m-0 font-normal border-0 text-sm p-0 text-primary"
                        onClick={acceptRequest}
                        size="sm"
                        isLoading={isSendingrequest}
                      >
                        <div className="flex items-center gap-x-2">
                          <FriendIcon width={16} stroke="#A24BFD"></FriendIcon>
                          <span className="inline text-primary">
                            Aceptar solicitud
                          </span>
                        </div>
                      </Button>
                    ) : isFriendRequestSent ? (
                      <Button
                        onClick={onCancelRequest}
                        isLoading={isSendingrequest}
                        size="sm"
                        className="bg-transparent m-0 text-danger-400 font-normal border-0 text-sm p-0"
                      >
                        <div className="flex items-center gap-2">
                          <UsersIcon width={16} stroke="red"></UsersIcon>
                          <span className="inline text-sm">
                            Cancelar solicitud
                          </span>
                        </div>
                      </Button>
                    ) : !isYourFriend && authData?.id != userData?.id ? (
                      <Button
                        onClick={sendRequest}
                        isLoading={isSendingrequest}
                        size="sm"
                        className="bg-transparent m-0 text-primary font-normal border-0 text-sm p-0"
                      >
                        <div className="flex items-center gap-x-2 ">
                          <FriendIcon width={16} stroke="#A24BFD"></FriendIcon>
                          <span className="inline text-sm">
                            Solicitud de amistad
                          </span>
                        </div>
                      </Button>
                    ) : (
                      <></>
                    )}
                  </DropdownItem>
                </DropdownSection> */}
              </DropdownMenu>
            </Dropdown>

            {userData?.id == authData?.id && (
              <SecondaryButton
                onClick={onEditClick}
                className="font-semibold text-[14px] text-color4"
              >
                Editar Perfil
              </SecondaryButton>
            )}
            <div className="lg:hidden">{mobileChild}</div>
          </div>
          <div className="flex flex-col w-full md:hidden justify-start items-end gap-3">
            <div className="flex  gap-3 justify-end items-start">
              <Dropdown>
                <DropdownTrigger>
                  <Button
                    className="border-1"
                    variant="bordered"
                    isIconOnly
                    aria-label="menu"
                  >
                    <MenuIcon />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  variant="flat"
                  aria-label="Dropdown menu with icons"
                  onAction={async (key: Key) => {
                    if (key == "report") {
                      setIsOpenReportModal(true);
                    } else if (key == "copy") {
                      await navigator.clipboard.writeText(location.href);
                    } else if (key == "lock" || key == "unlock") {
                      setIsOpenBlockModal(true);
                    }
                  }}
                >
                  <DropdownSection
                    title=""
                    showDivider={userData?.id != authData?.id}
                  >
                    {menuItems.map((e) => (
                      <DropdownItem
                        key={e.key}
                        startContent={e.icon}
                        color="primary"
                      >
                        {e.label}
                      </DropdownItem>
                    ))}
                  </DropdownSection>

                  <DropdownSection
                    title=""
                    className={userData?.id == authData?.id ? "hidden" : ""}
                  >
                    {menuDangerItems.map((e) => (
                      <DropdownItem
                        key={e.key}
                        className="text-danger"
                        color="danger"
                        startContent={e.icon}
                      >
                        {e.label}
                      </DropdownItem>
                    ))}
                  </DropdownSection>
                </DropdownMenu>
              </Dropdown>

              {userData?.id == authData?.id && (
                <SecondaryButton
                  onClick={onEditClick}
                  className="font-semibold text-[14px] text-color4"
                >
                  Editar Perfil
                </SecondaryButton>
              )}
            </div>
            <div className="lg:hidden">{mobileChild}</div>
          </div>
        </div>
      </div>
      <div className="flex justify-between lg:px-10 w-full  ">
        <div className="relative flex md:gap-7 w-full">
          <div className="-mt-14 md:-mt-3 w-full md:w-auto">
            <div className="flex gap-6 flex-wrap ml-6">
              <div className="">
                <div className="flex flex-col md:flex-row md:items-center">
                  <label className="font-semibold text-[30px] text-color3 flex ">
                    {userData?.name}
                  </label>
                </div>
                <ul className="flex flex-wrap items-center gap-3">
                  {userData?.id == authData?.id &&
                    userData?.suscription?.vence != undefined &&
                    new Date(userData?.suscription?.vence) >= new Date() && (
                      <li className=" flex md:hidden w-full  flex-col items-start justify-start ">
                        <div className="flex items-center bg-color4 rounded-md p-1 pr-4 pl-4">
                          <label className="font-medium text-[12px] text-white">
                            Premium hasta el{" "}
                            {formatter.format(
                              new Date(userData?.suscription?.vence)
                            )}
                          </label>
                        </div>
                      </li>
                    )}
                  <li className="w-full flex md:w-auto">
                    {userData?.last_conection && (
                      <div className=" gap-2 border-1 rounded-md p-0.5 pr-2 pl-2 ">
                        {/* <div className="w-2 h-2 bg-green-600 rounded-full "></div>
                      <label className="font-medium text-[12px] text-color2">
                        En linea
                      </label> */}
                        {/* TODO: check if  online*/}

                        <span className="px-2 text-[12px]">
                          Activo{" "}
                          <Moment utc fromNow className="">
                            {userData?.last_conection}
                          </Moment>
                        </span>
                      </div>
                    )}
                  </li>
                  <li className="">
                    <span className="font-normal text-[16px] text-color5">
                      {userData?.role_id == "3"
                        ? "Local"
                        : userData?.info?.is_couple
                        ? "Pareja"
                        : sexs.find((x) => x.id == infoDetals()?.sex?.value)
                            ?.name}{" "}
                      {userData?.role_id == "2" && orientation}
                    </span>
                  </li>
                  {userData?.role_id == "2" && (
                    <li>
                      <span className="font-medium text-[16px] text-color4">
                        {age()} años
                      </span>
                    </li>
                  )}
                </ul>
                {isLoadingGlobal ? (
                  <div className="flex gap-2 mt-4 lg:hidden">
                    <Skeleton className=" rounded-lg">
                      <p className="w-16 h-10"></p>
                    </Skeleton>
                    <Skeleton className=" rounded-lg">
                      <p className="w-16 h-10"></p>
                    </Skeleton>
                    <Skeleton className=" rounded-lg">
                      <p className="w-16 h-10"></p>
                    </Skeleton>
                  </div>
                ) : (
                  <div className="flex justify-between w-full flex-wrap gap-2 lg:hidden">
                    <div className=" flex lg:hidden mt-4 items-center gap-2 ">
                      <Tooltip content="Visitas" placement="bottom">
                        <div className="flex items-center gap-2 border-1 border-color2/15 text-color1 font-semibold  px-2 py-1 rounded-lg">
                          <div className="">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 37 37"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M24.1777 14C29.2737 14 29.2737 22 24.1777 22C19.0827 22 17.0447 14 11.4388 14C6.85375 14 6.85375 22 11.4388 22C17.0447 22 19.0828 14 24.1788 14H24.1777Z"
                                stroke="#FF01EE"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <span className="">{globalData.visits}</span>
                        </div>
                      </Tooltip>

                      <Tooltip content="Amigos" placement="bottom">
                        <div className="flex items-center gap-1 border-1 border-color2/15 text-color1 font-semibold  px-2 py-1 rounded-lg">
                          <div>
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 37 37"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M24.75 20.8573C26.0239 21.4972 27.1161 22.5242 27.9133 23.8084C28.0712 24.0627 28.1501 24.1899 28.1774 24.366C28.2329 24.7238 27.9882 25.1637 27.6549 25.3053C27.4909 25.375 27.3064 25.375 26.9375 25.375M23 17.0907C24.2965 16.4464 25.1875 15.1085 25.1875 13.5625C25.1875 12.0165 24.2965 10.6786 23 10.0343M21.25 13.5625C21.25 15.7371 19.4871 17.5 17.3125 17.5C15.1379 17.5 13.375 15.7371 13.375 13.5625C13.375 11.3879 15.1379 9.625 17.3125 9.625C19.4871 9.625 21.25 11.3879 21.25 13.5625ZM11.2393 23.571C12.6343 21.4765 14.8357 20.125 17.3125 20.125C19.7893 20.125 21.9906 21.4765 23.3857 23.571C23.6913 24.0299 23.8441 24.2593 23.8265 24.5524C23.8128 24.7806 23.6632 25.06 23.4808 25.1979C23.2467 25.375 22.9246 25.375 22.2804 25.375H12.3445C11.7004 25.375 11.3783 25.375 11.1441 25.1979C10.9618 25.06 10.8122 24.7806 10.7985 24.5524C10.7809 24.2593 10.9337 24.0299 11.2393 23.571Z"
                                stroke="#0038E5"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <span className="">{globalData.friends}</span>
                        </div>
                      </Tooltip>
                      <Tooltip content="Likes" placement="bottom">
                        <div className="flex items-center gap-2 border-1 border-color2/15 text-color1 font-semibold px-2 py-1 rounded-lg">
                          <div>
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 37 37"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M22.5455 16.9254C22.9195 16.261 23.2313 15.6615 23.4236 15.2052C24.3573 12.9895 23.434 10.4408 21.1769 9.40112C18.9199 8.36148 16.6534 9.4395 15.6587 11.5209C13.7566 10.2157 11.2192 10.4074 9.81989 12.4442C8.42059 14.4811 8.85975 17.142 10.7763 18.594C11.6461 19.253 13.3664 20.2242 14.986 21.0884M23.2972 18.7499C22.8751 16.482 20.9454 14.8233 18.5156 15.2742C16.0859 15.725 14.5149 17.9171 14.8433 20.299C15.1072 22.2127 16.5639 26.7027 17.1264 28.394C17.2032 28.6248 17.2415 28.7402 17.3175 28.8206C17.3837 28.8907 17.4717 28.9416 17.5655 28.9638C17.6732 28.9894 17.7923 28.9649 18.0306 28.916C19.7765 28.5575 24.3933 27.574 26.1826 26.8457C28.4096 25.9392 29.5589 23.4841 28.6981 21.153C27.8372 18.8219 25.4723 17.9815 23.2972 18.7499Z"
                                stroke="#00C800"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <span className="">{globalData.reactions}</span>
                        </div>
                      </Tooltip>
                    </div>
                    <div className=" flex md:hidden mt-4 items-center gap-2  ">
                      {" "}
                      {isLoadingBlocked ? (
                        <div className="flex gap-2">
                          <Skeleton className=" rounded-lg">
                            <p className="w-28 h-10"></p>
                          </Skeleton>
                          <Skeleton className=" rounded-lg">
                            <p className="w-28 h-10"></p>
                          </Skeleton>
                        </div>
                      ) : (
                        blocked.findIndex((e) => e.to.id == userData?.id) ==
                          -1 && (
                          <Fragment>
                            {authData?.id != userData?.id && (
                              <Link to="/chat">
                                <PrimaryButton>
                                  <div className="flex items-center gap-2">
                                    <MessageIcon />
                                  </div>
                                </PrimaryButton>
                              </Link>
                            )}
                            {isLoadingRequests ? (
                              <PrimaryButton className="bg-primary/5 text-primary border-2 border-primary/20">
                                <div className="flex items-center gap-2">
                                  <UsersIcon stroke="#A24BFD"></UsersIcon>
                                  <span className="inline">Loading...</span>
                                </div>
                              </PrimaryButton>
                            ) : isYourFriend ? (
                              <PrimaryButton
                                className="bg-red-600/5 text-red-600 border-2 border-red-600/20"
                                onClick={() => setIsOpenFriendModal(true)}
                              >
                                <div className="flex items-center gap-200">
                                  <DeleteFriend
                                    width={42}
                                    stroke="red"
                                  ></DeleteFriend>
                                  <span className="inline sm:hidden">
                                    Eliminar amistad
                                  </span>
                                </div>
                              </PrimaryButton>
                            ) : isFriendRequest ? (
                              <PrimaryButton
                                className="bg-primary/5 text-primary border-2 border-primary/20"
                                onClick={acceptRequest}
                                loading={isSendingrequest}
                              >
                                <div className="flex items-center gap-2">
                                  <FriendIcon stroke="#A24BFD"></FriendIcon>
                                  <span className="inline sm:hidden">
                                    Aceptar solicitud
                                  </span>
                                </div>
                              </PrimaryButton>
                            ) : isFriendRequestSent ? (
                              <PrimaryButton
                                className="bg-primary/5 text-primary border-2 border-primary/20"
                                onClick={onCancelRequest}
                                loading={isSendingrequest}
                              >
                                <div className="flex items-center gap-2">
                                  <UsersIcon stroke="#A24BFD"></UsersIcon>
                                  <span className="inline sm:hidden">
                                    Cancelar solicitud
                                  </span>
                                </div>
                              </PrimaryButton>
                            ) : !isYourFriend &&
                              authData?.id != userData?.id ? (
                              <PrimaryButton
                                onClick={sendRequest}
                                loading={isSendingrequest}
                                className="bg-primary/5 text-primary border-2 border-primary/20"
                              >
                                <div className="flex items-center gap-2">
                                  <FriendIcon stroke="#A24BFD"></FriendIcon>
                                  <span className="inline sm:hidden">
                                    Solicitud de amistad
                                  </span>
                                </div>
                              </PrimaryButton>
                            ) : (
                              <></>
                            )}
                          </Fragment>
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-6 flex-col mt-5">{child}</div>
      </div>
      <CropperModal
        type={editType}
        isOpen={isOpenModal}
        closeModal={() => setIsOpenModal(false)}
      />
      {iamgeSrc && (
        <LightBoxImage
          src={iamgeSrc}
          isOpen={isOpenLightbox}
          closeModal={() => setIsOpenLightbox(false)}
        />
      )}
      <FriendshipModal
        isOpen={isOpenFriendModal}
        closeModal={() => setIsOpenFriendModal(false)}
        name={userData?.name ?? ""}
        id={userData?.id ?? 0}
        onSuccessDelete={onSuccessDelete}
      />
      <ReportUserModalpModal
        isOpen={isOpenReportModal}
        closeModal={() => setIsOpenReportModal(false)}
        name={userData?.name ?? ""}
        id={userData?.id ?? 0}
      />
      <BlockUserModal
        isOpen={isOpenBlockModal}
        closeModal={() => setIsOpenBlockModal(false)}
        name={userData?.name ?? ""}
        type={
          blocked.findIndex((e) => e.to.id == userData?.id) == -1
            ? "lock"
            : "unlock"
        }
        id={
          blocked.findIndex((e) => e.to.id == userData?.id) == -1
            ? userData?.id!
            : blocked[blocked.findIndex((e) => e.to.id == userData?.id)].id
        }
        onSuccessDelete={(id: number) => {
          if (blocked.findIndex((e) => e.to.id == userData?.id) == -1) {
            setBlocked([
              ...blocked,
              {
                id,
                to: userData!,
              },
            ]);
          } else {
            setBlocked([...blocked.filter((e) => e.id != id)]);
          }
        }}
      />

      {/* <ProfileEditModal
        isOpen={isOpenEditModal}
        closeModal={() => {
          setIsOpenEditModal(false);
          setStep(2);
        }}
      /> */}
    </>
  );
}
