import {
  Card,
  CardBody,
  CardFooter,
  Modal,
  ModalContent,
  Image,
  Chip,
} from "@nextui-org/react";
import PrimaryButton from "../common/PrimaryButton";
import SecondaryButton from "../common/SecondaryButton";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import { Party, PartyRequest } from "../../interfaces/Party";
import { useState } from "react";
import { respondRequestJoinParty } from "../../api/party";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export default function PartyRequestsModal({
  isOpen = false,
  closeModal = () => {},
  onRequestAction,
  party,
}: {
  isOpen: boolean;
  closeModal?: () => void;
  onRequestAction: (type: string, req: PartyRequest) => void;
  party: Party;
}) {
  const [isLoading, setIsLoading] = useState(0);
  const [isRejecting, setIsRejecting] = useState(0);
  const [isDeleting, setIsDeleting] = useState(0);

  const onAcceptRequest = async (req: PartyRequest) => {
    setIsLoading(req.id);
    const payload = {
      accion: "aprobar",
    };
    try {
      const res = await respondRequestJoinParty(payload, req.id);
      // console.log(res);
      if (res?.error == "true" || !res.info) {
        toast.error("Ha ocurrido un error");
      } else {
        onRequestAction("aprobar", req);
        toast.success("Solicitud aceptada con éxito");
      }
    } catch (error) {
      console.error(error);
      toast.error("Ha ocurrido un error");
    }

    setIsLoading(0);
  };

  const onRejectRequest = async (req: PartyRequest) => {
    setIsRejecting(req.id);
    const payload = {
      accion: "denegar",
    };
    try {
      const res = await respondRequestJoinParty(payload, req.id);
      // console.log(res);
      if (res?.error == "true" || !res.info) {
        toast.error("Ha ocurrido un error");
      } else {
        onRequestAction("denegar", req);
        toast.success("Solicitud denegada con éxito");
      }
    } catch (error) {
      console.error(error);
      toast.error("Ha ocurrido un error");
    }

    setIsRejecting(0);
  };
  const onDeleteRequest = async (req: PartyRequest) => {
    setIsDeleting(req.id);
    const payload = {
      accion: "eliminar",
    };
    try {
      const res = await respondRequestJoinParty(payload, req.id);
      // console.log(res);
      if (res?.error == "true" || !res.info) {
        toast.error("Ha ocurrido un error");
      } else {
        onRequestAction("eliminar", req);
        toast.success("Solicitud eliminada con éxito");
      }
    } catch (error) {
      console.error(error);
      toast.error("Ha ocurrido un error");
    }

    setIsDeleting(0);
  };
  return (
    <Modal
      className=""
      isOpen={isOpen}
      placement="center"
      onClose={closeModal}
      classNames={{
        closeButton: "mt-4 mr-4",
        base: "rounded-xl border-none",
      }}
      closeButton={<CloseIcon />}
      scrollBehavior="inside"
      size="4xl"
    >
      <ModalContent>
        <div className="">
          <div className="flex ">
            <div className="text-start w-full px-6">
              <h3 className="mt-6 font-semibold text-color3 text-[18px]">
                Solicitudes para unirse a {party.nombre}
              </h3>
              <p className="mt-2 font-normal text-[14px] text-color5"></p>

              {party.solicitudes.length == 0 ? (
                <div className="h-[300px] mt-4 py-2 flex items-center justify-center gap-4 ">
                  <p className="text-center">
                    Parece que no tienes solicitudes pendientes
                  </p>
                </div>
              ) : (
                <div className="h-[350px] mt-4 py-2  gap-4 grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 overflow-auto w-full  px-6">
                  {party.solicitudes.map((e) => (
                    <Card
                      shadow="sm"
                      key={e.id}
                      radius="md"
                      className="w-full h-[280px]"
                    >
                      <CardBody className="overflow-visible p-0">
                        <Link to={`/profile/${e.user.id}`} key={e.user.id}>
                          <Image
                            shadow="none"
                            radius="md"
                            width="100%"
                            isZoomed
                            alt="alt"
                            className="w-full object-cover h-[140px]"
                            fallbackSrc="/assets/fantasyImage.jpeg"
                            src={
                              e.user?.profile_path
                                ? e.user?.profile_path?.startsWith("http")
                                  ? e.user?.profile_path
                                  : import.meta.env.VITE_BASE_URL +
                                    e.user?.profile_path
                                : undefined
                            }
                          />
                        </Link>
                      </CardBody>
                      <CardFooter className="text-small flex flex-col items-start">
                        <Link to={`/profile/${e.user.id}`} key={e.user.id}>
                          <b className="hover:text-primary">{e.user.name}</b>
                          <Chip
                            variant="flat"
                            color={
                              e.estado == "aprobado"
                                ? "primary"
                                : e.estado == "pendiente"
                                ? "warning"
                                : "danger"
                            }
                            className="mt-2 ml-2 capitalize"
                          >
                            {e.estado}
                          </Chip>
                        </Link>
                        <div className="w-full flex gap-2 mt-4">
                          {e.estado == "pendiente" ||
                            (e.estado == "aprobado" && (
                              <SecondaryButton
                                className="w-full"
                                disabled={isLoading == e.id}
                                loading={isRejecting == e.id}
                                onClick={() => onRejectRequest(e)}
                              >
                                Rechazar
                              </SecondaryButton>
                            ))}

                          {e.estado == "pendiente" && e.invitacion == 0 && (
                            <>
                              <PrimaryButton
                                className="w-full"
                                loading={isLoading == e.id}
                                onClick={() => onAcceptRequest(e)}
                              >
                                Aceptar
                              </PrimaryButton>
                              <SecondaryButton
                                className="w-full"
                                disabled={isLoading == e.id}
                                loading={isRejecting == e.id}
                                onClick={() => onRejectRequest(e)}
                              >
                                Rechazar
                              </SecondaryButton>
                            </>
                          )}
                          {e.estado == "pendiente" && e.invitacion == 1 && (
                            <PrimaryButton
                              className="w-full bg-red-600"
                              loading={isDeleting == e.id}
                              onClick={() => onDeleteRequest(e)}
                            >
                              Cancelar
                            </PrimaryButton>
                          )}
                        </div>
                      </CardFooter>
                    </Card>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="p-6 md:flex block justify-end border-t-1 border-color1/50 ">
            <div className="md:mt-0 mt-4 flex gap-[12px]">
              <SecondaryButton
                className="md:w-auto w-full"
                onClick={closeModal}
              >
                Cancelar
              </SecondaryButton>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
