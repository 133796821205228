import { FormEvent, useEffect, useMemo, useState } from "react";
import { useAuthStore } from "../../stores/authStore";
import {
  Card,
  Skeleton,
  Listbox,
  ListboxItem,
  Selection,
} from "@nextui-org/react";
// import { useParams } from "react-router-dom";
import { FriendReq, RequestData, User } from "../../interfaces/Login";
import { getFriendRequests, getFriends } from "../../api/friends";
import { FriendIcon } from "../../assets/icons/UserIcon";
import { UsersIcon } from "../../assets/icons/UsersIcon";
import CustomInput from "../form/CustomInput";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import SecondaryButton from "../common/SecondaryButton";
import { toast } from "react-toastify";
import FriendshipModal from "./FriendshipModal";
import FriendCard from "./FriendCard";
import { useHomeStore } from "../../stores/homeData";
import { useQuery } from "../../hooks/useQuery";
import { useNavigate } from "react-router-dom";

export default function FriendsTab({ userId }: { userId: string }) {
  // const [currentPage, setCurrentPage] = useState(1);
  // const onPageChange = (page: number) => setCurrentPage(page);

  const [search, setSearch] = useState("");
  const [selectedKeys, setSelectedKeys] = useState(new Set(["friends"]));
  const [friends, setFriends] = useState<RequestData[]>([]);
  const [friendRequests, setFriendRequests] = useState<RequestData[]>([]);
  const [friendRequestsSent, setFriendRequestsSent] = useState<RequestData[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState("friends");

  const authData = useAuthStore((state) => state.user);

  const [friendToDelete, setFriendToDelete] = useState<RequestData | null>(
    null
  );
  const setHome = useHomeStore((state) => state.setHome);
  const home = useHomeStore((state) => state.home);

  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      if (authData && userId) {
        try {
          const response = await getFriends(userId);
          // console.log("friends", response.info?.data[0].data);
          const friends = (response?.info?.data[0].data as FriendReq[]).map(
            (e) => {
              const user = e.to == "me" ? e.from : e.to;
              return {
                ...(user as User),
                reqId: e.id,
              };
            }
          );
          setFriends(friends ?? []);

          // Requests received
          const reqresponse = await getFriendRequests(0);
          // console.log("reqresponse", reqresponse?.info?.data[0].data);
          const data = (reqresponse?.info?.data[0]?.data as FriendReq[]).map(
            (e) => {
              return {
                ...(e.from as User),
                reqId: e.id,
              };
            }
          );
          // console.log("req", data);
          setFriendRequests(data ?? []);

          // Requests sent
          const reqSent = await getFriendRequests(1);
          // console.log("reqSent", reqSent.info?.data[0]?.data);

          const dataReq = (reqSent.info?.data[0]?.data as FriendReq[]).map(
            (e) => {
              return {
                ...(e.to as User),
                reqId: e.id,
              };
            }
          );
          setFriendRequestsSent(dataReq ?? []);
        } catch (error) {
          console.error(error);
          toast.error("Ha ocurrido un error cargando los datos");
        }
      }
      if (query.get("tab") == "friends" || query.get("tab") == null) {
        setSelectedKeys(new Set(["friends"]));
        setTab("friends");
      } else if (query.get("tab") == "requests") {
        setSelectedKeys(new Set(["requests"]));
        setTab("requests");
      } else {
        setSelectedKeys(new Set(["requestsSent"]));
        setTab("requestsSent");
      }

      setIsLoading(false);
    };
    if (authData) getData();
  }, [authData]);

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    setSearch(data.value);
  };

  const onSelectionChange = (key: Selection) => {
    // console.log(key != "all" ? key.keys() : "");
    const selected: string[] = [];

    if (key != "all")
      key.forEach((el) => {
        selected.push(el.toString());
      });

    if (selected.includes("friends")) {
      // console.log("WHYYY");
      if (query.get("tab") != null) navigate(`/friends/${userId}?tab=friends`);
      setTab("friends");
      setSelectedKeys(new Set(["friends"]));
    } else if (selected.includes("requests")) {
      setTab("requests");
      if (query.get("tab") != null) navigate(`/friends/${userId}?tab=requests`);
      setSelectedKeys(new Set(["requests"]));
    } else {
      setTab("requestsSent");
      if (query.get("tab") != null)
        navigate(`/friends/${userId}?tab=requestsSent`);
      setSelectedKeys(new Set(["requestsSent"]));
    }

    // setSelectedKeys(new Set([...selected]));
  };

  useEffect(() => {
    // console.log(query.get("tab"));
    if (query.get("tab") == "requests") {
      setTab("requests");
      setSelectedKeys(new Set(["requests"]));
    } else if (query.get("tab") == "friends" || query.get("tab") == null) {
      setTab("friends");
      setSelectedKeys(new Set(["friends"]));
    } else {
      setTab("requestsSent");
      setSelectedKeys(new Set(["requestsSent"]));
    }
  }, [query]);

  const data = useMemo(() => {
    if (selectedKeys.has("friends") || tab == "friends") return friends;
    if (selectedKeys.has("requests") || tab == "requests")
      return friendRequests;
    return friendRequestsSent;
  }, [selectedKeys, authData, friendRequestsSent, friends, friendRequests]);

  const onTabChange = (tab: string) => {
    setSelectedKeys(new Set([tab]));
    setTab(tab);
    if (query.get("tab") != null) navigate(`/friends/${userId}?tab=${tab}`);
    // console.log(tab);
  };

  const onSuccessDelete = () => {
    if (friendToDelete) {
      setFriends(friends.filter((e) => e.id != friendToDelete.id));
      setHome({
        ...home,
        amigos: [
          ...home.amigos.filter(
            (e) =>
              e.from_id != friendToDelete.id && e.to_id != friendToDelete.id
          ),
        ],
      });
    }
  };
  const onSuccessAccept = (friendToAccept: RequestData) => {
    setFriends([...friends, friendToAccept]);
    setFriendRequests(friendRequests.filter((e) => e.id != friendToAccept.id));
  };
  const onSuccessReject = (friendToreject: RequestData) => {
    setFriendRequests(friendRequests.filter((e) => e.id != friendToreject.id));
  };

  return (
    <>
      <div className="flex max-w-8xl w-full overflow-hidden pt-6 md:pt-18 min-h-[100vh] flex-col">
        <div className="px-4 md:px-8 w-full max-w-8xl mb-4">
          <section className="w-full ">
            <div className="mt-2 flex flex-col items-start">
              <h1 className="text-xl font-semibold">Amigos</h1>
            </div>
            <div className="my-2 flex lg:hidden gap-2 w-full justify-end pr-6 flex-wrap">
              <SecondaryButton
                className={`font-semibold text-[14px] ${
                  tab == "friends" ? "text-color4" : "text-color2"
                }`}
                onClick={() => onTabChange("friends")}
              >
                Amigos
              </SecondaryButton>
              {userId == authData?.id.toString() && (
                <>
                  <SecondaryButton
                    className={`font-semibold text-[14px] ${
                      tab == "requests" ? "text-color4" : "text-color2"
                    }`}
                    onClick={() => onTabChange("requests")}
                  >
                    Solicitudes recibidas
                  </SecondaryButton>
                  <SecondaryButton
                    className={`font-semibold text-[14px] ${
                      tab == "requestsSent" ? "text-color4" : "text-color2"
                    }`}
                    onClick={() => onTabChange("requestsSent")}
                  >
                    Solicitudes enviadas
                  </SecondaryButton>
                </>
              )}
            </div>
          </section>
        </div>
        <div className="max-w-8xl flex w-full">
          <div className="hidden lg:block w-4/12 max-h-main hide-scroll pl-2">
            <div className="w-full  px-1 py-2 rounded-small ">
              <Listbox
                variant="flat"
                aria-label="Listbox menu with descriptions"
                disallowEmptySelection
                selectionMode="single"
                selectedKeys={selectedKeys}
                onSelectionChange={onSelectionChange}
              >
                <ListboxItem
                  key="friends"
                  color="primary"
                  startContent={<UsersIcon width={20} height={20} />}
                  className={selectedKeys.has("friends") ? "item-selected" : ""}
                >
                  Amigos
                </ListboxItem>
                <ListboxItem
                  key="requests"
                  color="primary"
                  startContent={<FriendIcon />}
                  className={`${
                    selectedKeys.has("requests") ? "item-selected" : ""
                  } ${userId != authData?.id.toString() ? "hidden" : ""}`}
                >
                  Solicitudes de amistad
                </ListboxItem>
                <ListboxItem
                  key="requestsSent"
                  color="primary"
                  startContent={<FriendIcon />}
                  className={`${
                    selectedKeys.has("requestsSent") ? "item-selected" : ""
                  } ${userId != authData?.id.toString() ? "hidden" : ""}`}
                >
                  Solicitudes enviadas
                </ListboxItem>
              </Listbox>
            </div>
          </div>
          <div className="flex-grow flex flex-col  w-full  hide-scroll">
            <div className="w-full max-w-8xl px-4 md:px-14">
              <section className="w-full flex">
                <div className=" flex flex-col w-full">
                  <div className="w-full mb-2">
                    <CustomInput
                      className="hidden md:block text-input"
                      id="search"
                      type="text"
                      label=""
                      startIcon={<SearchIcon />}
                      placeholder="Buscar..."
                      required
                      value={search}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full gap-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {isLoading ? (
                      [0, 0, 0, 0].map((_, i) => (
                        <Card
                          className="w-full space-y-5 p-4 shadow-none border-1 border-gray-100"
                          radius="lg"
                          key={i}
                        >
                          <Skeleton className="rounded-lg">
                            <div className="h-24 rounded-lg bg-default-300"></div>
                          </Skeleton>
                          <div className="space-y-3">
                            <Skeleton className="w-3/5 rounded-lg">
                              <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                            </Skeleton>
                            <Skeleton className="w-4/5 rounded-lg">
                              <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                            </Skeleton>
                            <Skeleton className="w-2/5 rounded-lg">
                              <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                            </Skeleton>
                          </div>
                        </Card>
                      ))
                    ) : data.length == 0 ? (
                      <p>
                        No hay
                        {selectedKeys.has("friends")
                          ? " amigos "
                          : " solicitudes "}
                        para mostrar
                      </p>
                    ) : (
                      data.map((it) => (
                        <FriendCard
                          key={it.id}
                          data={it}
                          userId={parseInt(userId ?? "0")}
                          type={
                            selectedKeys.has("friends")
                              ? "friend"
                              : selectedKeys.has("requests")
                              ? "request"
                              : "requestSent"
                          }
                          setFriendToDelete={() => setFriendToDelete(it)}
                          setFriendRequestsSent={() =>
                            setFriendRequestsSent(
                              friendRequestsSent.filter(
                                (e) => e.reqId != it.reqId
                              )
                            )
                          }
                          onSuccessAccept={() => onSuccessAccept(it)}
                          onSuccessReject={() => onSuccessReject(it)}
                        />
                      ))
                    )}
                  </div>
                </div>
              </section>
            </div>
            {/* <div className="px-4 md:px-14 mt-16 w-full max-w-8xl">
              <div className="flex  sm:justify-center">
                <Pagination showControls total={10} initialPage={1} />
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <FriendshipModal
        isOpen={friendToDelete != null}
        closeModal={() => setFriendToDelete(null)}
        name={friendToDelete?.name ?? ""}
        id={friendToDelete?.id ?? 0}
        onSuccessDelete={onSuccessDelete}
      />
    </>
  );
}
