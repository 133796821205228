// import { useDateFormatter } from "@react-aria/i18n";
import { infoDetals, infoOthers, infoHowAmI } from "../../stores/userStore";
import { SexualOrientation } from "../../interfaces/SexualOrientation";
import {
  getHoroscopes,
  getLangueages,
  getProfesions,
} from "../../api/formSelectsData";
import { Lang } from "../../interfaces/Lang";
import { Profesion } from "../../interfaces/Profesion";
import { Horoscope } from "../../interfaces/Horoscope";
import {
  sexs,
  complexions,
  childs,
  races,
  eyesColor,
  hairColors,
  hairCuts,
  // smoke,
  skincolors,
  lipsOpt,
  tattoosCount,
  piercingCount,
  possibilitiesOfHosting,
  possibilitiesOfMoving,
} from "../../utils/selectOptions";
import { User } from "../../interfaces/Login";
import { useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionItem,
  Chip,
  Divider,
  Image,
} from "@nextui-org/react";
import { Province } from "../../interfaces/Provice";
import { Fantasy } from "../../interfaces/Fantasy";
import { getCountries } from "../../api/nationality";
import { Country } from "../../interfaces/Country";
import { useAuthStore } from "../../stores/authStore";
import SecondaryButton from "../common/SecondaryButton";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faScissors,
  faLanguage,
  faChildren,
  faBriefcase,
  faScaleBalanced,
  faVenusMars,
  faRuler,
  faRulerHorizontal,
  faWeightScale,
  faEarthAmerica,
  faPerson,
  faRulerVertical,
  faEye,
  faCar,
  faPeopleRoof,
  faLocationDot,
  faTransgender,
} from "@fortawesome/free-solid-svg-icons";

export function ProfileDetails({
  orientations,
  userData,
  provinces,
  fantasies,
  isLoading = false,
}: {
  orientations: SexualOrientation[];
  userData: User | null;
  provinces: Province[];
  fantasies: Fantasy[];
  isLoading?: boolean;
}) {
  const authData = useAuthStore((state) => state.user);

  const [countries, setCountries] = useState<Country[]>([]);
  const [loading, setLoading] = useState(false);

  const [info, setInfo] = useState({
    languages: [] as Lang[],
    profesions: [] as Profesion[],
    horoscope: [] as Horoscope[],
  });
  useEffect(() => {
    const getData = async () => {
      if (userData) {
        const languages = await getLangueages();
        const profesions = await getProfesions();
        const horoscope = await getHoroscopes();

        setInfo({
          ...info,
          languages: languages.info.data[0],
          profesions: profesions.info.data[0],
          horoscope: horoscope.info.data[0],
        });
      }
    };
    getData();
  }, [userData]);
  // let formatter = useDateFormatter({
  //   dateStyle: "long",
  // });
  // const parseBirthdate = (dateStr: string | undefined) => {
  //   if (!dateStr) return " ?";
  //   const date = new Date(dateStr);
  //   return formatter.format(date);
  // };
  const orientation = (data: string | undefined) => {
    if (orientations.length && data) {
      const res = orientations.find((e) => e.id.toString() == data)?.name;
      if (res) return res;
    }

    return "?";
  };
  const complexion = (data: string | undefined) => {
    if (!data) return "?";
    const comp = complexions.find((it) => it.id == parseInt(data));
    return comp?.name ?? "?";
  };
  const race = (data: string | undefined) => {
    if (!data) return "?";
    const res = races.find((it) => it.id == parseInt(data));
    return res?.name ?? "?";
  };
  const skin = (data: string | undefined) => {
    if (!data) return "?";
    const res = skincolors.find((it) => it.id == parseInt(data));
    return res?.name ?? "?";
  };
  const eyeColor = (data: string | undefined) => {
    if (!data) return "?";
    const res = eyesColor.find((it) => it.id == parseInt(data));
    return res?.name ?? "?";
  };
  const hairColor = (data: string | undefined) => {
    if (!data) return "?";
    const res = hairColors.find((it) => it.id == parseInt(data));
    return res?.name ?? "?";
  };
  const hairCut = (data: string | undefined) => {
    if (!data) return "";
    const res = hairCuts.find((it) => it.id == parseInt(data));
    return res?.name ?? "";
  };
  const lips = (data: string | undefined) => {
    if (!data) return "";
    const res = lipsOpt.find((it) => it.id == parseInt(data));
    return res?.name ?? "";
  };
  const tattoos = (data: string | undefined) => {
    if (!data) return "";
    const res = tattoosCount.find((it) => it.id == parseInt(data));
    return res?.name ?? "";
  };
  const piercings = (data: string | undefined) => {
    if (!data) return "";
    const res = piercingCount.find((it) => it.id == parseInt(data));
    return res?.name ?? "";
  };
  const hosting = (data: string | undefined) => {
    if (!data) return "";
    const res = possibilitiesOfHosting.find((it) => it.id == parseInt(data));
    return res?.name ?? "";
  };
  const moving = (data: string | undefined) => {
    if (!data) return "";
    const res = possibilitiesOfMoving.find((it) => it.id == parseInt(data));
    return res?.name ?? "";
  };
  const child = (data: string | undefined) => {
    if (!data) return "";
    const res = childs.find((it) => it.id == parseInt(data));
    return res?.name ?? "";
  };
  const lang = (data: string | undefined) => {
    if (!info.languages.length) return "Cargando...";
    if (!data) return "Español";
    const arr = data.split(",");
    const res = info.languages.filter((it) => arr.includes(it.id.toString()));
    return res.length ? res.map((e) => e.name).join(", ") : "Español";
  };
  const horoscop = (data: number | undefined) => {
    if (!info.horoscope.length) return "Cargando...";
    if (!data) return "?";
    const res = info.horoscope.find((it) => it.id == data);
    return res?.name ?? "?";
  };
  const profession = (data: number | undefined) => {
    if (!info.profesions.length) return "Cargando...";
    if (!data) return "?";
    const res = info.profesions.find((it) => it.id == data);
    return res?.name ?? "";
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const countriesRes = await getCountries();
      setCountries(countriesRes);
      setLoading(false);
    };
    getData();
  }, [authData]);

  const nationality = useMemo(() => {
    if (countries?.length && userData?.info?.detalles?.nacionalidad_id) {
      const aux = countries.find(
        (e) => e.cca2 == userData?.info?.detalles?.nacionalidad_id
      );
      if (aux) return aux.name.common;
    }
    return "España";
  }, [countries]);

  const partnerNationality = useMemo(() => {
    if (
      countries?.length &&
      userData?.info?.detalles?.partner_nacionalidad_id
    ) {
      const aux = countries.find(
        (e) => e.cca2 == userData?.info?.detalles?.partner_nacionalidad_id
      );
      if (aux) return aux.name.common;
    }
    return "España";
  }, [countries]);

  return (
    <>
      <div className="mt-10 flex justify-between px-6 lg:px-14 w-full min-w-8xl flex-wrap">
        <div className="w-full flex flex-col lg:flex-row lg:justify-between gap-4 lg:gap-8 lg:w-5/12 mt-5 px-6 lg:mt-0 pl-2 lg:pl-6">
          <div className="flex flex-col items-start  gap-2">
            <div className="fles flex-wrap">
              <span className="font-medium lg:text-[14px] text-color1 mr-2">
                <FontAwesomeIcon icon={faBriefcase} /> Profesión
              </span>

              <label>
                {isLoading
                  ? "Cargando..."
                  : profession(userData?.info?.profesion_id) ?? "..."}
              </label>
            </div>
            <div className="fles flex-wrap">
              <span className="font-medium lg:text-[14px] text-color1 mr-2">
                <FontAwesomeIcon icon={faScaleBalanced} /> Horóscopo
              </span>
              <label>
                {isLoading
                  ? "Cargando..."
                  : horoscop(userData?.info?.horoscopo_id) ?? "..."}
              </label>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-wrap items-center lg:items-start  gap-2">
              <span className="font-medium lg:text-[14px] text-color1 mr-2">
                <FontAwesomeIcon icon={faLanguage} /> Idiomas
              </span>
              <label>{lang(userData?.info?.idiomas_id)}</label>
            </div>
            <div className="flex flex-wrap items-center lg:items-start mt-2 gap-2">
              <span className="font-medium lg:text-[14px] text-color1 mr-2">
                <FontAwesomeIcon icon={faEarthAmerica} /> Raza
              </span>
              <label>{race(infoHowAmI()?.race?.value)}</label>
            </div>
            <div className="flex flex-wrap items-center lg:items-start mt-2 gap-2">
              <span className="font-medium lg:text-[14px] text-color1 mr-2">
                <FontAwesomeIcon icon={faPerson} /> Piel
              </span>
              <label>{skin(infoHowAmI()?.skin_color?.value)}</label>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col lg:flex-row lg:justify-between gap-4 lg:gap-8 lg:w-5/12 mt-5 px-6 lg:mt-0 pl-2 lg:pl-6">
          <div className="flex flex-col items-start  gap-2">
            <div className="flex flex-wrap gap-1">
              <span className="font-medium lg:text-[14px] text-color1 mr-2">
                <FontAwesomeIcon icon={faLocationDot} /> Ubicación
              </span>
              <label>
                {isLoading
                  ? " Cargando..."
                  : provinces?.length && userData?.info?.provincia_id
                  ? provinces.find((e) => e.id == userData?.info?.provincia_id)
                      ?.name
                  : "?"}
              </label>
            </div>
            <div className="flex flex-wrap gap-1">
              <span className="font-medium lg:text-[14px] text-color1 mr-2 flex items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 24 24"
                >
                  <g fill="none">
                    <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                    <path
                      fill="currentColor"
                      d="m17.068 2.001l.116.013a1 1 0 0 1 .822 1.15c-.43 2.58.088 5.416 1.428 8.43c1.174 2.643.82 5.424-.455 8.064a13.59 13.59 0 0 1-.632 1.165l-.232.364a7.37 7.37 0 0 1-.306.427a1 1 0 0 1-1.65-1.124l.072-.104c.361-.503.678-1.04.947-1.598c.79-1.636 1.143-3.297.879-4.883c-1.91.469-3.041 1.307-3.753 2.35c-.813 1.19-1.175 2.786-1.305 4.79a1.004 1.004 0 0 1-1 .955c-.53 0-.975-.425-.998-.956c-.13-2.003-.492-3.598-1.305-4.79c-.708-1.036-1.829-1.87-3.717-2.34c-.261 1.583.092 3.242.88 4.874c.215.445.458.882.734 1.294l.213.304a1 1 0 0 1-1.578 1.228a7.371 7.371 0 0 1-.306-.427l-.232-.364c-.2-.328-.417-.72-.632-1.165c-1.274-2.64-1.63-5.421-.455-8.064c1.34-3.014 1.858-5.85 1.428-8.43a1 1 0 1 1 1.972-.328c.482 2.89-.04 5.971-1.4 9.174c2.197.575 3.73 1.632 4.745 3.117c.252.37.468.76.652 1.167c.184-.407.4-.797.652-1.167c1.019-1.493 2.564-2.553 4.778-3.126c-1.356-3.2-1.878-6.278-1.396-9.165A1 1 0 0 1 17.068 2ZM12 8a1 1 0 1 1 0 2a1 1 0 0 1 0-2"
                    />
                  </g>
                </svg>{" "}
                Complexión
              </span>
              <label>{complexion(infoHowAmI()?.complexion.value)}</label>
            </div>
            {userData?.id != authData?.id && (
              <>
                <div className="hidden mt-2 lg:mt-4 lg:flex  gap-4 p-2 justify-center items-center border-1 border-color4 rounded-lg">
                  <div className="flex justify-center items-center w-[50px] h-[50px] rounded-full border-1.5 border-color4">
                    <span className="font-medium text-[14px] text-black">
                      {userData?.afinidad ?? "?"}%
                    </span>
                  </div>
                  <div className="flex flex-col gap-1 justify-center">
                    <span className="font-medium text-[14px] text-color1">
                      Afinidad
                    </span>
                    <span className="font-medium text-[16px] text-color2">
                      {(userData?.afinidad ?? 0) > 70
                        ? "Muy Alta"
                        : (userData?.afinidad ?? 0) > 50
                        ? "Alta"
                        : "Baja"}
                    </span>
                  </div>
                </div>
                <div className="flex lg:hidden gap-2 mt-3 items-center">
                  <Chip variant="flat" color="primary">
                    {userData?.afinidad ?? "?"}%
                  </Chip>
                  <span className="font-medium  text-color1">Afinidad</span>
                  <span className="font-medium  text-color2">
                    {(userData?.afinidad ?? 0) > 70
                      ? "Muy Alta"
                      : (userData?.afinidad ?? 0) > 50
                      ? "Alta"
                      : "Baja"}
                  </span>
                </div>
              </>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-wrap items-center lg:items-start  gap-2">
              <span className="font-medium lg:text-[14px] text-color1 mr-2">
                Nacionalidad
              </span>
              {loading ? (
                "Cargando..."
              ) : (
                <div className="flex items-center gap-2">
                  <Image
                    radius="none"
                    src={`https://flagcdn.com/${infoDetals()?.nacionalidad_id?.toLocaleLowerCase()}.svg`}
                    fallbackSrc="https://flagcdn.com/es.svg"
                    className="w-6 rounded-none"
                    loading="lazy"
                    classNames={{
                      wrapper:
                        "bg-center bg-no-repeat bg-contain border-1 border-color1/20",
                    }}
                  />
                  <label>{nationality}</label>
                </div>
              )}
            </div>
            <div className="flex flex-wrap items-center lg:items-start gap-3">
              <div className="flex flex-wrap items-start gap-2">
                <span className="font-medium lg:text-[14px] text-color1 mt-2">
                  Fantasías Destacadas
                </span>
                {isLoading ? (
                  "Cargando..."
                ) : fantasies.length ? (
                  fantasies.map((x) => (
                    <Link key={x.id} to={`/fantasy/${x.id}`}>
                      <SecondaryButton className="text-[14px] p-2 max-h-[36px]">
                        {x.name}
                      </SecondaryButton>
                    </Link>
                  ))
                ) : (
                  <p>Sin Fantasías</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Divider className="my-6 max-w-8xl  bg-zinc-200" />

      <div className="w-full flex flex-wrap px-4 lg:px-14">
        <Accordion
          defaultSelectedKeys={["1"]}
          className="w-full"
          fullWidth
          showDivider={false}
        >
          <AccordionItem
            key="1"
            aria-label="Ver mas"
            title="Más detalles"
            classNames={{
              trigger:
                "flex max-w-[200px] bg-color4/10 rounded-full text-primary p-1 px-4",
              base: "w-full mb-3",
              heading: "flex flex-row justify-center ",
              titleWrapper: "flex flex-row justify-center",
              title: "text-center  text-primary",
            }}
          >
            <div className="mt-10 flex justify-between md:px-6 lg:px-14 w-full flex-wrap ">
              <div className="w-full flex flex-col md:grid md:grid-cols-2 md:flex-row md:justify-between gap-4 lg:gap-8 lg:w-6/12 mt-5 px-6 lg:mt-0 pl-2 lg:pl-6">
                <div className="flex flex-col items-start  gap-2">
                  <div className="flex lg:flex-col">
                    <span className="font-medium lg:text-[14px] text-color1 mr-2">
                      <FontAwesomeIcon icon={faEye} /> Color de ojos
                    </span>
                    <label>{eyeColor(infoHowAmI()?.eye_color.value)}</label>
                  </div>
                  <div className="flex lg:flex-col">
                    <span className="font-medium lg:text-[14px] text-color1 mr-2 flex items-center gap-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                      >
                        <g fill="none">
                          <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                          <path
                            fill="currentColor"
                            d="M18.8 6.4c-1.884-2.512-4.28-3.9-6.8-3.9c-2.52 0-4.916 1.388-6.8 3.9c-1.417 1.889-3.126 4.44-3.362 7.124c-.121 1.384.147 2.801 1.019 4.15c.861 1.332 2.268 2.531 4.315 3.58c1.259.645 2.63-.22 2.809-1.483a6.48 6.48 0 0 1-1.162-1.001c-1.013-1.105-1.82-2.723-1.82-4.77c0-3.428 1.77-5.735 3.9-6.344c.282-.08.6.132.634.424a5 5 0 0 0 4.917 4.42c.674.006.55 1.06.55 1.5c0 2.047-.806 3.665-1.82 4.77c-.362.396-.76.734-1.164 1.003c.16 1.268 1.518 2.14 2.778 1.531c2.165-1.044 3.643-2.243 4.538-3.585c.907-1.362 1.163-2.797 1.007-4.194c-.3-2.69-2.138-5.257-3.54-7.125Z"
                          />
                        </g>
                      </svg>{" "}
                      Color del cabello
                    </span>
                    <label>{hairColor(infoHowAmI()?.hair_color.value)}</label>
                  </div>
                  <div className="flex lg:flex-col">
                    <span className="font-medium lg:text-[14px] text-color1 mr-2">
                      <FontAwesomeIcon icon={faScissors} /> Corte de cabello
                    </span>
                    <label> {hairCut(infoHowAmI()?.hair_length.value)}</label>
                  </div>
                </div>
                <div className="">
                  <div className="flex flex-col items-start  gap-2">
                    <div className="flex">
                      <span className="font-medium lg:text-[14px] text-color1 mr-2 flex items-center gap-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 48 48"
                        >
                          <g
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4"
                          >
                            <path d="M6 44V15c0-1.5 1-5.2 5-8L8.5 4c1.667 0 5.6.6 8 3c4.167-.333 13.5 2 10 8c-.89 1.335-2.5 2-5.5 2" />
                            <path d="M19 24c4 0 12 5 12 14c0 2 1 4.883 5.134 4.401C39 42.067 41.627 40.052 43 35.877" />
                            <path d="M17 44c0-4 2-9 10-9h3" />
                          </g>
                        </svg>{" "}
                        Tatuajes
                      </span>
                      <label> {tattoos(infoHowAmI()?.tattoos?.value)}</label>
                    </div>
                    <div className="flex">
                      <span className="font-medium lg:text-[14px] text-color1 mr-2 flex items-center gap-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M293.5 28.29h-5c-34.3.53-69.7 10.73-97.5 27.01c-40.3 23.61-68.1 72.3-75 118.5c-9 60.5 36.6 117 48.7 176.9c4.5 25.4 17.9 49.6 35.1 62.9c5.6-25.6 28.5-44.9 55.7-44.9c9.2 0 17.9 2.2 25.7 6.1c37.1-65.5 127.1-134.4 131.7-219c2.2-40.1-15.9-88.7-50.2-109.49c-20.1-12.17-44.3-17.71-69.2-18.02m-4.6 47.59c30.9.1 61.8 12.17 84.7 42.12l-14.2 11c-26.4-34.43-61.7-40.41-97-31.93c-35.3 8.43-69.5 32.73-87.4 57.93c-7.8 11.1-10.3 28.2-8.4 48.2s8.1 42.5 15.6 63.4c2.2 6.3 4.6 12.4 7 18.3c11.6-8.7 25.2-15.1 41.1-15.1c18.7 0 39.4 8.7 63.4 28.9l-11.6 13.8c-22.1-18.5-38.7-24.7-51.8-24.7c-12.6 0-23 5.4-33.9 14.3c10.9 24.5 20.4 41.6 20.4 41.6l-15.6 8.8s-20.5-36.3-36-79.9c-7.7-21.7-14.3-45.4-16.5-67.7c-2.1-22.3 0-43.8 11.7-60.3c20.8-29.4 57.6-55.39 97.8-65.03c10-2.41 20.3-3.69 30.7-3.69M354 337.7c-6.3 31.3-25.2 43.8-56.5 37.5c31.3 6.3 43.8 25.2 37.5 56.5c6.3-31.3 25.2-43.8 56.5-37.5c-31.3-6.3-43.8-25.2-37.5-56.5m-98.5 49c-21.6 0-39 17.4-39 39s17.4 39 39 39s39-17.4 39-39s-17.4-39-39-39m-111.3 28.4c11 22.9 5.1 39.8-17.8 50.8c22.9-11 39.8-5.1 50.8 17.8c-11-22.9-5.1-39.8 17.8-50.8c-22.9 11-39.8 5.1-50.8-17.8"
                          />
                        </svg>{" "}
                        Piercings
                      </span>
                      <label>{piercings(infoHowAmI()?.piercings?.value)}</label>
                    </div>
                    <div className="flex">
                      <span className="font-medium lg:text-[14px] text-color1 mr-2">
                        <FontAwesomeIcon icon={faChildren} /> Hijos
                      </span>
                      <label> {child(infoOthers()?.children.value)}</label>
                    </div>
                    {infoOthers()?.member_measurement?.value ? (
                      <>
                        <span className="font-medium lg:text-[14px] text-color1 mr-2">
                          <FontAwesomeIcon icon={faRuler} /> Tamaño del miembro
                        </span>
                        <label>
                          {" "}
                          {infoOthers()?.member_measurement?.value} cm
                        </label>
                      </>
                    ) : (
                      <></>
                    )}
                    {infoOthers()?.nipple_size?.value ? (
                      <>
                        <span className="font-medium lg:text-[14px] text-color1 mr-2">
                          <FontAwesomeIcon icon={faRulerHorizontal} /> Tamaño de
                          los pezones
                        </span>
                        <label>{infoOthers()?.nipple_size?.value} cm</label>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col md:grid md:grid-cols-2 md:justify-between gap-4 lg:gap-8 lg:w-6/12 mt-5 px-6 lg:mt-0 pl-2 lg:pl-16">
                <div className="flex flex-col items-start  gap-2">
                  <span className="font-medium lg:text-[14px] text-color1 mr-2">
                    <FontAwesomeIcon icon={faPeopleRoof} /> Capacidad para
                    alojar gente
                  </span>
                  <label>
                    {hosting(infoOthers()?.possibility_of_hosting.value)}
                  </label>
                  <span className="font-medium lg:text-[14px] text-color1 mr-2">
                    <FontAwesomeIcon icon={faCar} /> Capacidad para desplazarme
                  </span>
                  <label>
                    {moving(infoOthers()?.possibility_of_moving.value)}
                  </label>
                </div>
                <div className="flex flex-col items-start  gap-2">
                  <div className="flex">
                    <span className="font-medium lg:text-[14px] text-color1 mr-2">
                      <FontAwesomeIcon icon={faRulerVertical} /> Altura
                    </span>
                    <label>{infoHowAmI()?.height.value}cm</label>
                  </div>
                  <div className="flex">
                    <span className="font-medium lg:text-[14px] text-color1 mr-2">
                      <FontAwesomeIcon icon={faWeightScale} /> Peso
                    </span>
                    <label>{infoHowAmI()?.weight.value}kg</label>
                  </div>
                  <div className="flex">
                    <span className="font-medium lg:text-[14px] text-color1 mr-2 flex items-center gap-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        viewBox="0 0 64 64"
                      >
                        <path
                          fill="gray"
                          d="M54.738 29.2c-3.651 3.666-12.458-.012-22.747-.012s-19.1 3.678-22.747.012c-1.164 1.68-1.809 3.513-1.809 5.436c0 7.971 10.992 14.431 24.555 14.431c13.561 0 24.555-6.46 24.555-14.431c.002-1.923-.643-3.756-1.807-5.436"
                        />
                        <path
                          fill="currentColor"
                          d="M56.13 24.802c-5.303-7.05-10.697-9.345-10.697-9.345s-4.546-2.732-7.481 1.292c-1.884 2.587-4.391 3.682-5.955 4.139c-1.563-.457-4.071-1.551-5.955-4.139c-2.936-4.02-7.482-1.292-7.482-1.292s-5.396 2.299-10.696 9.345c-5.302 7.05-7.859 5.03-7.859 5.03s8.521 8.627 19.407 1.869c0 0 3.855-3.01 12.584.877c8.729-3.886 12.584-.877 12.584-.877c10.886 6.758 19.407-1.869 19.407-1.869s-2.555 2.01-7.857-5.03"
                        />
                        <path
                          fill="#f4f5f5"
                          d="M40.67 17.09s6.04-1.764 13.09 8.05c0 0-6.921-5.916-11.326-5.79s-1.764-2.264-1.764-2.264M20.14 43.18s-4.548 1.33-9.854-6.06c0 0 5.21 4.452 8.525 4.358c3.319-.096 1.329 1.704 1.329 1.704"
                        />
                      </svg>{" "}
                      Labios
                    </span>
                    <label>{lips(infoHowAmI()?.lips?.value)}</label>
                  </div>
                  <div className="flex">
                    <span className="font-medium lg:text-[14px] text-color1 mr-2">
                      <FontAwesomeIcon icon={faVenusMars} /> Sexo
                    </span>
                    <label>
                      {sexs.find((x) => x.id == infoDetals()?.sex?.value)?.name}
                    </label>
                  </div>
                  <div className="flex flex-wrap">
                    <span className="font-medium lg:text-[14px] text-color1 mr-2">
                      <FontAwesomeIcon icon={faTransgender} /> Orientación
                    </span>
                    <label>
                      {" "}
                      {isLoading
                        ? "Cargando..."
                        : orientation(infoDetals()?.sexualOrentation?.value)}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </AccordionItem>
          <AccordionItem
            hidden={!userData?.info?.is_couple}
            key="2"
            aria-label="Ver mas"
            title="Mi Pareja"
            classNames={{
              trigger:
                "flex max-w-[200px] bg-color4/10 rounded-full text-primary p-1 px-4",
              base: "w-full ",
              heading: "flex flex-row justify-center ",
              titleWrapper: "flex flex-row justify-center",
              title: "text-center  text-primary",
            }}
          >
            <div className="mt-10 flex justify-between md:px-6 lg:px-14 w-full min-w-8xl flex-wrap">
              <div className="w-full flex flex-col md:grid md:grid-cols-2 lg:flex-row lg:justify-between gap-4 lg:gap-8 lg:w-6/12 mt-5 px-6 lg:mt-0 pl-2 lg:pl-6">
                <div className="flex flex-col items-start  gap-2">
                  <div className="flex flex-wrap ">
                    <span className="font-medium lg:text-[14px] text-color1 mr-2">
                      <FontAwesomeIcon icon={faBriefcase} /> Profesión
                    </span>

                    <label>
                      {userData?.info?.otros?.partner_profesion_id?.value
                        ? profession(
                            parseInt(
                              userData?.info?.otros?.partner_profesion_id.value
                            )
                          )
                        : "?"}
                    </label>
                  </div>
                  <div className="flex flex-wrap ">
                    <span className="font-medium lg:text-[14px] text-color1 mr-2">
                      <FontAwesomeIcon icon={faScaleBalanced} /> Horóscopo
                    </span>
                    <label>
                      {" "}
                      {isLoading
                        ? "Cargando..."
                        : userData?.info?.otros?.partner_horoscopo_id?.value
                        ? horoscop(
                            parseInt(
                              userData?.info?.otros?.partner_horoscopo_id.value
                            )
                          )
                        : "?"}
                    </label>
                  </div>
                </div>
                <div className="flex flex-col ">
                  <div className="flex  items-center lg:items-start  gap-2">
                    <span className="font-medium lg:text-[14px] text-color1 mr-2">
                      <FontAwesomeIcon icon={faLanguage} /> Idiomas
                    </span>
                    <label>
                      {lang(
                        userData?.info?.otros?.partner_idioma_id?.value ?? ""
                      )}
                    </label>
                  </div>

                  <div className="flex  items-center lg:items-start mt-2 gap-2">
                    <span className="font-medium lg:text-[14px] text-color1 mr-2">
                      <FontAwesomeIcon icon={faEarthAmerica} /> Raza
                    </span>
                    <label>{race(infoHowAmI()?.partner_race?.value)}</label>
                  </div>
                  <div className="flex items-center lg:items-start mt-2 gap-2">
                    <span className="font-medium lg:text-[14px] text-color1 mr-2">
                      <FontAwesomeIcon icon={faPerson} /> Piel
                    </span>
                    <label>
                      {skin(infoHowAmI()?.partner_skin_color?.value)}
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col md:grid md:grid-cols-2 lg:flex-row lg:justify-between gap-4 lg:gap-8 lg:w-6/12 mt-5 px-6 lg:mt-0 pl-2 lg:pl-16">
                <div className="flex flex-col items-start  gap-2">
                  <div>
                    <span className="font-medium lg:text-[14px] text-color1 mr-2 flex items-center gap-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        viewBox="0 0 24 24"
                      >
                        <g fill="none">
                          <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                          <path
                            fill="currentColor"
                            d="m17.068 2.001l.116.013a1 1 0 0 1 .822 1.15c-.43 2.58.088 5.416 1.428 8.43c1.174 2.643.82 5.424-.455 8.064a13.59 13.59 0 0 1-.632 1.165l-.232.364a7.37 7.37 0 0 1-.306.427a1 1 0 0 1-1.65-1.124l.072-.104c.361-.503.678-1.04.947-1.598c.79-1.636 1.143-3.297.879-4.883c-1.91.469-3.041 1.307-3.753 2.35c-.813 1.19-1.175 2.786-1.305 4.79a1.004 1.004 0 0 1-1 .955c-.53 0-.975-.425-.998-.956c-.13-2.003-.492-3.598-1.305-4.79c-.708-1.036-1.829-1.87-3.717-2.34c-.261 1.583.092 3.242.88 4.874c.215.445.458.882.734 1.294l.213.304a1 1 0 0 1-1.578 1.228a7.371 7.371 0 0 1-.306-.427l-.232-.364c-.2-.328-.417-.72-.632-1.165c-1.274-2.64-1.63-5.421-.455-8.064c1.34-3.014 1.858-5.85 1.428-8.43a1 1 0 1 1 1.972-.328c.482 2.89-.04 5.971-1.4 9.174c2.197.575 3.73 1.632 4.745 3.117c.252.37.468.76.652 1.167c.184-.407.4-.797.652-1.167c1.019-1.493 2.564-2.553 4.778-3.126c-1.356-3.2-1.878-6.278-1.396-9.165A1 1 0 0 1 17.068 2ZM12 8a1 1 0 1 1 0 2a1 1 0 0 1 0-2"
                          />
                        </g>
                      </svg>{" "}
                      Complexión
                    </span>
                    <label>{complexion(infoHowAmI()?.complexion.value)}</label>
                  </div>
                  <div>
                    <span className="font-medium lg:text-[14px] text-color1 mr-2">
                      <FontAwesomeIcon icon={faRulerVertical} /> Altura
                    </span>
                    <label>{infoHowAmI()?.height.value}cm</label>
                  </div>
                  <div>
                    <span className="font-medium lg:text-[14px] text-color1 mr-2">
                      <FontAwesomeIcon icon={faWeightScale} /> Peso
                    </span>
                    <label> {infoHowAmI()?.weight.value}kg</label>
                  </div>
                </div>
                <div className="">
                  <div className="flex lg:flex-col items-center lg:items-start  gap-2">
                    <span className="font-medium lg:text-[14px] text-color1 mr-2">
                      Nacionalidad
                    </span>
                    {loading ? (
                      "Cargando..."
                    ) : (
                      <div className="flex items-center gap-2">
                        <Image
                          radius="none"
                          src={`https://flagcdn.com/${infoDetals()?.partner_nacionalidad_id?.toLocaleLowerCase()}.svg`}
                          fallbackSrc="https://flagcdn.com/es.svg"
                          className="w-6 rounded-none"
                          loading="lazy"
                          classNames={{
                            wrapper:
                              "bg-center bg-no-repeat bg-contain border-1 border-color1/20",
                          }}
                        />
                        <label>{partnerNationality}</label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10 flex justify-between md:px-6 lg:px-14 w-full flex-wrap ">
              <div className="w-full flex flex-col  md:grid md:grid-cols-2   lg:flex-row lg:justify-between gap-4 lg:gap-8 lg:w-6/12 mt-5 px-6 lg:mt-0 pl-2 lg:pl-6">
                <div className="flex flex-col items-start  gap-2">
                  <div className="flex lg:flex-col">
                    <span className="font-medium lg:text-[14px] text-color1 mr-2">
                      <FontAwesomeIcon icon={faEye} /> Color de ojos
                    </span>
                    <label>
                      {eyeColor(infoHowAmI()?.partner_eye_color?.value)}
                    </label>
                  </div>
                  <div className="flex lg:flex-col">
                    <span className="font-medium lg:text-[14px] text-color1 mr-2 flex items-center gap-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                      >
                        <g fill="none">
                          <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                          <path
                            fill="currentColor"
                            d="M18.8 6.4c-1.884-2.512-4.28-3.9-6.8-3.9c-2.52 0-4.916 1.388-6.8 3.9c-1.417 1.889-3.126 4.44-3.362 7.124c-.121 1.384.147 2.801 1.019 4.15c.861 1.332 2.268 2.531 4.315 3.58c1.259.645 2.63-.22 2.809-1.483a6.48 6.48 0 0 1-1.162-1.001c-1.013-1.105-1.82-2.723-1.82-4.77c0-3.428 1.77-5.735 3.9-6.344c.282-.08.6.132.634.424a5 5 0 0 0 4.917 4.42c.674.006.55 1.06.55 1.5c0 2.047-.806 3.665-1.82 4.77c-.362.396-.76.734-1.164 1.003c.16 1.268 1.518 2.14 2.778 1.531c2.165-1.044 3.643-2.243 4.538-3.585c.907-1.362 1.163-2.797 1.007-4.194c-.3-2.69-2.138-5.257-3.54-7.125Z"
                          />
                        </g>
                      </svg>{" "}
                      Color del cabello
                    </span>
                    <label>
                      {hairColor(infoHowAmI()?.partner_hair_color?.value)}
                    </label>
                  </div>
                  <div className="flex lg:flex-col">
                    <span className="font-medium lg:text-[14px] text-color1 mr-2">
                      <FontAwesomeIcon icon={faScissors} /> Corte de cabello
                    </span>
                    <label>
                      {" "}
                      {hairCut(infoHowAmI()?.partner_hair_length?.value)}
                    </label>
                  </div>
                </div>
                <div className="">
                  <div className="flex flex-col items-start  gap-2">
                    <div className="flex">
                      <span className="font-medium lg:text-[14px] text-color1 mr-2 flex items-center gap-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 48 48"
                        >
                          <g
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4"
                          >
                            <path d="M6 44V15c0-1.5 1-5.2 5-8L8.5 4c1.667 0 5.6.6 8 3c4.167-.333 13.5 2 10 8c-.89 1.335-2.5 2-5.5 2" />
                            <path d="M19 24c4 0 12 5 12 14c0 2 1 4.883 5.134 4.401C39 42.067 41.627 40.052 43 35.877" />
                            <path d="M17 44c0-4 2-9 10-9h3" />
                          </g>
                        </svg>{" "}
                        Tatuajes
                      </span>
                      <label>
                        {" "}
                        {tattoos(infoHowAmI()?.partner_tattoos?.value)}
                      </label>
                    </div>
                    <div className="flex">
                      <span className="font-medium lg:text-[14px] text-color1 mr-2 flex items-center gap-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M293.5 28.29h-5c-34.3.53-69.7 10.73-97.5 27.01c-40.3 23.61-68.1 72.3-75 118.5c-9 60.5 36.6 117 48.7 176.9c4.5 25.4 17.9 49.6 35.1 62.9c5.6-25.6 28.5-44.9 55.7-44.9c9.2 0 17.9 2.2 25.7 6.1c37.1-65.5 127.1-134.4 131.7-219c2.2-40.1-15.9-88.7-50.2-109.49c-20.1-12.17-44.3-17.71-69.2-18.02m-4.6 47.59c30.9.1 61.8 12.17 84.7 42.12l-14.2 11c-26.4-34.43-61.7-40.41-97-31.93c-35.3 8.43-69.5 32.73-87.4 57.93c-7.8 11.1-10.3 28.2-8.4 48.2s8.1 42.5 15.6 63.4c2.2 6.3 4.6 12.4 7 18.3c11.6-8.7 25.2-15.1 41.1-15.1c18.7 0 39.4 8.7 63.4 28.9l-11.6 13.8c-22.1-18.5-38.7-24.7-51.8-24.7c-12.6 0-23 5.4-33.9 14.3c10.9 24.5 20.4 41.6 20.4 41.6l-15.6 8.8s-20.5-36.3-36-79.9c-7.7-21.7-14.3-45.4-16.5-67.7c-2.1-22.3 0-43.8 11.7-60.3c20.8-29.4 57.6-55.39 97.8-65.03c10-2.41 20.3-3.69 30.7-3.69M354 337.7c-6.3 31.3-25.2 43.8-56.5 37.5c31.3 6.3 43.8 25.2 37.5 56.5c6.3-31.3 25.2-43.8 56.5-37.5c-31.3-6.3-43.8-25.2-37.5-56.5m-98.5 49c-21.6 0-39 17.4-39 39s17.4 39 39 39s39-17.4 39-39s-17.4-39-39-39m-111.3 28.4c11 22.9 5.1 39.8-17.8 50.8c22.9-11 39.8-5.1 50.8 17.8c-11-22.9-5.1-39.8 17.8-50.8c-22.9 11-39.8 5.1-50.8-17.8"
                          />
                        </svg>{" "}
                        Piercings
                      </span>
                      <label>
                        {piercings(infoHowAmI()?.partner_piercings?.value)}
                      </label>
                    </div>
                    <div className="flex">
                      <span className="font-medium lg:text-[14px] text-color1 mr-2">
                        <FontAwesomeIcon icon={faChildren} /> Hijos
                      </span>
                      <label>
                        {" "}
                        {child(infoOthers()?.partner_children?.value)}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col  md:grid md:grid-cols-2  lg:flex-row lg:justify-between gap-4 lg:gap-8 lg:w-6/12 mt-5 px-6 lg:mt-0 pl-2 lg:pl-16">
                <div className="flex flex-col items-start  gap-2">
                  <div className="flex flex-wrap">
                    <span className="font-medium lg:text-[14px] text-color1 mr-2 flex items-center gap-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        viewBox="0 0 64 64"
                      >
                        <path
                          fill="gray"
                          d="M54.738 29.2c-3.651 3.666-12.458-.012-22.747-.012s-19.1 3.678-22.747.012c-1.164 1.68-1.809 3.513-1.809 5.436c0 7.971 10.992 14.431 24.555 14.431c13.561 0 24.555-6.46 24.555-14.431c.002-1.923-.643-3.756-1.807-5.436"
                        />
                        <path
                          fill="currentColor"
                          d="M56.13 24.802c-5.303-7.05-10.697-9.345-10.697-9.345s-4.546-2.732-7.481 1.292c-1.884 2.587-4.391 3.682-5.955 4.139c-1.563-.457-4.071-1.551-5.955-4.139c-2.936-4.02-7.482-1.292-7.482-1.292s-5.396 2.299-10.696 9.345c-5.302 7.05-7.859 5.03-7.859 5.03s8.521 8.627 19.407 1.869c0 0 3.855-3.01 12.584.877c8.729-3.886 12.584-.877 12.584-.877c10.886 6.758 19.407-1.869 19.407-1.869s-2.555 2.01-7.857-5.03"
                        />
                        <path
                          fill="#f4f5f5"
                          d="M40.67 17.09s6.04-1.764 13.09 8.05c0 0-6.921-5.916-11.326-5.79s-1.764-2.264-1.764-2.264M20.14 43.18s-4.548 1.33-9.854-6.06c0 0 5.21 4.452 8.525 4.358c3.319-.096 1.329 1.704 1.329 1.704"
                        />
                      </svg>{" "}
                      Labios
                    </span>
                    <label>{lips(infoHowAmI()?.partner_lips?.value)}</label>
                  </div>

                  <div className="flex flex-wrap">
                    {infoOthers()?.partner_member_measurement?.value ? (
                      <>
                        <span className="font-medium lg:text-[14px] text-color1 mr-2">
                          <FontAwesomeIcon icon={faRuler} /> Tamaño del miembro
                        </span>
                        <label>
                          {" "}
                          {infoOthers()?.partner_member_measurement?.value} cm
                        </label>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="flex flex-wrap">
                    {infoOthers()?.partner_nipple_size?.value ? (
                      <>
                        <span className="font-medium lg:text-[14px] text-color1 mr-2">
                          <FontAwesomeIcon icon={faRulerHorizontal} /> Tamaño de
                          los pezones
                        </span>
                        <label>
                          {infoOthers()?.partner_nipple_size?.value} cm
                        </label>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="flex flex-col items-start  gap-2">
                  <div className="flex">
                    <span className="font-medium lg:text-[14px] text-color1 mr-2">
                      <FontAwesomeIcon icon={faVenusMars} /> Sexo
                    </span>
                    <label>
                      {
                        sexs.find(
                          (x) => x.id == infoDetals()?.partner_sex?.value
                        )?.name
                      }
                    </label>
                  </div>
                  <div className="flex flex-wrap">
                    <span className="font-medium lg:text-[14px] text-color1 mr-2">
                      <FontAwesomeIcon icon={faTransgender} /> Orientación
                    </span>
                    <label>
                      {" "}
                      {isLoading
                        ? "Cargando..."
                        : orientation(
                            infoDetals()?.partner_sexualOrentation?.value
                          )}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </AccordionItem>
        </Accordion>
      </div>
    </>
  );
}
