import { Modal, ModalContent, Progress } from "@nextui-org/react";
import PrimaryButton from "../common/PrimaryButton";
import SecondaryButton from "../common/SecondaryButton";
import FileInput from "../form/FileInput";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useAuthStore } from "../../stores/authStore";
import { toast } from "react-toastify";
import { uploadFile } from "../../api/upload";
import { round } from "../../utils/useNumber";
import { AxiosProgressEvent } from "axios";
import { updateUser } from "../../api/auth";

interface Data {
  profile_path?: string;
  portada_path?: string;
}

export default function PostUploadFile({
  isOpen = false,
  type = "avatar",
  closeModal = () => {},
}: {
  isOpen: boolean;
  type?: "avatar" | "cover";
  closeModal?: () => void;
}) {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [percentCompleted, setPercentCompleted] = useState(0);

  const inputRef = useRef(null);

  const user = useAuthStore((state) => state.user);
  const setUser = useAuthStore((state) => state.saveUser);
  // const [error, setError] = useState("");

  const onCancel = () => {
    setFile(null);
    setFileUrl("");
    closeModal();
  };

  const onFileUpload = () => {
    if (inputRef.current) (inputRef.current as HTMLInputElement).click();
  };
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    if (
      e.nativeEvent.target instanceof HTMLInputElement &&
      e.nativeEvent.target.files
    ) {
      // Aquí puedes procesar los archivos seleccionados
      const selectedFiles = Array.from(e.nativeEvent.target.files);
      //console.log(selectedFiles);
      if (selectedFiles.length > 0) {
        setFile(selectedFiles[0]);
      }
      // console.log(file);
    }
  };
  useEffect(() => {
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      // console.log(fileUrl);

      setFileUrl(fileUrl);

      return () => {
        URL.revokeObjectURL(fileUrl);
      };
    }
  }, [file]);

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const maxSize = 7.1;

    if (file && round(file.size / (1024 * 1024)) > maxSize) {
      toast.error(`El archivo no debe superar los ${maxSize} MB`);
      return;
    }
    if (!user) return;
    setLoading(true);
    try {
      const formData = new FormData();

      if (file) {
        formData.append("files[0]", file, file.name);
        formData.append("type", "image");
        formData.append("folder[0]", "profiles");
      }

      const uploadResponse = await uploadFile(formData, {
        onUploadProgress: (progressEvent: AxiosProgressEvent) =>
          setPercentCompleted(
            Math.round((progressEvent.loaded * 100) / progressEvent.total!)
          ),
      });

      if (uploadResponse.error == "true") {
        // TODO: check this
        toast.error(uploadResponse.info.message + " ");
        setLoading(false);
        return;
      }
      // console.log(uploadResponse);

      let data: Data = {
        profile_path: uploadResponse.info.data[0].profiles.file.content,
      };

      if (type == "cover") {
        data = {
          portada_path: uploadResponse.info.data[0].profiles.file.content,
        };
      }

      const responseData = await updateUser(user.id, data);
      // console.log(responseData);

      if (responseData.error == "true") {
        toast.error(responseData.info.message + " ");
      } else {
        toast.success(responseData.info.message);
        setUser({
          ...user,
          profile_path: responseData.info.data[0].profile_path,
          portada_path: responseData.info.data[0].portada_path,
        });

        setFile(null);
        setFileUrl("");
        setLoading(false);
        setPercentCompleted(0);
        closeModal();
      }
    } catch (error) {
      console.error(error);
      toast.error("Error desconocido");
    }
    setPercentCompleted(0);
    setLoading(false);
  }

  return (
    <Modal
      className="max-w-[420px]"
      isOpen={isOpen}
      placement="center"
      onClose={onCancel}
      classNames={{ closeButton: "mt-4 mr-4", base: "rounded-xl border-none" }}
      closeButton={
        <svg
          width="34"
          height="34"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 1L1 13M1 1L13 13"
            stroke="#667085"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
    >
      <ModalContent>
        <form onSubmit={handleSubmit} className="p-6">
          <div className="flex justify-start">
            <div className="text-start">
              <h3 className="font-semibold text-color3 text-[18px]">
                Actualizar foto de {type == "avatar" ? "perfil" : "portada"}
              </h3>
              <p className="mt-1 font-normal text-[14px] text-color5">
                Mantén tu perfil actualizado, ¡muestra al mundo quién eres!
              </p>
            </div>
          </div>
          <div className="mt-5 flex  justify-center">
            <div className="w-full flex flex-col items-center">
              <FileInput onClick={onFileUpload} file={fileUrl} type="image" />
              <input
                ref={inputRef}
                className="hidden"
                type="file"
                accept="image/*"
                onChange={handleChange}
              ></input>

              {loading && (
                <div className="flex items-center gap-3 mt-2 w-full">
                  <Progress
                    size="md"
                    aria-label="Loading..."
                    value={percentCompleted}
                    className=" h-[8px]"
                    classNames={{
                      indicator: "bg-color4",
                    }}
                  />
                  <label className="font-medium text-[14px] mb-0.5 ">
                    {percentCompleted}%
                  </label>
                </div>
              )}
            </div>
          </div>
          <div className="mt-8 flex gap-[12px]">
            <SecondaryButton
              className="w-full"
              onClick={onCancel}
              disabled={loading}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              className="w-full"
              loading={loading}
              disabled={!file}
              type="submit"
            >
              Actualizar
            </PrimaryButton>
          </div>
        </form>
      </ModalContent>
    </Modal>
  );
}
