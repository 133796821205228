import NavBar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import {
  Card,
  CardBody,
  CardFooter,
  Pagination,
  Image,
  Skeleton,
  Button,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Slider,
  SliderValue,
  SelectItem,
  Select,
  Avatar,
  Badge,
  Checkbox,
} from "@nextui-org/react";
import {
  races,
  complexions,
  eyesColor,
  hairColors,
  // pubicHairCut,
  noseTypes,
  skincolors,
  piercingCount,
  tattoosCount,
  drink,
  smoke,
  hairCuts,
  lipsOpt,
} from "../utils/selectOptions";
import { SearchIcon } from "../assets/icons/SearchIcon";
import { Link } from "react-router-dom";
import { FormEvent, useEffect, useState } from "react";
import { getUsers } from "../api/user";
import { User } from "../interfaces/Login";
import { Pagination as PaginationData } from "../interfaces/pagination";
import { FilterIcon } from "../assets/icons/FilterIcon";
import { Province } from "../interfaces/Provice";
import { Fantasy } from "../interfaces/Fantasy";
import {
  getFantasies,
  getHoroscopes,
  getLangueages,
  getProfesions,
  getProvinces,
} from "../api/formSelectsData";
import { GenderFemale, GenderMale } from "../assets/icons/UserIcon";
import { UsersIcon } from "../assets/icons/UsersIcon";
import { Country } from "../interfaces/Country";
import { getCountries } from "../api/nationality";
import { useAuthStore } from "../stores/authStore";
import { useQuery } from "../hooks/useQuery";
import { Lang } from "../interfaces/Lang";
import { Profesion } from "../interfaces/Profesion";
import { Horoscope } from "../interfaces/Horoscope";
import { withErrorHandler } from "../utils/withErrorFallback";
const initialFilters = {
  fantasias_id: new Set([]),
  provincias_id: new Set([]),
  complexions_id: new Set([]),
  races_id: new Set([]),
  eye_colors_id: new Set([]),
  skin_colors_id: new Set([]),
  hair_colors_id: new Set([]),
  nacionalidades_id: new Set([]),
  hair_length: new Set([]),
  lips: new Set([]),
  piercings: new Set([]),
  tattoos: new Set([]),
  you_drink: new Set([]),
  you_smoke: new Set([]),
  children: new Set([]),
  type_of_nose: new Set([]),
  profesion_id: new Set([]),
  idiomas_id: new Set([]),
  horoscopo_id: new Set([]),
};
const childs = [
  { id: 1, name: "Se lo reserva" },
  {
    id: 2,
    name: "Si",
  },
  {
    id: 3,
    name: "No",
  },
];
export default function Profiles() {
  const [search, setSearch] = useState("");
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    setSearch(data.value);
  };
  const authData = useAuthStore((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const query = useQuery();

  const [filters, setFilters] = useState(initialFilters);

  const [pagination, setPagination] = useState<PaginationData>({
    current_page: 0,
    last_page: 0,
    total: 0,
    per_page: 1,
  });
  const [byPremium, setByPremium] = useState(false);
  const [onlyOnline, setOnlyOnline] = useState(false);
  const [selectsData, setSelectsData] = useState({
    provinces: [] as Province[],
    fantasies: [] as Fantasy[],
    nationalities: [] as Country[],
    languages: [] as Lang[],
    profesions: [] as Profesion[],
    horoscope: [] as Horoscope[],
  });
  const [ageRange, setAgeRange] = useState<SliderValue>([18, 60]);
  const joinSet = (set: Set<string>) => {
    return Array.from(set).length > 0 ? Array.from(set).join(",") : undefined;
  };
  const getData = withErrorHandler(async (page: number) => {
    setIsLoading(true);
    const filtersData = {
      provincias_id: joinSet(filters.provincias_id),
      fantasias_id: joinSet(filters.fantasias_id),
      complexions_id: joinSet(filters.complexions_id),
      races_id: joinSet(filters.races_id),
      eye_colors_id: joinSet(filters.eye_colors_id),
      skin_colors_id: joinSet(filters.skin_colors_id),
      hair_colors_id: joinSet(filters.hair_colors_id),
      nacionalidades_id: joinSet(filters.nacionalidades_id),
      hair_length: joinSet(filters.hair_length),
      lips: joinSet(filters.lips),
      piercings: joinSet(filters.piercings),
      tattoos: joinSet(filters.tattoos),
      you_drink: joinSet(filters.you_drink),
      you_smoke: joinSet(filters.you_smoke),
      children: joinSet(filters.children),
      type_of_nose: joinSet(filters.type_of_nose),
      profesion_id: joinSet(filters.profesion_id),
      idiomas_id: joinSet(filters.idiomas_id),
      horoscopo_id: joinSet(filters.horoscopo_id),
      name: search ?? "",
      email: search ?? "",
      subscription_payed: byPremium ? 1 : undefined,
      edad_to: Array.isArray(ageRange) ? ageRange[1] : "",
      edad_from: Array.isArray(ageRange) ? ageRange[0] : "",
      status: onlyOnline ? "connected" : undefined,
    };

    const res = await getUsers(page, filtersData);
    setUsers(res.info.data[0].data);
    setPagination({
      current_page: res?.info?.data[0].current_page,
      last_page: res?.info?.data[0].last_page,
      total: res?.info?.data[0].total,
      per_page: res?.info?.data[0].per_page,
    });
    setIsLoading(false);
  });
  useEffect(() => {
    if (query.get("online")) {
      setOnlyOnline(true);
    }
    //if (authData) getData(1);
    const getSelectsData = withErrorHandler(async () => {
      const fantasies = await getFantasies();
      const provinces = await getProvinces();
      const countries = await getCountries();
      const languages = await getLangueages();
      const profesions = await getProfesions();
      const horoscope = await getHoroscopes();

      setSelectsData({
        ...selectsData,
        fantasies: fantasies.info.data[0],
        provinces: provinces.info.data[0],
        nationalities: countries,
        languages: languages.info.data[0],
        profesions: profesions.info.data[0],
        horoscope: horoscope.info.data[0],
      });
    });
    getSelectsData();
  }, []);

  const onPageChange = async (page: number) => {
    getData(page);
  };

  const handleChangeSelect = (e: FormEvent<HTMLSelectElement>) => {
    const data = e.target as HTMLSelectElement;
    //setFormData(data.name, data.value);
    let aux = data.value.split(",");
    if (aux.length && aux[0] == "") aux = aux.slice(1);
    setFilters({
      ...filters,
      [data.name]: new Set(aux),
    });
  };

  useEffect(() => {
    setTimeout(() => {
      getData(1);
    }, 600);
  }, [filters, byPremium, onlyOnline]);

  const age = (userData: User) => {
    if (!userData?.info?.detalles?.birthDate) return "?";
    const birthday = new Date(userData?.info?.detalles?.birthDate);
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    if (
      userData.info.is_couple &&
      userData?.info?.detalles?.partner_birthDate
    ) {
      const partnerBirthday = new Date(
        userData?.info?.detalles?.partner_birthDate
      );
      const partnerAgeDifMs = Date.now() - partnerBirthday.getTime();
      const partnerAgeDate = new Date(partnerAgeDifMs); // miliseconds from epoch
      const uAge = Math.abs(ageDate.getUTCFullYear() - 1970);
      const pAge = Math.abs(partnerAgeDate.getUTCFullYear() - 1970);
      if (uAge == pAge) return `ambos ${pAge}`;
      return `${uAge} y ${pAge}`;
    }
    //partner_birthDate
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };
  const handleReset = () => {
    setFilters(initialFilters);
    setByPremium(false);
    setAgeRange([18, 60]);
    setSearch("");
    setOnlyOnline(false);
  };

  return (
    <div className=" h-screen ">
      <NavBar />
      <main className="flex justify-center">
        <div className="flex max-w-8xl w-full overflow-hidden pt-6 md:pt-18 min-h-[100vh] flex-col md:px-6">
          <div className="px-4 text-2xl w-full max-w-8xl mb-4">
            <section className="w-full mb-4">
              <div className="mt-2 flex flex-col items-start">
                <h1 className=" font-semibold">Buscar perfiles</h1>
              </div>
            </section>
          </div>
          <div className="flex w-full h-full">
            <div className="hidden xl:flex w-3/12 ">
              <div className="px-0 py-2 w-full overflow-auto max-h-[calc(100vh-170px)]  bg-zinc-200/20 rounded-lg">
                <p className="font-bold text-foreground overflow-auto mb-2 px-2">
                  Filtros
                </p>
                <div className=" flex flex-col gap-4 w-full mt-3 px-3 pr-4">
                  <Checkbox
                    isSelected={onlyOnline}
                    onValueChange={() => setOnlyOnline(!onlyOnline)}
                    classNames={{
                      wrapper:
                        "border-small before:border-small rounded-[4px] before:rounded-[4px] after:rounded-[4px] after:bg-color4",
                      base: "font-medium text-[14px] text-color2",
                    }}
                  >
                    Solo en línea
                  </Checkbox>
                  <Select
                    selectionMode="multiple"
                    labelPlacement="outside"
                    variant="bordered"
                    label="Nacionalidad"
                    name="nacionalidades_id"
                    classNames={{
                      trigger: "h-[44px] rounded-lg border-small",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                      popoverContent: "rounded-md",
                    }}
                    className="max-w-xs"
                    onChange={handleChangeSelect}
                    placeholder={
                      selectsData.nationalities?.length
                        ? "Seleccione"
                        : "Loading..."
                    }
                    selectedKeys={filters.nacionalidades_id}
                  >
                    {selectsData.nationalities?.map((item) => (
                      <SelectItem key={item.cca2} value={item.cca2}>
                        {item.name.common}
                      </SelectItem>
                    ))}
                  </Select>
                  <Select
                    selectionMode="multiple"
                    variant="bordered"
                    label="Provincias"
                    name="provincias_id"
                    labelPlacement="outside"
                    classNames={{
                      trigger: "h-[44px] rounded-lg border-small",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                      popoverContent: "rounded-md",
                    }}
                    className="max-w-xs"
                    onChange={handleChangeSelect}
                    placeholder={
                      selectsData.provinces?.length
                        ? "Seleccione"
                        : "Loading..."
                    }
                    selectedKeys={filters.provincias_id}
                  >
                    {selectsData.provinces?.map((item) => (
                      <SelectItem
                        key={item.id.toString()}
                        value={item.id.toString()}
                      >
                        {item.name}
                      </SelectItem>
                    ))}
                  </Select>
                  <Select
                    name="fantasias_id"
                    selectionMode="multiple"
                    variant="bordered"
                    label="Fantasías"
                    labelPlacement="outside"
                    classNames={{
                      trigger: "h-[44px] rounded-lg border-small",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                      popoverContent: "rounded-md",
                    }}
                    className="max-w-xs"
                    onChange={handleChangeSelect}
                    placeholder={
                      selectsData.fantasies?.length
                        ? "Seleccione"
                        : "Loading..."
                    }
                    selectedKeys={filters.fantasias_id}
                  >
                    {selectsData.fantasies?.map((item) => (
                      <SelectItem
                        key={item.id.toString()}
                        value={item.id.toString()}
                      >
                        {item.name}
                      </SelectItem>
                    ))}
                  </Select>
                  <Select
                    selectionMode="multiple"
                    variant="bordered"
                    label="Idiomas"
                    name="idiomas_id"
                    labelPlacement="outside"
                    classNames={{
                      trigger: "h-[44px] rounded-lg border-small",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                      popoverContent: "rounded-md",
                    }}
                    className="max-w-xs"
                    onChange={handleChangeSelect}
                    placeholder={
                      selectsData.languages?.length
                        ? "Seleccione"
                        : "Loading..."
                    }
                    selectedKeys={filters.idiomas_id}
                  >
                    {selectsData.languages?.map((item) => (
                      <SelectItem
                        key={item.id.toString()}
                        value={item.id.toString()}
                      >
                        {item.name}
                      </SelectItem>
                    ))}
                  </Select>
                  <Select
                    selectionMode="multiple"
                    variant="bordered"
                    label="Horóscopo"
                    name="horoscopo_id"
                    labelPlacement="outside"
                    classNames={{
                      trigger: "h-[44px] rounded-lg border-small",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                      popoverContent: "rounded-md",
                    }}
                    className="max-w-xs"
                    onChange={handleChangeSelect}
                    placeholder={
                      selectsData.languages?.length
                        ? "Seleccione"
                        : "Loading..."
                    }
                    selectedKeys={filters.horoscopo_id}
                  >
                    {selectsData.horoscope?.map((item) => (
                      <SelectItem
                        key={item.id.toString()}
                        value={item.id.toString()}
                      >
                        {item.name}
                      </SelectItem>
                    ))}
                  </Select>
                  <Select
                    selectionMode="multiple"
                    variant="bordered"
                    label="Profesión"
                    name="profesion_id"
                    labelPlacement="outside"
                    classNames={{
                      trigger: "h-[44px] rounded-lg border-small",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                      popoverContent: "rounded-md",
                    }}
                    className="max-w-xs"
                    onChange={handleChangeSelect}
                    placeholder={
                      selectsData.languages?.length
                        ? "Seleccione"
                        : "Loading..."
                    }
                    selectedKeys={filters.profesion_id}
                  >
                    {selectsData.profesions?.map((item) => (
                      <SelectItem
                        key={item.id.toString()}
                        value={item.id.toString()}
                      >
                        {item.name}
                      </SelectItem>
                    ))}
                  </Select>
                  <Select
                    name="races_id"
                    selectionMode="multiple"
                    variant="bordered"
                    label="Raza"
                    labelPlacement="outside"
                    classNames={{
                      trigger: "h-[44px] rounded-lg border-small",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                      popoverContent: "rounded-md",
                    }}
                    className="max-w-xs"
                    onChange={handleChangeSelect}
                    placeholder="Seleccione"
                    selectedKeys={filters.races_id}
                  >
                    {races?.map((item) => (
                      <SelectItem
                        key={item.id.toString()}
                        value={item.id.toString()}
                      >
                        {item.name}
                      </SelectItem>
                    ))}
                  </Select>
                  <Select
                    name="skin_colors_id"
                    selectionMode="multiple"
                    variant="bordered"
                    label="Color de piel"
                    labelPlacement="outside"
                    classNames={{
                      trigger: "h-[44px] rounded-lg border-small",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                      popoverContent: "rounded-md",
                    }}
                    className="max-w-xs"
                    onChange={handleChangeSelect}
                    placeholder="Seleccione"
                    selectedKeys={filters.skin_colors_id}
                  >
                    {skincolors?.map((item) => (
                      <SelectItem
                        key={item.id.toString()}
                        value={item.id.toString()}
                      >
                        {item.name}
                      </SelectItem>
                    ))}
                  </Select>
                  <Select
                    name="complexions_id"
                    selectionMode="multiple"
                    variant="bordered"
                    label="Complexión"
                    labelPlacement="outside"
                    classNames={{
                      trigger: "h-[44px] rounded-lg border-small",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                      popoverContent: "rounded-md",
                    }}
                    className="max-w-xs"
                    onChange={handleChangeSelect}
                    placeholder="Seleccione"
                    selectedKeys={filters.complexions_id}
                  >
                    {complexions?.map((item) => (
                      <SelectItem
                        key={item.id.toString()}
                        value={item.id.toString()}
                      >
                        {item.name}
                      </SelectItem>
                    ))}
                  </Select>
                  <Select
                    name="eye_colors_id"
                    selectionMode="multiple"
                    variant="bordered"
                    label="Color de ojos"
                    labelPlacement="outside"
                    classNames={{
                      trigger: "h-[44px] rounded-lg border-small",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                      popoverContent: "rounded-md",
                    }}
                    className="max-w-xs"
                    onChange={handleChangeSelect}
                    placeholder="Seleccione"
                    selectedKeys={filters.eye_colors_id}
                  >
                    {eyesColor?.map((item) => (
                      <SelectItem
                        key={item.id.toString()}
                        value={item.id.toString()}
                      >
                        {item.name}
                      </SelectItem>
                    ))}
                  </Select>
                  <Select
                    name="hair_colors_id"
                    selectionMode="multiple"
                    variant="bordered"
                    label="Color de cabello"
                    labelPlacement="outside"
                    classNames={{
                      trigger: "h-[44px] rounded-lg border-small",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                      popoverContent: "rounded-md",
                    }}
                    className="max-w-xs"
                    onChange={handleChangeSelect}
                    placeholder="Seleccione"
                    selectedKeys={filters.hair_colors_id}
                  >
                    {hairColors?.map((item) => (
                      <SelectItem
                        key={item.id.toString()}
                        value={item.id.toString()}
                      >
                        {item.name}
                      </SelectItem>
                    ))}
                  </Select>
                  <Select
                    name="hair_length"
                    selectionMode="multiple"
                    variant="bordered"
                    label="Corte de cabello"
                    labelPlacement="outside"
                    classNames={{
                      trigger: "h-[44px] rounded-lg border-small",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                      popoverContent: "rounded-md",
                    }}
                    className="max-w-xs"
                    onChange={handleChangeSelect}
                    placeholder="Seleccione"
                    selectedKeys={filters.hair_length}
                  >
                    {hairCuts?.map((item) => (
                      <SelectItem
                        key={item.id.toString()}
                        value={item.id.toString()}
                      >
                        {item.name}
                      </SelectItem>
                    ))}
                  </Select>
                  <Select
                    name="lips"
                    selectionMode="multiple"
                    variant="bordered"
                    label="Labios"
                    labelPlacement="outside"
                    classNames={{
                      trigger: "h-[44px] rounded-lg border-small",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                      popoverContent: "rounded-md",
                    }}
                    className="max-w-xs"
                    onChange={handleChangeSelect}
                    placeholder="Seleccione"
                    selectedKeys={filters.lips}
                  >
                    {lipsOpt?.map((item) => (
                      <SelectItem
                        key={item.id.toString()}
                        value={item.id.toString()}
                      >
                        {item.name}
                      </SelectItem>
                    ))}
                  </Select>
                  <Select
                    name="type_of_nose"
                    selectionMode="multiple"
                    variant="bordered"
                    label="Tipo de nariz"
                    labelPlacement="outside"
                    classNames={{
                      trigger: "h-[44px] rounded-lg border-small",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                      popoverContent: "rounded-md",
                    }}
                    className="max-w-xs"
                    onChange={handleChangeSelect}
                    placeholder="Seleccione"
                    selectedKeys={filters.lips}
                  >
                    {noseTypes?.map((item) => (
                      <SelectItem
                        key={item.id.toString()}
                        value={item.id.toString()}
                      >
                        {item.name}
                      </SelectItem>
                    ))}
                  </Select>
                  <Select
                    name="piercings"
                    selectionMode="multiple"
                    variant="bordered"
                    label="Piercings"
                    labelPlacement="outside"
                    classNames={{
                      trigger: "h-[44px] rounded-lg border-small",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                      popoverContent: "rounded-md",
                    }}
                    className="max-w-xs"
                    onChange={handleChangeSelect}
                    placeholder="Seleccione"
                    selectedKeys={filters.piercings}
                  >
                    {piercingCount?.map((item) => (
                      <SelectItem
                        key={item.id.toString()}
                        value={item.id.toString()}
                      >
                        {item.name}
                      </SelectItem>
                    ))}
                  </Select>
                  <Select
                    name="tattoos"
                    selectionMode="multiple"
                    variant="bordered"
                    label="Tatuajes"
                    labelPlacement="outside"
                    classNames={{
                      trigger: "h-[44px] rounded-lg border-small",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                      popoverContent: "rounded-md",
                    }}
                    className="max-w-xs"
                    onChange={handleChangeSelect}
                    placeholder="Seleccione"
                    selectedKeys={filters.tattoos}
                  >
                    {tattoosCount?.map((item) => (
                      <SelectItem
                        key={item.id.toString()}
                        value={item.id.toString()}
                      >
                        {item.name}
                      </SelectItem>
                    ))}
                  </Select>
                  <Select
                    name="you_drink"
                    selectionMode="multiple"
                    variant="bordered"
                    label="Bebe"
                    labelPlacement="outside"
                    classNames={{
                      trigger: "h-[44px] rounded-lg border-small",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                      popoverContent: "rounded-md",
                    }}
                    className="max-w-xs"
                    onChange={handleChangeSelect}
                    placeholder="Seleccione"
                    selectedKeys={filters.you_drink}
                  >
                    {drink?.map((item) => (
                      <SelectItem
                        key={item.id.toString()}
                        value={item.id.toString()}
                      >
                        {item.name}
                      </SelectItem>
                    ))}
                  </Select>
                  <Select
                    name="you_smoke"
                    selectionMode="multiple"
                    variant="bordered"
                    label="Fuma"
                    labelPlacement="outside"
                    classNames={{
                      trigger: "h-[44px] rounded-lg border-small",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                      popoverContent: "rounded-md",
                    }}
                    className="max-w-xs"
                    onChange={handleChangeSelect}
                    placeholder="Seleccione"
                    selectedKeys={filters.you_smoke}
                  >
                    {smoke?.map((item) => (
                      <SelectItem
                        key={item.id.toString()}
                        value={item.id.toString()}
                      >
                        {item.name}
                      </SelectItem>
                    ))}
                  </Select>
                  <Select
                    name="children"
                    selectionMode="multiple"
                    variant="bordered"
                    label="Hijos"
                    labelPlacement="outside"
                    classNames={{
                      trigger: "h-[44px] rounded-lg border-small",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                      popoverContent: "rounded-md",
                    }}
                    className="max-w-xs"
                    onChange={handleChangeSelect}
                    placeholder="Seleccione"
                    selectedKeys={filters.children}
                  >
                    {childs?.map((item) => (
                      <SelectItem
                        key={item.id.toString()}
                        value={item.id.toString()}
                      >
                        {item.name}
                      </SelectItem>
                    ))}
                  </Select>
                  <Slider
                    label="Edad"
                    step={1}
                    maxValue={100}
                    minValue={18}
                    value={ageRange}
                    onChange={setAgeRange}
                    className="max-w-md"
                    onChangeEnd={async () => {
                      await getData(1);
                    }}
                  />
                  <Checkbox
                    isSelected={byPremium}
                    onValueChange={() => setByPremium(!byPremium)}
                    classNames={{
                      wrapper:
                        "border-small before:border-small rounded-[4px] before:rounded-[4px] after:rounded-[4px] after:bg-color4",
                      base: "font-medium text-[14px] text-color2",
                    }}
                  >
                    Solo premium
                  </Checkbox>
                </div>
              </div>
            </div>
            <div className="flex-grow flex flex-col justify-between w-full">
              <div className="w-full  pl-4 xl:pl-6">
                <section className="w-full flex">
                  <div className=" flex flex-col w-full">
                    <div className="w-full mb-2 flex  justify-between ">
                      <form
                        onSubmit={(e: FormEvent) => {
                          e.preventDefault();
                          getData(1);
                        }}
                        className="flex w-full  gap-4 max-w-4xl flex-wrap md:flex-nowrap"
                      >
                        <Input
                          className="min-w-sm text-input w-full "
                          id="search"
                          type="text"
                          variant="bordered"
                          label=""
                          startContent={<SearchIcon />}
                          placeholder="Buscar..."
                          required
                          value={search}
                          onChange={handleChange}
                          classNames={{ inputWrapper: " border-1 rounded-lg" }}
                        />
                        <Button
                          color="primary"
                          variant="solid"
                          className="rounded-lg max-w-sm"
                          type="submit"
                        >
                          Buscar
                        </Button>
                        <Button
                          color="default"
                          variant="bordered"
                          onClick={handleReset}
                          className="rounded-lg max-w-sm border-1 "
                        >
                          Reset
                        </Button>
                      </form>

                      <div className="xl:hidden">
                        <Popover placement="bottom-end" offset={10}>
                          <PopoverTrigger>
                            <Button
                              isIconOnly
                              aria-label="filter"
                              variant="bordered"
                              className="ml-2 bg-color8 bor border-primary/60 border-1 rounded-lg"
                            >
                              <FilterIcon width={22} stroke={"#A24BFD"} />
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent className="w-[300px] px-0">
                            {(titleProps) => (
                              <div className="px-0 py-2 w-full">
                                <p
                                  className="text-small font-bold text-foreground px-2"
                                  {...titleProps}
                                >
                                  Filtros
                                </p>
                                <div className="mt-2 flex flex-col gap-2 w-full max-h-[300px] overflow-auto px-3">
                                  <Checkbox
                                    isSelected={onlyOnline}
                                    onValueChange={() =>
                                      setOnlyOnline(!onlyOnline)
                                    }
                                    classNames={{
                                      wrapper:
                                        "border-small before:border-small rounded-[4px] before:rounded-[4px] after:rounded-[4px] after:bg-color4",
                                      base: "font-medium text-[14px] text-color2",
                                    }}
                                  >
                                    Solo en línea
                                  </Checkbox>
                                  <Select
                                    selectionMode="multiple"
                                    variant="bordered"
                                    label="Nacionalidad"
                                    name="nacionalidades_id"
                                    classNames={{
                                      trigger:
                                        "h-[44px] rounded-lg border-small",
                                      label:
                                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                                      popoverContent: "rounded-md",
                                    }}
                                    className="max-w-xs"
                                    onChange={handleChangeSelect}
                                    placeholder={
                                      selectsData.nationalities?.length
                                        ? "Seleccione"
                                        : "Loading..."
                                    }
                                    selectedKeys={filters.nacionalidades_id}
                                  >
                                    {selectsData.nationalities?.map((item) => (
                                      <SelectItem
                                        key={item.cca2}
                                        value={item.cca2}
                                      >
                                        {item.name.common}
                                      </SelectItem>
                                    ))}
                                  </Select>
                                  <Select
                                    selectionMode="multiple"
                                    variant="bordered"
                                    label="Provincias"
                                    name="provincias_id"
                                    labelPlacement="inside"
                                    classNames={{
                                      trigger:
                                        "h-[44px] rounded-lg border-small",
                                      label:
                                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                                      popoverContent: "rounded-md",
                                    }}
                                    className="max-w-xs"
                                    onChange={handleChangeSelect}
                                    placeholder={
                                      selectsData.provinces?.length
                                        ? "Seleccione"
                                        : "Loading..."
                                    }
                                    selectedKeys={filters.provincias_id}
                                  >
                                    {selectsData.provinces?.map((item) => (
                                      <SelectItem
                                        key={item.id.toString()}
                                        value={item.id.toString()}
                                      >
                                        {item.name}
                                      </SelectItem>
                                    ))}
                                  </Select>
                                  <Select
                                    name="fantasias_id"
                                    selectionMode="multiple"
                                    variant="bordered"
                                    label="Fantasías"
                                    labelPlacement="inside"
                                    classNames={{
                                      trigger:
                                        "h-[44px] rounded-lg border-small",
                                      label:
                                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                                      popoverContent: "rounded-md",
                                    }}
                                    className="max-w-xs"
                                    onChange={handleChangeSelect}
                                    placeholder={
                                      selectsData.fantasies?.length
                                        ? "Seleccione"
                                        : "Loading..."
                                    }
                                    selectedKeys={filters.fantasias_id}
                                  >
                                    {selectsData.fantasies?.map((item) => (
                                      <SelectItem
                                        key={item.id.toString()}
                                        value={item.id.toString()}
                                      >
                                        {item.name}
                                      </SelectItem>
                                    ))}
                                  </Select>
                                  <Select
                                    selectionMode="multiple"
                                    variant="bordered"
                                    label="Idiomas"
                                    name="idiomas_id"
                                    labelPlacement="inside"
                                    classNames={{
                                      trigger:
                                        "h-[44px] rounded-lg border-small",
                                      label:
                                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                                      popoverContent: "rounded-md",
                                    }}
                                    className="max-w-xs"
                                    onChange={handleChangeSelect}
                                    placeholder={
                                      selectsData.languages?.length
                                        ? "Seleccione"
                                        : "Loading..."
                                    }
                                    selectedKeys={filters.idiomas_id}
                                  >
                                    {selectsData.languages?.map((item) => (
                                      <SelectItem
                                        key={item.id.toString()}
                                        value={item.id.toString()}
                                      >
                                        {item.name}
                                      </SelectItem>
                                    ))}
                                  </Select>
                                  <Select
                                    selectionMode="multiple"
                                    variant="bordered"
                                    label="Horóscopo"
                                    name="horoscopo_id"
                                    labelPlacement="inside"
                                    classNames={{
                                      trigger:
                                        "h-[44px] rounded-lg border-small",
                                      label:
                                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                                      popoverContent: "rounded-md",
                                    }}
                                    className="max-w-xs"
                                    onChange={handleChangeSelect}
                                    placeholder={
                                      selectsData.languages?.length
                                        ? "Seleccione"
                                        : "Loading..."
                                    }
                                    selectedKeys={filters.horoscopo_id}
                                  >
                                    {selectsData.horoscope?.map((item) => (
                                      <SelectItem
                                        key={item.id.toString()}
                                        value={item.id.toString()}
                                      >
                                        {item.name}
                                      </SelectItem>
                                    ))}
                                  </Select>
                                  <Select
                                    selectionMode="multiple"
                                    variant="bordered"
                                    label="Profesión"
                                    name="profesion_id"
                                    labelPlacement="inside"
                                    classNames={{
                                      trigger:
                                        "h-[44px] rounded-lg border-small",
                                      label:
                                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                                      popoverContent: "rounded-md",
                                    }}
                                    className="max-w-xs"
                                    onChange={handleChangeSelect}
                                    placeholder={
                                      selectsData.languages?.length
                                        ? "Seleccione"
                                        : "Loading..."
                                    }
                                    selectedKeys={filters.profesion_id}
                                  >
                                    {selectsData.profesions?.map((item) => (
                                      <SelectItem
                                        key={item.id.toString()}
                                        value={item.id.toString()}
                                      >
                                        {item.name}
                                      </SelectItem>
                                    ))}
                                  </Select>
                                  <Select
                                    name="races_id"
                                    selectionMode="multiple"
                                    variant="bordered"
                                    label="Raza"
                                    labelPlacement="inside"
                                    classNames={{
                                      trigger:
                                        "h-[44px] rounded-lg border-small",
                                      label:
                                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                                      popoverContent: "rounded-md",
                                    }}
                                    className="max-w-xs"
                                    onChange={handleChangeSelect}
                                    placeholder="Seleccione"
                                    selectedKeys={filters.races_id}
                                  >
                                    {races?.map((item) => (
                                      <SelectItem
                                        key={item.id.toString()}
                                        value={item.id.toString()}
                                      >
                                        {item.name}
                                      </SelectItem>
                                    ))}
                                  </Select>
                                  <Select
                                    name="skin_colors_id"
                                    selectionMode="multiple"
                                    variant="bordered"
                                    label="Color de piel"
                                    labelPlacement="inside"
                                    classNames={{
                                      trigger:
                                        "h-[44px] rounded-lg border-small",
                                      label:
                                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                                      popoverContent: "rounded-md",
                                    }}
                                    className="max-w-xs"
                                    onChange={handleChangeSelect}
                                    placeholder="Seleccione"
                                    selectedKeys={filters.skin_colors_id}
                                  >
                                    {skincolors?.map((item) => (
                                      <SelectItem
                                        key={item.id.toString()}
                                        value={item.id.toString()}
                                      >
                                        {item.name}
                                      </SelectItem>
                                    ))}
                                  </Select>
                                  <Select
                                    name="complexions_id"
                                    selectionMode="multiple"
                                    variant="bordered"
                                    label="Complexión"
                                    labelPlacement="inside"
                                    classNames={{
                                      trigger:
                                        "h-[44px] rounded-lg border-small",
                                      label:
                                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                                      popoverContent: "rounded-md",
                                    }}
                                    className="max-w-xs"
                                    onChange={handleChangeSelect}
                                    placeholder="Seleccione"
                                    selectedKeys={filters.complexions_id}
                                  >
                                    {complexions?.map((item) => (
                                      <SelectItem
                                        key={item.id.toString()}
                                        value={item.id.toString()}
                                      >
                                        {item.name}
                                      </SelectItem>
                                    ))}
                                  </Select>
                                  <Select
                                    name="eye_colors_id"
                                    selectionMode="multiple"
                                    variant="bordered"
                                    label="Color de ojos"
                                    labelPlacement="inside"
                                    classNames={{
                                      trigger:
                                        "h-[44px] rounded-lg border-small",
                                      label:
                                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                                      popoverContent: "rounded-md",
                                    }}
                                    className="max-w-xs"
                                    onChange={handleChangeSelect}
                                    placeholder="Seleccione"
                                    selectedKeys={filters.eye_colors_id}
                                  >
                                    {eyesColor?.map((item) => (
                                      <SelectItem
                                        key={item.id.toString()}
                                        value={item.id.toString()}
                                      >
                                        {item.name}
                                      </SelectItem>
                                    ))}
                                  </Select>
                                  <Select
                                    name="hair_colors_id"
                                    selectionMode="multiple"
                                    variant="bordered"
                                    label="Color de cabello"
                                    labelPlacement="inside"
                                    classNames={{
                                      trigger:
                                        "h-[44px] rounded-lg border-small",
                                      label:
                                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                                      popoverContent: "rounded-md",
                                    }}
                                    className="max-w-xs"
                                    onChange={handleChangeSelect}
                                    placeholder="Seleccione"
                                    selectedKeys={filters.hair_colors_id}
                                  >
                                    {hairColors?.map((item) => (
                                      <SelectItem
                                        key={item.id.toString()}
                                        value={item.id.toString()}
                                      >
                                        {item.name}
                                      </SelectItem>
                                    ))}
                                  </Select>
                                  <Select
                                    name="hair_length"
                                    selectionMode="multiple"
                                    variant="bordered"
                                    label="Corte de cabello"
                                    labelPlacement="inside"
                                    classNames={{
                                      trigger:
                                        "h-[44px] rounded-lg border-small",
                                      label:
                                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                                      popoverContent: "rounded-md",
                                    }}
                                    className="max-w-xs"
                                    onChange={handleChangeSelect}
                                    placeholder="Seleccione"
                                    selectedKeys={filters.hair_length}
                                  >
                                    {hairCuts?.map((item) => (
                                      <SelectItem
                                        key={item.id.toString()}
                                        value={item.id.toString()}
                                      >
                                        {item.name}
                                      </SelectItem>
                                    ))}
                                  </Select>
                                  <Select
                                    name="lips"
                                    selectionMode="multiple"
                                    variant="bordered"
                                    label="Labios"
                                    labelPlacement="inside"
                                    classNames={{
                                      trigger:
                                        "h-[44px] rounded-lg border-small",
                                      label:
                                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                                      popoverContent: "rounded-md",
                                    }}
                                    className="max-w-xs"
                                    onChange={handleChangeSelect}
                                    placeholder="Seleccione"
                                    selectedKeys={filters.lips}
                                  >
                                    {lipsOpt?.map((item) => (
                                      <SelectItem
                                        key={item.id.toString()}
                                        value={item.id.toString()}
                                      >
                                        {item.name}
                                      </SelectItem>
                                    ))}
                                  </Select>
                                  <Select
                                    name="type_of_nose"
                                    selectionMode="multiple"
                                    variant="bordered"
                                    label="Tipo de nariz"
                                    labelPlacement="inside"
                                    classNames={{
                                      trigger:
                                        "h-[44px] rounded-lg border-small",
                                      label:
                                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                                      popoverContent: "rounded-md",
                                    }}
                                    className="max-w-xs"
                                    onChange={handleChangeSelect}
                                    placeholder="Seleccione"
                                    selectedKeys={filters.lips}
                                  >
                                    {noseTypes?.map((item) => (
                                      <SelectItem
                                        key={item.id.toString()}
                                        value={item.id.toString()}
                                      >
                                        {item.name}
                                      </SelectItem>
                                    ))}
                                  </Select>
                                  <Select
                                    name="piercings"
                                    selectionMode="multiple"
                                    variant="bordered"
                                    label="Piercings"
                                    labelPlacement="inside"
                                    classNames={{
                                      trigger:
                                        "h-[44px] rounded-lg border-small",
                                      label:
                                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                                      popoverContent: "rounded-md",
                                    }}
                                    className="max-w-xs"
                                    onChange={handleChangeSelect}
                                    placeholder="Seleccione"
                                    selectedKeys={filters.piercings}
                                  >
                                    {piercingCount?.map((item) => (
                                      <SelectItem
                                        key={item.id.toString()}
                                        value={item.id.toString()}
                                      >
                                        {item.name}
                                      </SelectItem>
                                    ))}
                                  </Select>
                                  <Select
                                    name="tattoos"
                                    selectionMode="multiple"
                                    variant="bordered"
                                    label="Tatuajes"
                                    labelPlacement="inside"
                                    classNames={{
                                      trigger:
                                        "h-[44px] rounded-lg border-small",
                                      label:
                                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                                      popoverContent: "rounded-md",
                                    }}
                                    className="max-w-xs"
                                    onChange={handleChangeSelect}
                                    placeholder="Seleccione"
                                    selectedKeys={filters.tattoos}
                                  >
                                    {tattoosCount?.map((item) => (
                                      <SelectItem
                                        key={item.id.toString()}
                                        value={item.id.toString()}
                                      >
                                        {item.name}
                                      </SelectItem>
                                    ))}
                                  </Select>
                                  <Select
                                    name="you_drink"
                                    selectionMode="multiple"
                                    variant="bordered"
                                    label="Bebe"
                                    labelPlacement="inside"
                                    classNames={{
                                      trigger:
                                        "h-[44px] rounded-lg border-small",
                                      label:
                                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                                      popoverContent: "rounded-md",
                                    }}
                                    className="max-w-xs"
                                    onChange={handleChangeSelect}
                                    placeholder="Seleccione"
                                    selectedKeys={filters.you_drink}
                                  >
                                    {drink?.map((item) => (
                                      <SelectItem
                                        key={item.id.toString()}
                                        value={item.id.toString()}
                                      >
                                        {item.name}
                                      </SelectItem>
                                    ))}
                                  </Select>
                                  <Select
                                    name="you_smoke"
                                    selectionMode="multiple"
                                    variant="bordered"
                                    label="Fuma"
                                    labelPlacement="inside"
                                    classNames={{
                                      trigger:
                                        "h-[44px] rounded-lg border-small",
                                      label:
                                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                                      popoverContent: "rounded-md",
                                    }}
                                    className="max-w-xs"
                                    onChange={handleChangeSelect}
                                    placeholder="Seleccione"
                                    selectedKeys={filters.you_smoke}
                                  >
                                    {smoke?.map((item) => (
                                      <SelectItem
                                        key={item.id.toString()}
                                        value={item.id.toString()}
                                      >
                                        {item.name}
                                      </SelectItem>
                                    ))}
                                  </Select>
                                  <Select
                                    name="children"
                                    selectionMode="multiple"
                                    variant="bordered"
                                    label="Hijos"
                                    labelPlacement="inside"
                                    classNames={{
                                      trigger:
                                        "h-[44px] rounded-lg border-small",
                                      label:
                                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                                      popoverContent: "rounded-md",
                                    }}
                                    className="max-w-xs"
                                    onChange={handleChangeSelect}
                                    placeholder="Seleccione"
                                    selectedKeys={filters.children}
                                  >
                                    {childs?.map((item) => (
                                      <SelectItem
                                        key={item.id.toString()}
                                        value={item.id.toString()}
                                      >
                                        {item.name}
                                      </SelectItem>
                                    ))}
                                  </Select>
                                  <Checkbox
                                    isSelected={byPremium}
                                    onValueChange={() =>
                                      setByPremium(!byPremium)
                                    }
                                    classNames={{
                                      wrapper:
                                        "border-small before:border-small rounded-[4px] before:rounded-[4px] after:rounded-[4px] after:bg-color4",
                                      base: "font-medium text-[14px] text-color2",
                                    }}
                                  >
                                    Solo premium
                                  </Checkbox>
                                  <Slider
                                    label="Edad"
                                    step={1}
                                    maxValue={100}
                                    minValue={18}
                                    value={ageRange}
                                    onChange={setAgeRange}
                                    className="max-w-md"
                                    onChangeEnd={async () => {
                                      await getData(1);
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </PopoverContent>
                        </Popover>
                      </div>
                    </div>
                    <div className="w-full xl:overflow-auto xl:max-h-[calc(100vh-220px)] xl:pb-2 pt-4 pr-2">
                      <div className="w-full gap-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 ">
                        {isLoading ? (
                          [0, 0, 0, 0].map((_, i) => (
                            <Card
                              className="w-full space-y-5 p-4 shadow-none border-1 border-gray-100"
                              radius="lg"
                              key={i}
                            >
                              <Skeleton className="rounded-lg">
                                <div className="h-24 rounded-lg bg-default-300"></div>
                              </Skeleton>
                              <div className="space-y-3">
                                <Skeleton className="w-3/5 rounded-lg">
                                  <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                                </Skeleton>
                                <Skeleton className="w-4/5 rounded-lg">
                                  <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                                </Skeleton>
                                <Skeleton className="w-2/5 rounded-lg">
                                  <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                                </Skeleton>
                              </div>
                            </Card>
                          ))
                        ) : users.length == 0 ? (
                          <p>No hay usuarios para mostrar</p>
                        ) : (
                          users
                            .filter((u) => u.id != authData?.id)
                            .map((it) => (
                              <Card
                                shadow="sm"
                                key={it.id}
                                radius="md"
                                className="w-full flexflex-grow items-start h-full"
                              >
                                <CardBody className="overflow-visible p-0">
                                  <div className="">
                                    <Image
                                      shadow="none"
                                      radius="md"
                                      width="100%"
                                      alt="alt"
                                      className="w-full object-cover h-[140px]"
                                      fallbackSrc="/assets/fantasyImage.jpeg"
                                      src={
                                        it?.portada_path
                                          ? it?.portada_path?.startsWith("http")
                                            ? it?.portada_path
                                            : import.meta.env.VITE_BASE_URL +
                                              it?.portada_path
                                          : undefined
                                      }
                                    />
                                    <div className="w-full flex justify-center">
                                      <Link
                                        to={`/profile/${it.id}`}
                                        key={it.id}
                                      >
                                        <Badge
                                          isOneChar
                                          content={
                                            it.info?.is_couple ? (
                                              <UsersIcon stroke="white" />
                                            ) : it.info?.detalles?.sex?.value ==
                                              "f" ? (
                                              <GenderFemale />
                                            ) : (
                                              <GenderMale />
                                            )
                                          }
                                          color="primary"
                                          size="lg"
                                          shape="circle"
                                          placement="bottom-right"
                                          className={`z-20 top-0 ${
                                            it.info?.is_couple
                                              ? "bg-primary"
                                              : it.info?.detalles?.sex?.value ==
                                                "f"
                                              ? "bg-pink-400"
                                              : "bg-blue-400"
                                          }`}
                                        >
                                          <Avatar
                                            src={
                                              it?.profile_path
                                                ? it?.profile_path?.startsWith(
                                                    "http"
                                                  )
                                                  ? it?.profile_path
                                                  : import.meta.env
                                                      .VITE_BASE_URL +
                                                    it?.profile_path
                                                : undefined
                                            }
                                            showFallback
                                            classNames={{
                                              base: "bg-zinc-100",
                                              icon: "text-black/50",
                                            }}
                                            className="-mt-[40px] z-20 w-20 h-20 border-3 border-white"
                                          />
                                        </Badge>
                                      </Link>
                                    </div>
                                  </div>

                                  <div className="text-small flex flex-col items-start justify-start px-3">
                                    <b className="hover:text-primary text-xl">
                                      <Link
                                        to={`/profile/${it.id}`}
                                        key={it.id}
                                      >
                                        {it.name}
                                      </Link>
                                    </b>

                                    <p
                                      onClick={() => console.log(it.info)}
                                      className="font-semibold text-color2"
                                    >
                                      {it.info?.is_couple
                                        ? "Pareja"
                                        : it.info?.detalles?.sex?.value == "f"
                                        ? "Mujer"
                                        : "Hombre"}

                                      <span className="text-[16px] ml-3 font-bold text-primary">
                                        {age(it)} años
                                      </span>
                                    </p>
                                    <p className="font-semibold text-color2 mb-2">
                                      {it.email.slice(0, 26)}
                                      {it.email.length > 26 ? "..." : ""}
                                    </p>
                                    <p className="">
                                      {it.info?.detalles?.description.value.slice(
                                        0,
                                        100
                                      )}
                                      {it.info?.detalles?.description.value
                                        ? it.info?.detalles?.description.value
                                            .length > 100
                                          ? "..."
                                          : ""
                                        : "..."}
                                    </p>
                                  </div>
                                </CardBody>
                                <CardFooter className="text-small flex flex-col items-start justify-start"></CardFooter>
                              </Card>
                            ))
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              {pagination && pagination?.current_page > 0 && (
                <div className="px-4 mt-3 mb-2 w-full">
                  <div className="flex justify-center">
                    <Pagination
                      showControls
                      total={pagination.last_page}
                      initialPage={1}
                      onChange={onPageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
