import { Avatar, Tooltip } from "@nextui-org/react";
import { Link } from "react-router-dom";
import { User } from "../../interfaces/Login";

export default function LastVisits({
  lastVisits = [],
}: {
  lastVisits: User[];
}) {
  return (
    <section className="border-1 border-gray-300 w-full  rounded-2xl">
      <div className="p-4">
        <h3 className="font-bold text-[16px] text-black">
          Últimas Visitas al Perfil
        </h3>
      </div>
      <hr className="border-0.5 border-gray-300"></hr>
      <div className="p-5 px-4 flex flex-col gap-4">
        <div className="grid grid-cols-4 gap-3 ">
          {lastVisits.map((e) => (
            <Link to={`/profile/${e?.id}`} key={e.id}>
              <Tooltip content={e.name} placement="left">
                <Avatar
                  showFallback
                  className="w-[46px] h-[46px] 2xl:w-[50px] 2xl:h-[50px]"
                  classNames={{
                    base: "bg-zinc-100",
                    icon: "text-black/50",
                    name: "text-black/50 capitalize font-bold text-[15px]",
                  }}
                  src={
                    e.profile_path
                      ? e.profile_path.startsWith("http")
                        ? e.profile_path
                        : import.meta.env.VITE_BASE_URL + e.profile_path
                      : undefined
                  }
                  name={e.name}
                />
              </Tooltip>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}
