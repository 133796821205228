import PrimaryButton from "../common/PrimaryButton";
import SecondaryButton from "../common/SecondaryButton";
import Stepper from "../common/Stepper";
import { useStepperStore } from "../../stores/stepperStore";
import CustomTextAreaInput from "../form/CustomTextAreaInput";
import CustomSelect from "../form/CustomSelect";
import CustomRadioGroup from "../form/CustomRadioGroup";
import { FormEvent, useEffect, useReducer, useState } from "react";
import {
  getFantasies,
  // getLookingFor,
  getProvinces,
  getSexualOrientations,
} from "../../api/formSelectsData";
import { getInfoPayload, useRegisterStore } from "../../stores/registerData";
import { Province } from "../../interfaces/Provice";
import { Fantasy } from "../../interfaces/Fantasy";
// import { LookingFor } from "../../interfaces/LookingFor";
import Logo from "../common/Logo";
import { SexualOrientation } from "../../interfaces/SexualOrientation";
import { CalendarDate, parseDate } from "@internationalized/date";
import {
  // Autocomplete,
  // AutocompleteItem,
  DateInput,
  // Spinner,
} from "@nextui-org/react";
import VisibilityState from "../form/VisibilityState";
import { toast } from "react-toastify";
import { useLoginStore } from "../../stores/loginDataStore";
import { useNavigate } from "react-router-dom";
import { Country } from "../../interfaces/Country";
import { getCountries } from "../../api/nationality";
import { saveUserInfo } from "../../api/info";
import { useAuthStore } from "../../stores/authStore";
import { StepTwoInitialState } from "../../stores/initialStates";
import NationalityAutocomplete from "../form/CustomAutocomplete";

type VisibilityNm = "sex" | "partner_sex";

const initialMessages = {
  fantasias_id: "",
  provincia_id: "",
  birthDate: "",
  partner_birthDate: "",
  nacionalidad_id: "",
  partner_nacionalidad_id: "",
  sex: "",
  partner_sex: "",
  sexualOrentation: "",
  partner_sexualOrentation: "",
  description: "",
  partner_description: "",
};

export default function ProfileThirdStepForm({
  isLocal = false,
  participants = 1,
  backStep,
  isEditing = false,
}: {
  isLocal: boolean;
  isEditing?: boolean;
  participants?: number;
  backStep: () => void;
  next?: () => void;
}) {
  const step = useStepperStore((state) => state.step);
  const setStep = useStepperStore((state) => state.setStep);

  const stepTwoData = useRegisterStore((state) => state.stepTwoData);
  const setStepTwoData = useRegisterStore((state) => state.setStepTwoData);

  const [loading, setLoading] = useState(false);

  const user = useLoginStore((state) => state.user);
  const [date, setDate] = useState(
    parseDate(stepTwoData.birthDate ?? StepTwoInitialState.birthDate)
  );
  const [partnerDate, setPartnerDate] = useState(
    parseDate(stepTwoData.partner_birthDate ?? StepTwoInitialState.birthDate)
  );
  const [country, setCountry] = useState<string | number | null>("");
  const [partnerCountry, setPartnerCountry] = useState<string | number | null>(
    ""
  );
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [fantasies, setFantasies] = useState<Fantasy[]>([]);
  // const [lookingFor, setLookingFor] = useState<LookingFor[]>([]);
  const [orientations, setOrientations] = useState<SexualOrientation[]>([]);
  const [nationalities, setNationalities] = useState<Country[]>([]);

  const saveUser = useAuthStore((state) => state.saveUser);
  const authData = useAuthStore((state) => state.user);
  const navigate = useNavigate();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [messagesErrors, setMessagesErrors] = useState(initialMessages);

  const validate = () => {
    let isValid = true;
    // setMessagesErrors(initialMessages);
    let finalErrors = initialMessages;
    const arr = Object.keys(initialMessages);
    arr.forEach((name) => {
      switch (name) {
        case "fantasias_id":
        case "provincia_id":
        case "birthDate":
        case "nacionalidad_id":
          if (!stepTwoData[name]) {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: "Este campo es requerido",
            };
          }
          // console.log(name);
          break;
        case "sex":
        case "sexualOrentation":
        case "description":
          if (!stepTwoData[name]?.value) {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: "Este campo es requerido",
            };
            // console.log(stepTwoData[name]);
          }
          break;
        case "partner_birthDate":
        case "partner_nacionalidad_id":
          if (!stepTwoData[name] && participants == 2) {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: "Este campo es requerido",
            };
          }

          break;
        case "partner_sex":
        case "partner_sexualOrentation":
        case "partner_description":
          // console.log(stepTwoData[name]?.value);
          if (!stepTwoData[name]?.value && participants == 2) {
            isValid = false;
            finalErrors = {
              ...finalErrors,
              [name]: "Este campo es requerido",
            };
          }
          break;
        default:
          break;
      }
    });
    setMessagesErrors(finalErrors);
    // console.log(messagesErrors);
    forceUpdate();
    return isValid;
  };

  useEffect(() => {
    const getData = async () => {
      if (step == 2) {
        const fantasies = await getFantasies();
        setFantasies(fantasies.info.data[0]);
        // const lookingFor = await getLookingFor();
        // setLookingFor(lookingFor.info.data[0]);
        const provinces = await getProvinces();
        setProvinces(provinces.info.data[0]);
        const orientations = await getSexualOrientations();
        setOrientations(orientations?.info?.data[0]);
        const countries = await getCountries();
        // console.log(countries);

        setNationalities(countries);

        // setSelectsData({
        //   ...selectsData,
        //   fantasies: fantasies.info.data[0],
        //   lookingFor: lookingFor.info.data[0],
        //   provinces: provinces.info.data[0],
        //   orientations: orientations?.info?.data[0] ?? [],
        //   nationalities: countries,
        // });
        if (isEditing) {
          if (stepTwoData.nacionalidad_id)
            setCountry(stepTwoData.nacionalidad_id);
          if (stepTwoData.partner_nacionalidad_id)
            setPartnerCountry(stepTwoData.partner_nacionalidad_id);
        }
      }
    };
    // console.log("stepTwoData", stepTwoData);
    getData();
  }, [step]);

  const setFormData = (name: string, value: string) => {
    switch (name) {
      case "fantasias_id":
      case "provincia_id":
      case "birthDate":
      case "partner_birthDate":
      case "nacionalidad_id":
      case "partner_nacionalidad_id":
        setStepTwoData({
          ...stepTwoData,
          [name]: value,
        });
        // console.log(stepTwoData[name]);
        break;
      case "sex":
      case "partner_sex":
      case "sexualOrentation":
      case "partner_sexualOrentation":
      case "description":
      case "partner_description":
        // case "lookingFor":
        setStepTwoData({
          ...stepTwoData,
          [name]: {
            value: value,
            visibility: stepTwoData[name]?.visibility ?? "all",
          },
        });
        break;
      default:
        break;
    }
  };
  const handleChangeSelect = (e: FormEvent<HTMLSelectElement>) => {
    const data = e.target as HTMLSelectElement;
    setFormData(data.name, data.value);
  };

  const handleChangeInput = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    setFormData(data.name, data.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // console.log(validate());
    const isValid = validate();

    if (!isValid) return;
    const submitter = (e.nativeEvent as SubmitEvent).submitter?.innerText;

    if (!user && !isEditing) {
      toast.error("Debes autenticarte primero");
      navigate("/login");
    }
    // console.log(stepTwoData);
    if (submitter == "Guardar") await onSaveEditData();
    else setStep(step + 1);
  };
  // on visibilty change
  const handleVisibiltyChange = (name: VisibilityNm, value: string) => {
    if (name)
      setStepTwoData({
        ...stepTwoData,
        [name]: {
          value: stepTwoData[name]!.value,
          visibility: value,
        },
      });
  };

  // save dates as strings
  useEffect(() => {
    if (date?.year) {
      // console.log(date.toString());
      setFormData("birthDate", date.toString());
    } else setFormData("birthDate", "");
  }, [date]);

  useEffect(() => {
    if (partnerDate?.year) {
      setFormData("partner_birthDate", partnerDate.toString());
    } else setFormData("partner_birthDate", "");
  }, [partnerDate]);

  // save nationallity
  useEffect(() => {
    if (country) {
      setFormData("nacionalidad_id", country.toString());
    } else setFormData("nacionalidad_id", "");
    //console.log(stepTwoData.fantasias_id.split(","));
  }, [country]);

  useEffect(() => {
    if (partnerCountry) {
      setFormData("partner_nacionalidad_id", partnerCountry.toString());
    } else setFormData("partner_nacionalidad_id", "");
  }, [partnerCountry]);

  const onSaveEditData = async () => {
    setLoading(true);
    const data = getInfoPayload(participants - 1, authData?.id);
    const response = await saveUserInfo(data);
    // console.log(response);
    if (response?.error == "true" || !response) {
      toast.error("Ha ocurrido un error");
    } else {
      toast.success("Los datos se guardaron correctamente");
      saveUser(response.info.data[0]);
    }
    setLoading(false);
  };

  return (
    <div
      hidden={!isLocal && step == 2 ? false : true}
      className={` m-6 pb-10 text-center items-center  xl:overflow-hidden xl:overflow-y-auto xl:p-5 ${
        isEditing ? "w-full lg:max-w-[90%]" : "max-w-[768px] max-h-[90vh]"
      }`}
    >
      <div
        hidden={isEditing}
        className="md:absolute mb-8 md:mb-0 justify-center top-[27px] left-[26px]"
      >
        <SecondaryButton onClick={backStep} variant="solid">
          Volver atrás
        </SecondaryButton>
      </div>
      {!isEditing && (
        <div className="flex justify-center">
          <Logo />
        </div>
      )}
      <Stepper step={step - 2} />
      <h1 className="mt-14 font-semibold text-[30px] ">
        {participants == 1 ? "Yo solo" : "En pareja"}
      </h1>
      <h3 className="mt-2 font-normal text-color1 text-[16px]">
        {participants == 1
          ? "¡Muy bien! Ahora rellena tu información."
          : "¡Muy bien! Ahora rellenad la información de cada uno de vosotros."}
      </h3>
      <form className="w-full" onSubmit={handleSubmit} id="1">
        <div className="mt-8 w-full flex justify-center">
          <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-10">
            {!isEditing && (
              <>
                <div className="text-start w-full">
                  <CustomRadioGroup
                    onVisibilityChange={(value) => {
                      handleVisibiltyChange("sex", value);
                    }}
                    value={stepTwoData.sex.value}
                    onChange={handleChangeInput}
                    name="sex"
                    visibility_name="sex_visibility"
                    title="Mi sexo"
                    required
                    items={[
                      { label: "Hombre", value: "m" },
                      { label: "Mujer", value: "f" },
                    ]}
                    isInvalid={messagesErrors.sex.length > 0}
                    errorMessage={
                      messagesErrors.sex.length
                        ? messagesErrors.sex
                        : "Este campo es requerido"
                    }
                  />
                </div>
                <div
                  className="text-start w-full"
                  hidden={participants == 2 ? false : true}
                >
                  <CustomRadioGroup
                    onVisibilityChange={(value) => {
                      handleVisibiltyChange("partner_sex", value);
                    }}
                    name="partner_sex"
                    visibility_name="partner_sex_visibility"
                    value={stepTwoData.partner_sex?.value}
                    onChange={handleChangeInput}
                    title="Sexo de mi pareja"
                    required={participants == 2}
                    items={[
                      { label: "Hombre", value: "m" },
                      { label: "Mujer", value: "f" },
                    ]}
                    isInvalid={messagesErrors.partner_sex.length > 0}
                    errorMessage={
                      messagesErrors.partner_sex.length
                        ? messagesErrors.partner_sex
                        : "Este campo es requerido"
                    }
                  />
                </div>
                <div className="text-start">
                  <CustomRadioGroup
                    visibilityAll
                    name="sexualOrentation"
                    title="Mi orientación sexual"
                    required
                    value={stepTwoData.sexualOrentation.value}
                    onChange={handleChangeInput}
                    items={
                      orientations?.length
                        ? orientations.map((it) => ({
                            label: it.name,
                            value: it.id.toString(),
                          }))
                        : []
                    }
                    isInvalid={messagesErrors.sexualOrentation.length > 0}
                    errorMessage={
                      messagesErrors.sexualOrentation.length
                        ? messagesErrors.sexualOrentation
                        : "Este campo es requerido"
                    }
                  ></CustomRadioGroup>
                </div>
                <div
                  className="text-start w-full"
                  hidden={participants == 2 ? false : true}
                >
                  <CustomRadioGroup
                    name="partner_sexualOrentation"
                    visibility_name="partner_sex_orientation_visibility"
                    title="Orientacion sexual de mi pareja"
                    required={participants == 2}
                    value={stepTwoData.partner_sexualOrentation?.value}
                    onChange={handleChangeInput}
                    items={
                      orientations?.length
                        ? orientations.map((it) => ({
                            label: it.name,
                            value: it.id.toString(),
                          }))
                        : []
                    }
                    isInvalid={
                      messagesErrors.partner_sexualOrentation.length > 0
                    }
                    errorMessage={
                      messagesErrors.partner_sexualOrentation.length
                        ? messagesErrors.partner_sexualOrentation
                        : "Este campo es requerido"
                    }
                  />
                </div>
              </>
            )}
            {/* <div className="text-start w-full">
              <CustomSelect
                onChange={handleChangeSelect}
                visibilityAll
                title={participants == 2 ? "Qué buscamos" : "Qué busco"}
                required
                name="lookingFor"
                items={
                  lookingFor?.length
                    ? lookingFor.map((x) => ({
                        label: x.name,
                        value: x.id.toString(),
                      }))
                    : []
                }
                placeholder={lookingFor?.length ? "Seleccione" : "Loading..."}
                value={stepTwoData.lookingFor?.value}
                defaultSelectedKeys={
                  isEditing && stepTwoData.lookingFor?.value
                    ? [stepTwoData.lookingFor?.value]
                    : []
                }
              />
            </div> */}
            <div className="text-start w-full">
              <CustomSelect
                visibilityAll
                name="fantasias_id"
                selectionMode="multiple"
                title={
                  participants == 2 ? "Nuestras fantasías" : "Mis fantasías"
                }
                required
                items={
                  fantasies?.length
                    ? fantasies.map((x) => ({
                        label: x.name,
                        value: x.id.toString(),
                      }))
                    : []
                }
                placeholder={fantasies?.length ? "Seleccione" : "Loading..."}
                onChange={handleChangeSelect}
                value={stepTwoData.fantasias_id}
                defaultSelectedKeys={
                  isEditing && stepTwoData.fantasias_id
                    ? stepTwoData.fantasias_id?.split(",")
                    : []
                }
                isInvalid={messagesErrors.fantasias_id.length > 0}
                errorMessage={
                  messagesErrors.fantasias_id.length
                    ? messagesErrors.fantasias_id
                    : "Este campo es requerido"
                }
              />
            </div>
            <div className="text-start w-full">
              <DateInput
                label="Mi fecha de nacimiento"
                labelPlacement="outside"
                className="w-full"
                radius="sm"
                variant="bordered"
                isRequired
                value={date}
                onChange={setDate}
                defaultValue={parseDate(StepTwoInitialState.birthDate)}
                maxValue={parseDate(StepTwoInitialState.birthDate)}
                placeholderValue={new CalendarDate(1995, 11, 6)}
              />
              <div>
                <VisibilityState
                  visibilityAll={true}
                  visibility_name="birthdate_visibility"
                />
              </div>
            </div>
            <div
              className="text-start"
              hidden={participants == 2 ? false : true}
            >
              <DateInput
                name="partner_birthDate"
                isRequired={participants === 2}
                label="Fecha de nacimiento de mi pareja"
                radius="sm"
                labelPlacement="outside"
                className="w-full group-data-[filled-within=true]:text-color2 rounded-sm"
                variant="bordered"
                value={partnerDate}
                onChange={setPartnerDate}
                defaultValue={parseDate(StepTwoInitialState.birthDate)}
                maxValue={parseDate(StepTwoInitialState.birthDate)}
                placeholderValue={new CalendarDate(1995, 11, 6)}
              />
              <div>
                <VisibilityState
                  visibilityAll={true}
                  visibility_name="partner_birthdate_visibility"
                />
              </div>
            </div>
            <div className="text-start">
              <div>
                {/* <Autocomplete
                  label="Mi nacionalidad"
                  variant="bordered"
                  defaultItems={nationalities.length ? nationalities : []}
                  placeholder={
                    nationalities.length ? "Seleccione" : "Loading..."
                  }
                  isRequired
                  labelPlacement="outside"
                  classNames={{
                    popoverContent: "rounded-md",
                  }}
                  inputProps={{
                    isRequired: true,
                    classNames: {
                      input: "ml-1",
                      inputWrapper: "h-[48px] border-1 rounded-md",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                    },
                  }}
                  className="w-full"
                  selectedKey={country}
                  onSelectionChange={setCountry}
                  isDisabled={!nationalities?.length}
                  startContent={
                    !nationalities?.length ? (
                      <Spinner size="sm" color="primary" />
                    ) : undefined
                  }
                >
                  {(item) => (
                    <AutocompleteItem
                      key={item.cca2}
                      textValue={item.name.common}
                    >
                      <div className="flex items-center gap-2">
                        <img
                          alt={item.name.common}
                          src={item.flags.svg}
                          className="rounded-sm w-5 "
                        ></img>
                        <span>{item.name.common}</span>
                      </div>
                    </AutocompleteItem>
                  )}
                </Autocomplete> */}
                <NationalityAutocomplete
                  label="Mi nacionalidad"
                  placeholder={
                    nationalities.length ? "Seleccione" : "Loading..."
                  }
                  isRequired
                  selectedKey={country}
                  onSelectionChange={setCountry}
                  isDisabled={!nationalities?.length}
                  nationalities={nationalities}
                  isInvalid={messagesErrors.nacionalidad_id.length > 0}
                  errorMessage={
                    messagesErrors.nacionalidad_id.length
                      ? messagesErrors.nacionalidad_id
                      : "Este campo es requerido"
                  }
                />
                <VisibilityState visibilityAll />
              </div>
            </div>

            <div hidden={participants != 2} className="text-start">
              <div>
                {/* <Autocomplete
                  label="Nacionalidad de mi pareja"
                  variant="bordered"
                  defaultItems={nationalities.length ? nationalities : []}
                  placeholder={
                    nationalities.length ? "Seleccione" : "Loading..."
                  }
                  isRequired={participants == 2}
                  labelPlacement="outside"
                  classNames={{
                    popoverContent: "rounded-md",
                  }}
                  inputProps={{
                    classNames: {
                      input: "ml-1",
                      inputWrapper: "h-[48px] border-1 rounded-md",
                      label:
                        "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                    },
                  }}
                  className="w-full"
                  selectedKey={partnerCountry}
                  onSelectionChange={setPartnerCountry}
                  isDisabled={!nationalities?.length}
                  startContent={
                    !nationalities?.length ? (
                      <Spinner size="sm" color="primary" />
                    ) : undefined
                  }
                >
                  {(item) => (
                    <AutocompleteItem
                      key={item.cca2}
                      textValue={item.name.common}
                    >
                      <div className="flex items-center gap-2">
                        <img
                          alt={item.name.common}
                          src={item.flags.svg}
                          className="rounded-sm w-5 "
                        ></img>
                        <span>{item.name.common}</span>
                      </div>
                    </AutocompleteItem>
                  )}
                </Autocomplete> */}
                <NationalityAutocomplete
                  label="Nacionalidad de mi pareja"
                  placeholder={
                    nationalities.length ? "Seleccione" : "Loading..."
                  }
                  isRequired
                  selectedKey={partnerCountry}
                  onSelectionChange={setPartnerCountry}
                  isDisabled={!nationalities?.length}
                  nationalities={nationalities}
                  isInvalid={messagesErrors.partner_nacionalidad_id.length > 0}
                  errorMessage={
                    messagesErrors.partner_nacionalidad_id.length
                      ? messagesErrors.partner_nacionalidad_id
                      : "Este campo es requerido"
                  }
                />
                <VisibilityState visibilityAll />
              </div>
            </div>
            <div className="text-start">
              <CustomSelect
                name="provincia_id"
                visibilityAll
                title={participants == 2 ? "Nuestra provincia" : "Mi provincia"}
                required
                items={
                  provinces?.length
                    ? provinces.map((x) => ({
                        label: x.name,
                        value: x.id.toString(),
                      }))
                    : []
                }
                placeholder={provinces?.length ? "Seleccione" : "Loading..."}
                onChange={handleChangeSelect}
                value={stepTwoData.provincia_id.toString()}
                defaultSelectedKeys={
                  isEditing && stepTwoData.provincia_id
                    ? [stepTwoData.provincia_id.toString()]
                    : []
                }
                isInvalid={messagesErrors.provincia_id.length > 0}
                errorMessage={
                  messagesErrors.provincia_id.length
                    ? messagesErrors.provincia_id
                    : "Este campo es requerido"
                }
              />
            </div>
          </div>
        </div>
        <div className="mt-10">
          <div className="text-start">
            <CustomTextAreaInput
              id="description"
              name="description"
              visibilityAll
              title="Mi descripción"
              required
              isInvalid={messagesErrors.description.length > 0}
              errorMessage={
                messagesErrors.description.length
                  ? messagesErrors.description
                  : "Este campo es requerido"
              }
              onChange={handleChangeInput}
              value={stepTwoData.description.value}
            />
          </div>
          <div
            className="text-start mt-4"
            hidden={participants == 2 ? false : true}
          >
            <CustomTextAreaInput
              required={participants == 2}
              id="partner_description"
              onChange={handleChangeInput}
              value={stepTwoData.partner_description?.value}
              visibilityAll
              isInvalid={messagesErrors.partner_description.length > 0}
              errorMessage={
                messagesErrors.partner_description.length
                  ? messagesErrors.partner_description
                  : "Este campo es requerido"
              }
              name="partner_description"
              title="Descripción de mi pareja"
            />
          </div>
        </div>
        <div className="mt-12 flex justify-center gap-4">
          {isEditing ? (
            <>
              <PrimaryButton
                loading={loading}
                // onClick={onSaveEditData}
                type="submit"
                className="w-full "
              >
                Guardar
              </PrimaryButton>
              <PrimaryButton
                disabled={loading}
                type="submit"
                className="w-full bg-white border-primary border-1 text-primary"
              >
                Continuar
              </PrimaryButton>
            </>
          ) : (
            <PrimaryButton loading={loading} type="submit" className="w-full">
              Continuar
            </PrimaryButton>
          )}
        </div>
      </form>
    </div>
  );
}
