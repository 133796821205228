import { Radio, RadioGroup } from "@nextui-org/react";
import VisibilityState from "./VisibilityState";
import { FormEvent, useEffect, useState } from "react";

export default function CustomRadioGroup({
  name = "",
  visibility_name = "",
  title = "Custom Radio Group",
  required = false,
  items = [],
  onVisibilityChange = () => {},
  visibilityAll = false,
  value = "",
  onChange = () => {},
  showVisbility = true,
  isInvalid,
  errorMessage = "Este campo es requerido",
}: {
  name?: string;
  visibility_name?: string;
  title?: string;
  required?: boolean;
  items?: {
    label: string;
    value: string;
  }[];
  onVisibilityChange?: (value: string) => void;
  visibilityAll?: boolean;
  value?: string;
  errorMessage?: string;
  onChange?: (e: FormEvent<HTMLInputElement>) => void;
  showVisbility?: boolean;
  isInvalid?: boolean;
}) {
  const [isValid, setIsValid] = useState(!isInvalid);
  const handleChangeInput = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    if (!data.value && required) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
    onChange(e);
  };

  useEffect(() => {
    // console.log(isInvalid);
    setIsValid(!isInvalid);
  }, [isInvalid]);

  return (
    <div className="w-full">
      <RadioGroup
        name={name}
        defaultValue={items.length ? items[0].value : "0"}
        value={value}
        onChange={handleChangeInput}
        label={title}
        isRequired={required}
        errorMessage={errorMessage}
        size="sm"
        isInvalid={!isValid}
        orientation="horizontal"
        className="w-full"
        classNames={{
          label: "font-semibold text-[14px] text-color5",
        }}
      >
        {items.map((item) => (
          <Radio
            key={item.value}
            classNames={{
              label: "font-normal text-[14px] text-color5",
              wrapper: "group-data-[selected=true]:border-color4",
              control: "bg-color4",
            }}
            value={item.value}
          >
            {item.label}
          </Radio>
        ))}
      </RadioGroup>
      {showVisbility ? (
        <VisibilityState
          onVisibilityChange={onVisibilityChange}
          visibilityAll={visibilityAll}
          visibility_name={visibility_name}
        />
      ) : (
        <></>
      )}
      {items.length ? "" : "Loading..."}
    </div>
  );
}
