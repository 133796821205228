import {
  Avatar,
  Badge,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Navbar,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
} from "@nextui-org/react";
import Logo from "./Logo";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { useAuthStore } from "../../stores/authStore";
import { useLoginStore } from "../../stores/loginDataStore";
import { FriendIcon, UserIcon } from "../../assets/icons/UserIcon";
import { UsersIcon } from "../../assets/icons/UsersIcon";
import { SettingsIcon } from "../../assets/icons/Settings";
import { LogOutIcon } from "../../assets/icons/LogOutIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import { ChatIcon } from "../../assets/icons/ChatIcon";
import { NotificationIcon } from "../../assets/icons/NotificationIcon";
import PremiumModal from "./PremiumModal";
import PremiumIcon from "../../assets/icons/PremiumIcon";
import { useHomeStore } from "../../stores/homeData";
import { useNotificationsStore } from "../../stores/notificationStore";
import {
  deleteAll,
  deleteFromList,
  getNotifications,
  markAsReaded,
} from "../../api/notification";
import { Notification } from "../../interfaces/Notification";
import { TrashCanIcon } from "../../assets/icons/TrashCanIcon";
import { Key } from "@react-types/shared";
import { getPublication } from "../../api/publication";
import LoadingPostModal from "../post/LoadingPostModal";
import CommentPostModal from "../post/CommentPostModal";
import PlanModal from "./PlanModal";
import PaymentInformationModal from "./PaymentInformationModal";
import { SubscriptionRequest } from "../../interfaces/Login";
import Events from "./Events";
import { useChatStore } from "../../stores/chatStore";
import SearchModal from "./SearchModal";
import { useRegisterStore } from "../../stores/registerData";
import ShareModal from "./ShareModal";

export default function NavBar({ rightButton }: { rightButton?: JSX.Element }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openPlanModal, setIsOpenPlanModal] = useState(false);
  const [openPurchaseModal, setIsOpenPurchaseModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const resetHome = useHomeStore((state) => state.resetHome);
  const resetRegisterData = useRegisterStore((state) => state.resetData);
  const location = useLocation();

  const user = useAuthStore((state) => state.user);
  const setUser = useLoginStore((state) => state.saveUser);
  const setAuth = useAuthStore((state) => state.saveUser);
  const setToken = useAuthStore((state) => state.saveToken);
  const notificationsCount = useNotificationsStore((state) => state.count);
  const hasUnreadMessages = useChatStore((state) => state.hasUnreadMessages);

  const [isOpen, setIsOpen] = useState(false);
  const [isSearchMoadlOpen, setIsSearchMoadlOpen] = useState(false);
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPost, setIsLoadingPost] = useState(false);
  const [planSelected, setPlanSelected] = useState({
    monto: 0,
    anual: false,
  });
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const navigate = useNavigate();
  const notificationsPost = useNotificationsStore(
    (state) => state.notificationPost
  );
  const setNotificationsPost = useNotificationsStore(
    (state) => state.setNotificationPost
  );

  const [pagination, setPagination] = useState({
    current_page: 0,
    last_page: 0,
    total: 0,
  });
  const logout = () => {
    setAuth(null);
    setUser(null);
    setToken("");
    resetHome();
    resetRegisterData();
  };
  const onPrmiumClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  const itemsFirstSection = [
    {
      key: "profile",
      link: `/profile/${user?.id}`,
      icon: <UserIcon height={24} />,
      label: "Mi perfil",
    },
    {
      key: "settings",
      link: "/settings",
      icon: <SettingsIcon />,
      label: "Ajustes",
    },
    {
      key: "friends",
      link: `/friends/${user?.id}?tab=friends`,
      icon: <UsersIcon />,
      label: "Mis amigos",
    },
  ];
  const itemsSecondSection = [
    {
      key: "parties",
      link: "/parties",
      label: "Fiestas",
    },
    {
      key: "fantasies",
      link: "/fantasies",
      label: "Fantasías",
    },
    {
      key: "profiles",
      link: "/profiles",
      label: "Gente",
    },
    {
      key: "trips",
      link: "/trips",
      label: "Viajes",
    },
    {
      key: "news",
      link: "https://loouth.com/blog",
      label: "Noticias",
    },
    {
      key: "saved",
      link: "/saved",
      label: "Publicaciones guardadas",
    },
    {
      key: "blocked",
      link: "/blocked-users",
      label: "Bloqueados",
    },
  ];
  const handleGetNotifications = async (page: number) => {
    setIsLoading(true);

    try {
      const response = await getNotifications(page);
      // console.log(response);
      if (response.info?.data[0]?.data) {
        setNotifications([...notifications, ...response.info.data[0].data]);
        setPagination({
          current_page: response.info?.data[0]?.current_page,
          last_page: response.info?.data[0]?.last_page,
          total: response.info?.data[0]?.total,
        });
      }
    } catch (error) {
      console.error(error);
      //toast.error("Ha ocurrido un error cargando los datos");
    }

    setIsLoading(false);
  };
  const handleNotificationsClose = async () => {
    setPagination({
      current_page: 0,
      last_page: 0,
      total: 0,
    });
    setNotifications([]);
    await markAsReaded();
  };
  const deleteNotification = async (id: number) => {
    setNotifications(notifications.filter((e) => e.id != id));

    await deleteFromList(id);
    // console.log(res);
  };
  const deleteAllNotifications = async () => {
    setPagination({
      current_page: 0,
      last_page: 0,
      total: 0,
    });
    setNotifications([]);
    await deleteAll();
    // console.log(res);
  };
  const notificationsList = useMemo(
    () =>
      notifications.map((notification) => (
        <div
          className={`p-2 py-3 my-1 w-[290px] rounded-lg hover:bg-gray-100 transition-colors ease-soft-in  ${
            notification.visibilidad == 0 ? "bg-primary/10" : ""
          }`}
          key={notification.id}
          color="default"
        >
          <div className="flex justify-between items-center gap-2">
            <div
              onClick={() => handleClickItem(notification.id)}
              className="flex gap-2 items-center"
            >
              <Link to={`/profile/${notification.from_id}`}>
                <Avatar
                  showFallback
                  classNames={{
                    base: "bg-zinc-100",
                    icon: "text-black/50",
                  }}
                  src={
                    notification.from_user?.profile_path
                      ? notification.from_user?.profile_path.startsWith("http")
                        ? notification.from_user?.profile_path
                        : import.meta.env.VITE_BASE_URL +
                          notification.from_user?.profile_path
                      : undefined
                  }
                  className="cursor-pointer flex-shrink-0 min-w-10 h-10"
                />
              </Link>

              <div className="flex flex-col">
                <span className="font-medium">
                  {notification.from_user?.name}
                </span>
                <span className="text-default-500">
                  {notification.mensaje?.split("</b>")[1]}
                </span>
              </div>
            </div>
            <Button
              isIconOnly
              aria-label="deltete-notification"
              variant="flat"
              size="sm"
              onClick={() => deleteNotification(notification.id)}
            >
              <TrashCanIcon width={18} height={18} className="" />
            </Button>
          </div>
        </div>
      )),
    [notifications]
  );
  const handleClickItem = async (key: Key) => {
    const notif = notifications.find((e) => e.id == key);
    if (notif && (notif.tipo_id == 6 || notif.tipo_id == 2)) {
      // console.log(key);
      setIsLoadingPost(true);
      setIsOpen(false);
      handleNotificationsClose();
      const data = await getPublication(notif.object_id);
      if (data.info?.data) {
        setNotificationsPost(data.info.data[0]);
      }
      setIsLoadingPost(false);
    } else if (notif?.tipo_id == 1) {
      navigate(
        `/friends/${user?.id}?tab=${notif.solicitud ? "requests" : "friends"}`
      );
    } else if (notif?.tipo_id == 3) {
      navigate(`/parties/${notif?.object_id}`);
    } else if (notif?.tipo_id == 4) {
      navigate(`/trips/${notif?.object_id}`);
    }
  };
  const onViewPlans = () => {
    setIsModalOpen(false);
    setIsOpenPlanModal(true);
  };
  const onCompletePurchase = (plan: SubscriptionRequest) => {
    setPlanSelected(plan);
    setIsOpenPurchaseModal(true);
    setIsOpenPlanModal(false);
  };

  const handleChangeOpen = (open: boolean) => {
    setIsOpen(open);
    if (!open) {
      handleNotificationsClose();
    } else {
      setNotifications([]);
    }
  };

  return (
    <>
      <Navbar
        isBordered
        isBlurred={false}
        onMenuOpenChange={setIsMenuOpen}
        classNames={{ wrapper: "max-w-8xl lg:px-10 bg-white" }}
      >
        <NavbarContent className="flex lg:max-w-[100px]" justify="start">
          <NavbarMenuToggle
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
            className="lg:hidden"
          />
          <NavbarItem>
            <Logo />
          </NavbarItem>
        </NavbarContent>

        <NavbarContent className="hidden lg:flex gap-6 ml-2" justify="start">
          <NavbarItem>
            <Link
              className={`font-medium hover:text-primary transition-colors ease-in-out ${
                location.pathname == "/" && "text-primary"
              }`}
              color="foreground"
              to="/"
            >
              Inicio
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link
              color="foreground"
              to="/parties"
              className={`font-medium hover:text-primary transition-colors ease-in-out ${
                location.pathname.includes("/parties") && "text-primary"
              }`}
            >
              Fiestas
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link
              color="foreground"
              to="/fantasies"
              className={`font-medium hover:text-primary transition-colors ease-in-out ${
                (location.pathname.includes("/fantasies") ||
                  location.pathname.includes("/fantasy")) &&
                "text-primary"
              }`}
            >
              Fantasías
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link
              to="/trips"
              color="foreground"
              className={`font-medium hover:text-primary transition-colors ease-in-out ${
                location.pathname.includes("/trips") && "text-primary"
              }`}
            >
              Viajes
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link
              to="/profiles"
              color="foreground"
              className={`font-medium hover:text-primary transition-colors ease-in-out ${
                location.pathname.includes("/profiles") && "text-primary"
              }`}
            >
              Gente
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link
              color="foreground"
              className=" font-medium hover:text-primary transition-colors ease-in-out"
              to="https://loouth.com/blog"
            >
              Noticias
            </Link>
          </NavbarItem>
        </NavbarContent>
        <NavbarContent as="div" className="items-center gap-3" justify="end">
          <div className="gap-2 md:gap-3 flex justify-end items-center">
            {!user?.is_payed && (
              <>
                <Button
                  onClick={onPrmiumClick}
                  variant="bordered"
                  className="border-1 rounded-lg border-color4 border-color4/30 hidden xl:flex bg-color4/5"
                >
                  <PremiumIcon stroke={"#475467"} />
                  <label className="text-color4 font-semibold">
                    Hazte Premium
                  </label>
                </Button>
                <Button
                  onClick={onPrmiumClick}
                  variant="bordered"
                  isIconOnly
                  aria-label="premium"
                  className="border-1 rounded-lg border-color4/30 bg-color4/5 hidden md:flex xl:hidden"
                >
                  <PremiumIcon stroke={"#475467"} />
                </Button>
              </>
            )}
            <Button
              variant="bordered"
              isIconOnly
              aria-label="search"
              className="border-color1/30 border-1 lg:hidden rounded-lg"
              onClick={() => setIsSearchMoadlOpen(true)}
            >
              <SearchIcon />
            </Button>
            <Link to="/chat">
              <Badge
                className=""
                color="danger"
                content=""
                isInvisible={!hasUnreadMessages}
                shape="circle"
                showOutline={false}
                size="sm"
              >
                <Button
                  variant="bordered"
                  isIconOnly
                  aria-label="chat"
                  className={`border-color1/30 border-1 rounded-lg transition-colors ease-in-out ${
                    location.pathname.includes("/chat") && "border-primary/50"
                  }`}
                >
                  <ChatIcon
                    stroke={
                      location.pathname.includes("/chat")
                        ? "#A24BFD"
                        : "#667085"
                    }
                  />
                </Button>
              </Badge>
            </Link>
            <div>
              <Popover
                isOpen={isOpen}
                onOpenChange={(open) => handleChangeOpen(open)}
                onClose={handleNotificationsClose}
              >
                <Badge
                  className=""
                  color="danger"
                  content={notificationsCount}
                  isInvisible={notificationsCount == 0}
                  shape="circle"
                  showOutline={false}
                  size="sm"
                >
                  <PopoverTrigger>
                    <Button
                      variant="bordered"
                      isIconOnly
                      aria-label="notification"
                      className="border-color1 border-color1/30 border-1 rounded-lg"
                      onClick={() => handleGetNotifications(1)}
                    >
                      {" "}
                      <NotificationIcon />
                    </Button>
                  </PopoverTrigger>
                </Badge>
                <PopoverContent className="p-[1px] rounded-lg">
                  <div className="max-h-[400px] overflow-y-auto p-2">
                    {notifications.length == 0 && !isLoading ? (
                      <div>No hay notificaciones que mostrar</div>
                    ) : isLoading ? (
                      [
                        ...notificationsList,
                        ...[0, 0, 0, 0].map((_, i) => (
                          <div key={i} color="primary">
                            <div className="w-[290px] p-2 py-2 my-1 flex items-center gap-3">
                              <div>
                                <Skeleton className="flex rounded-full w-12 h-12" />
                              </div>
                              <div className="w-full flex flex-col gap-2">
                                <Skeleton className="h-3 w-full rounded-lg" />
                                <Skeleton className="h-3 w-full rounded-lg" />
                              </div>
                            </div>
                          </div>
                        )),
                      ]
                    ) : (
                      notificationsList
                    )}
                  </div>
                  {notifications.length > 0 && (
                    <div className="w-full flex gap-2 p-3 pb-1">
                      <Button
                        variant="bordered"
                        color="danger"
                        className="border-1 w-full"
                        onClick={deleteAllNotifications}
                      >
                        Eliminar todos
                      </Button>
                      <Button
                        isDisabled={
                          pagination.current_page == pagination.last_page
                        }
                        variant="bordered"
                        color="primary"
                        className="border-1 w-full"
                        onClick={() =>
                          handleGetNotifications(pagination.current_page + 1)
                        }
                      >
                        Cargar más
                      </Button>
                    </div>
                  )}
                </PopoverContent>
              </Popover>
            </div>
          </div>
          <Button
            radius="sm"
            className="hidden lg:flex pr-10 bg-zinc-200/70"
            startContent={<SearchIcon height={18} width={18} />}
            onClick={() => setIsSearchMoadlOpen(true)}
          >
            Buscar...
          </Button>
          <Dropdown>
            <DropdownTrigger>
              <Avatar
                showFallback
                classNames={{
                  base: "bg-zinc-100",
                  icon: "text-black/50",
                }}
                src={
                  user?.profile_path
                    ? user?.profile_path.startsWith("http")
                      ? user?.profile_path
                      : import.meta.env.VITE_BASE_URL + user?.profile_path
                    : undefined
                }
                className={`cursor-pointer min-w-10 min-h-10 ${
                  user?.is_payed ? "border-2 border-primary" : ""
                }`}
              />
            </DropdownTrigger>
            <DropdownMenu aria-label="Dynamic Actions" variant="flat">
              <DropdownSection title="" showDivider>
                {itemsFirstSection.map((item) => (
                  <DropdownItem
                    key={item.key}
                    color="primary"
                    href={item.link}
                    startContent={item.icon}
                  >
                    {item.label}
                  </DropdownItem>
                ))}
              </DropdownSection>
              <DropdownSection showDivider>
                {itemsSecondSection.map((item) => (
                  <DropdownItem key={item.key} href={item.link} color="primary">
                    {item.label}
                  </DropdownItem>
                ))}
              </DropdownSection>

              <DropdownSection showDivider>
                <DropdownItem
                  as="div"
                  color="primary"
                  onClick={() => setInviteModalOpen(true)}
                  startContent={<FriendIcon width={16} height={16} />}
                >
                  Invita a un amigo
                </DropdownItem>
                {!user?.is_payed ? (
                  <DropdownItem
                    as="div"
                    color="primary"
                    onClick={onPrmiumClick}
                    startContent={
                      <PremiumIcon width={16} height={16} stroke={"#475467"} />
                    }
                    className="font-semibold"
                  >
                    Hazte Premium
                  </DropdownItem>
                ) : (
                  <DropdownItem className="hidden"></DropdownItem>
                )}
              </DropdownSection>

              <DropdownSection>
                <DropdownItem
                  color="primary"
                  onClick={logout}
                  startContent={<LogOutIcon />}
                >
                  Cerrar sesión
                </DropdownItem>
              </DropdownSection>
            </DropdownMenu>
          </Dropdown>
        </NavbarContent>
        <NavbarMenu>
          {itemsFirstSection.map((item) => (
            <NavbarMenuItem key={item.key}>
              <Link color="foreground" className="w-full" to={item.link}>
                {item.label}
              </Link>
            </NavbarMenuItem>
          ))}
          {itemsSecondSection.map((item) => (
            <NavbarMenuItem key={item.key}>
              <Link color="foreground" className="w-full" to={item.link}>
                {item.label}
              </Link>
            </NavbarMenuItem>
          ))}
          <NavbarMenuItem hidden={user?.is_payed}>
            <div className="flex items-center gap-2" onClick={onPrmiumClick}>
              <PremiumIcon stroke={"#475467"} />
              <label className="text-color4 font-semibold">Hazte Premium</label>
              <span className="hidden">{rightButton}</span>
            </div>
          </NavbarMenuItem>
        </NavbarMenu>
      </Navbar>

      <PremiumModal
        isOpen={isModalOpen}
        closeModal={onPrmiumClick}
        onViewPlans={onViewPlans}
      />
      <PlanModal
        isOpen={openPlanModal}
        closeModal={() => setIsOpenPlanModal(false)}
        finalizePurchase={onCompletePurchase}
      />
      <PaymentInformationModal
        plan={planSelected}
        isOpen={openPurchaseModal}
        closeModal={() => setIsOpenPurchaseModal(false)}
      />
      <LoadingPostModal isOpen={isLoadingPost} />
      {notificationsPost && (
        <CommentPostModal
          showComments={true}
          isOpen={notificationsPost != null}
          closeModal={() => setNotificationsPost(null)}
          post={notificationsPost}
        />
      )}
      <SearchModal
        isOpen={isSearchMoadlOpen}
        closeModal={() => setIsSearchMoadlOpen(false)}
      />
      <ShareModal
        isOpen={isInviteModalOpen}
        closeModal={() => setInviteModalOpen(false)}
      />
      <Events />
    </>
  );
}
