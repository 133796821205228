import { Avatar, Button, CircularProgress, Textarea } from "@nextui-org/react";
import { FormEvent, useMemo, useState } from "react";
import { useAuthStore } from "../../stores/authStore";
import { IconamoonSendLight } from "../../assets/icons/SendIcon";
import { createPublication } from "../../api/publication";
import { toast } from "react-toastify";
import { useHomeStore } from "../../stores/homeData";
import { ImageIcon } from "../../assets/icons/ImageIcon";
import PostUploadFile from "./PostUploadFile";
import { useParams } from "react-router-dom";
import CreateTripModal from "../trip/CreateTripModal";

export function NewPostCard({ isFantasy = 0 }: { isFantasy?: 0 | 1 }) {
  const { fantasyId } = useParams();
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openTripModal, setOpenTripModal] = useState(false);

  const user = useAuthStore((state) => state.user);
  const setNewPost = useHomeStore((state) => state.setNewPost);
  const setNewFantasyPost = useHomeStore((state) => state.setNewFantasyPost);

  const [type, setType] = useState<"image" | "video">("image");

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    setContent(data.value);
  };
  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    // console.log("asdasd");
    setLoading(true);

    //Data for request
    let data = {
      content,
      type: "text",
      isFantasia: isFantasy,
      fantasia_id: "",
    };

    if (isFantasy && fantasyId) {
      data = {
        ...data,
        fantasia_id: fantasyId,
      };
    }

    const responseData = await createPublication(data);
    // console.log(responseData);

    if (responseData.error == "true") {
      toast.error(responseData.info.message + " ");
    } else {
      toast.success(responseData.info.message);
      // setHomeData({
      //   ...homeData,
      //   posts: [responseData.info.data[0], ...homeData.posts],
      // });
      if (isFantasy) setNewFantasyPost(responseData.info.data[0]);
      else setNewPost(responseData.info.data[0]);
      setContent("");
    }

    setLoading(false);
  }
  const endComponent = useMemo(() => {
    if (content.length > 3 && !loading)
      return (
        <Button
          isIconOnly
          aria-label="publish"
          size="sm"
          className="focus:outline-none absolute top-2 right-2 bg-zinc-300/20"
          type="submit"
        >
          <IconamoonSendLight height={20} width={20} />
        </Button>
      );
    if (loading)
      return (
        <CircularProgress
          className="absolute top-2 right-2"
          size="sm"
          aria-label="Loading.."
        />
      );
    return <></>;
  }, [content, loading]);

  const onImageClick = () => {
    setType(() => "image");
    setOpenModal(true);
  };
  const onVideoClick = () => {
    setType(() => "video");
    setOpenModal(true);
  };

  return (
    <section>
      <article className="p-5 md:p-10 w-full min-h-[215px] bg-white rounded-3xl  py-5 overflow-hidden overflow-y-auto">
        <div className="flex items-center gap-4 md:gap-8">
          <div>
            <Avatar
              showFallback
              classNames={{
                base: "bg-zinc-100",
                icon: "text-black/50",
              }}
              className="w-14 h-14 md:w-[68px] md:h-[68px]"
              src={
                user?.profile_path
                  ? user?.profile_path.startsWith("http")
                    ? user?.profile_path
                    : import.meta.env.VITE_BASE_URL + user?.profile_path
                  : undefined
              }
            />
          </div>
          <form className="w-full" onSubmit={handleSubmit}>
            {/* <CustomTextAreaInput
              placeholder="Cuéntanos algo..."
              value={content}
              onChange={handleChange}
              errorMessage="Por favor introduzca un texto de al menos 4 caracteres"
              required
              minLength={3}
              endContent={endComponent()}
              className="col-span-1"
            /> */}
            <Textarea
              variant="bordered"
              placeholder="Cuéntanos algo..."
              value={content}
              onChange={handleChange}
              disableAnimation
              disableAutosize
              required
              endContent={endComponent}
              className="w-full"
              classNames={{
                inputWrapper:
                  "borer-1 border-small focus:ring-1 focus:ring-transparent",
                base: "w-full",
                input:
                  "resize-y col-span-1 border-0 focus:ring-1 focus:ring-transparent",
              }}
            />
          </form>
        </div>
        <div className="mt-12 flex justify-center gap-4 md:gap-8">
          <div className="flex items-center gap-4" onClick={onImageClick}>
            <ImageIcon />
            <label className="font-bold text-[16px] text-color7 cursor-pointer hover:text-color4 transition-colors-opacity">
              Subir Imagen
            </label>
          </div>
          <div className="flex items-center gap-4" onClick={onVideoClick}>
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 11H21M1 6H6M16 6H21M1 16H6M16 16H21M6 21V1M16 21V1M5.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V5.8C21 4.11984 21 3.27976 20.673 2.63803C20.3854 2.07354 19.9265 1.6146 19.362 1.32698C18.7202 1 17.8802 1 16.2 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21Z"
                stroke="#919DAD"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <label className="font-bold text-[16px] text-color7 cursor-pointer hover:text-color4 transition-colors-opacity">
              Subir Vídeo
            </label>
          </div>
          {user?.role_id == "2" ? (
            <div
              className="flex items-center gap-4"
              onClick={() => setOpenTripModal(true)}
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 1.4578C13.053 1.16035 12.0452 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21C16.5228 21 21 16.5228 21 11C21 9.28467 20.5681 7.67022 19.8071 6.25945M16 4.75H16.005M9.50005 20.8883L9.50016 18.6849C9.50017 18.5656 9.54286 18.4502 9.62053 18.3596L12.1063 15.4594C12.3106 15.2211 12.2473 14.8556 11.9748 14.6999L9.11853 13.0677C9.04093 13.0234 8.97663 12.9591 8.93234 12.8814L7.07046 9.61863C6.97356 9.44882 6.78657 9.35107 6.59183 9.36841L1.06418 9.86074M20 5C20 7.20914 18 9 16 11C14 9 12 7.20914 12 5C12 2.79086 13.7909 1 16 1C18.2091 1 20 2.79086 20 5ZM16.25 4.75C16.25 4.88807 16.1381 5 16 5C15.8619 5 15.75 4.88807 15.75 4.75C15.75 4.61193 15.8619 4.5 16 4.5C16.1381 4.5 16.25 4.61193 16.25 4.75Z"
                  stroke="#919DAD"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <label className="font-bold text-[16px] text-color7 cursor-pointer hover:text-color4 transition-colors-opacity">
                Crear Viaje
              </label>
            </div>
          ) : (
            <div className="flex items-center gap-4">
              <label className="font-bold text-[16px] text-white transition-colors-opacity">
                Crear Viaje
              </label>
            </div>
          )}
        </div>
        <PostUploadFile
          isOpen={openModal}
          type={type}
          closeModal={() => setOpenModal(false)}
          isFantasy={isFantasy}
          fantasyId={fantasyId}
        />
      </article>
      <CreateTripModal
        isOpen={openTripModal}
        closeModal={() => setOpenTripModal(false)}
        onSuccessCreateTrip={() => {}}
      />
    </section>
  );
}
