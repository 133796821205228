import {
  Avatar,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Button,
  DropdownTrigger,
  User,
  Spinner,
  Chip,
} from "@nextui-org/react";
import NavBar from "../components/common/Navbar";
import SecondaryButton from "../components/common/SecondaryButton";
import Footer from "../components/common/Footer";
import PrimaryButton from "../components/common/PrimaryButton";

import { MenuIcon } from "../assets/icons/MenuIcon";
import { CopyIcon } from "../assets/icons/CopyIcon";
import { LocationIcon } from "../assets/icons/LockationIcon";
import { HeartIcon } from "../assets/icons/HeartIcon";
import { CalendarIcon } from "../assets/icons/CalendarIcon";
import { Key, useEffect, useMemo, useState } from "react";
import {
  getParty,
  likeParty,
  requestJoinParty,
  respondRequestJoinParty,
} from "../api/party";
import { Party as PartyData, PartyRequest } from "../interfaces/Party";
import { Link, useParams } from "react-router-dom";
import { useAuthStore } from "../stores/authStore";
import { useDateFormatter } from "@react-aria/i18n";
import Moment from "react-moment";
import { FriendIcon } from "../assets/icons/UserIcon";
import PartyRequestsModal from "../components/party/PartyRequestsModal";
import { UsersIcon } from "../assets/icons/UsersIcon";
import PartyMembersModal from "../components/party/PartyMembersModal";
import PartyUsersModal from "../components/party/PartyUsersModal";
import { toast } from "react-toastify";
import { withErrorHandler } from "../utils/withErrorFallback";

const menuItems = [
  {
    key: "copy",
    icon: <CopyIcon />,
    label: "Copiar enlace de la fiesta",
  },
];

export default function Party() {
  const { partyId } = useParams();

  const [partyData, setPartyData] = useState<PartyData | undefined>();
  const authData = useAuthStore((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenMembersModal, setIsOpenMembersModal] = useState(false);
  const [isOpenUsersModal, setIsOpenUsersModal] = useState(false);
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [isRejectingRequest, setIsRejectingRequest] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    // console.log(authData?.info?.fantasias_id);
    const getData = withErrorHandler(async () => {
      setIsLoading(true);
      if (partyId) {
        const res = await getParty(parseInt(partyId));
        if (res) {
          setPartyData(res.info.data[0]);
          if (res.info.data[0].likes) {
            const data = res.info.data[0] as PartyData;
            setCheck(
              data.likes.find((e) => e.user_id == authData?.id) ? true : false
            );
          }
          // console.log(res);
        }
      }

      setIsLoading(false);
    });
    if (authData) getData();
  }, [authData]);
  const formatter = useDateFormatter({
    dateStyle: "long",
  });
  const [ckeck, setCheck] = useState(false);
  const handleRequestJoin = withErrorHandler(async () => {
    setIsSendingRequest(true);
    try {
      const payload = {
        fiesta_id: partyData?.id,
        user_id: authData?.id,
      };
      const res = await requestJoinParty(payload);
      // console.log(isSendingRequest);
      if (res.info && res.error == "false" && partyData && authData) {
        if (partyData.publica)
          setPartyData({
            ...partyData,
            integrantes: [...partyData.integrantes, authData],
          });
        else
          setPartyData({
            ...partyData,
            solicitudes: [
              ...partyData.solicitudes,
              {
                id: res.info.data[0].id,
                user: authData,
                estado: "pendiente",
                invitacion: 0,
              },
            ],
          });
      } else {
        if (
          res.error == "true" &&
          res.info.message == "This user was invited before"
        ) {
          toast.error(
            "Ya no puedes unirte a esta fiesta, debido a una previa solicitud rechazada"
          );
        } else toast.error("Ha ocurrido un error");
      }
    } catch (error) {
      console.error(error);
      toast.error("Ha ocurrido un error");
    } finally {
      setIsSendingRequest(false);
    }
  });
  const onDeleteRequest = withErrorHandler(async () => {
    if (!partyData) return;

    setIsDeleting(true);
    const payload = {
      accion: "eliminar",
    };

    const req = partyData.solicitudes.find((e) => e.user.id == authData?.id);
    if (req)
      try {
        const res = await respondRequestJoinParty(payload, req.id);
        // console.error(res);
        if (res?.error == "true" || !res.info) {
          toast.error("Ha ocurrido un error");
        } else {
          setPartyData({
            ...partyData,
            solicitudes: partyData?.solicitudes?.filter((e) => e.id != req.id),
          });
          toast.success("Solicitud cancelada con éxito");
        }
      } catch (error) {
        console.error(error);
        toast.error("Ha ocurrido un error");
      }

    setIsDeleting(false);
  });
  const amJoined = useMemo(() => {
    if (!partyData) return <></>;
    if (partyData?.user_id == authData?.id)
      return (
        <PrimaryButton
          className=" w-full max-w-sm mt-5"
          onClick={() => setIsOpenModal(true)}
        >
          <div className="flex items-center gap-2">
            <span className="">Ver solicitudes</span>
          </div>
        </PrimaryButton>
      );
    if (partyData?.integrantes?.find((e) => e.id == authData?.id))
      return (
        <PrimaryButton className=" w-full max-w-sm mt-5" disabled>
          <div className="flex items-center gap-2">
            <span className="">Estás apuntado</span>
          </div>
        </PrimaryButton>
      );
    if (
      partyData.solicitudes.find((e) => e.user.id == authData?.id) &&
      (partyData.solicitud?.invitacion == 0 || !partyData.solicitud)
    )
      return (
        <div className="max-w-sm flex flex-col">
          <PrimaryButton className=" w-full max-w-sm mt-5" disabled>
            <div className="flex items-center gap-2">
              <span className="">Solicitud pendiente</span>
            </div>
          </PrimaryButton>
          <PrimaryButton
            onClick={onDeleteRequest}
            loading={isDeleting}
            className=" w-full max-w-sm mt-5 bg-red-600"
          >
            <div className="flex items-center gap-2">
              <span className="">Cancelar</span>
            </div>
          </PrimaryButton>
        </div>
      );
    if (
      partyData.solicitud?.invitacion == 1 &&
      partyData.solicitudes.find((e) => e.user.id == authData?.id)
    )
      return (
        <>
          <PrimaryButton
            onClick={() => onRespondRequest("aprobar")}
            className=" w-full max-w-sm mt-5"
            loading={isSendingRequest}
          >
            <div className="flex items-center gap-2">
              <span className="">Aceptar</span>
            </div>
          </PrimaryButton>
          <SecondaryButton
            onClick={() => onRespondRequest("denegar")}
            className=" w-full max-w-sm mt-5"
            loading={isRejectingRequest}
          >
            <div className="flex items-center gap-2">
              <span className="">Rechazar</span>
            </div>
          </SecondaryButton>
        </>
      );

    if (partyData.capacidad > partyData.integrantes.length)
      return (
        <PrimaryButton
          className=" w-full max-w-sm mt-5"
          onClick={handleRequestJoin}
          loading={isSendingRequest}
        >
          <div className="flex items-center gap-2">
            <span className="">
              {partyData.publica ? "Unirse" : "Solicitar unirse"}
            </span>
          </div>
        </PrimaryButton>
      );
    return <></>;
  }, [partyData, isDeleting, isSendingRequest, isRejectingRequest]);

  const onRespondRequest = withErrorHandler(async (type: string) => {
    if (!partyData?.solicitud?.id || !authData) return;
    type == "aprobar" ? setIsSendingRequest(true) : setIsRejectingRequest(true);

    const id = partyData?.solicitud?.id;
    const payload = {
      accion: type,
    };
    try {
      const res = await respondRequestJoinParty(payload, id);
      // console.log(res);
      if (res?.error == "true" || !res.info) {
        toast.error("Ha ocurrido un error");
      } else {
        if (type == "aprobar") {
          toast.success("Solicitud aceptada con éxito");

          setPartyData({
            ...partyData,
            solicitudes: [...partyData.solicitudes.filter((e) => e.id != id)],
            integrantes: [...partyData.integrantes, authData],
          });
        } else {
          toast.success("Solicitud rechazada con éxito");
          setPartyData({
            ...partyData,
            solicitudes: [...partyData.solicitudes.filter((e) => e.id != id)],
          });
        }
      }
    } catch (error) {
      console.error(error);
      toast.error("Ha ocurrido un error");
    }

    type == "aprobar"
      ? setIsSendingRequest(false)
      : setIsRejectingRequest(false);
  });

  const onRequestAction = (type: string, req: PartyRequest) => {
    const item = partyData?.solicitudes?.find((e) => e.id == req.id);
    if (item) {
      item.estado = type == "aprobar" ? "aprobado" : "denegado";
    }
    if (partyData && item) {
      if (type == "eliminar" || type == "rechazar")
        setPartyData({
          ...partyData,
          solicitudes: partyData?.solicitudes?.filter((e) => e.id != req.id),
        });
      else
        setPartyData({
          ...partyData,
          solicitudes: [...partyData.solicitudes.filter((e) => e.id != req.id)],
          integrantes: [...partyData.integrantes, req.user],
        });
    }
  };
  const handleLike = async () => {
    if (partyData?.id) {
      // console.log(ckeck);
      setCheck(!ckeck);
      await likeParty(partyData?.id);
      // console.log(res);
    }
  };
  return (
    <div className=" h-screen">
      <NavBar />
      <main className="p-4 lg:px-10 flex justify-center w-full overflow-hidden ">
        {isLoading && (
          <div className="w-full flex justify-center pt-20 h-[50vh]">
            <Spinner color="primary" />
          </div>
        )}
        {!isLoading && (
          <div className="w-full max-w-8xl">
            <section className="w-full pb-10">
              <div
                className={`h-96 rounded-md w-full bg-center bg-cover bg-[url('/assets/fantasyImage.jpeg')] `}
                style={{
                  backgroundImage: ` url(${
                    partyData?.imagen
                      ? partyData?.imagen.startsWith("http")
                        ? partyData?.imagen
                        : import.meta.env.VITE_BASE_URL + partyData?.imagen
                      : undefined
                  })`,
                }}
              ></div>
              <div className="flex flex-col md:flex-row flex-wrap justify-between lg:px-2 w-full mt-10">
                <div className="w-full flex justify-between">
                  <div className="">
                    <User
                      name={
                        <span>
                          Por <b>{partyData?.user?.name}</b>
                        </span>
                      }
                      description={
                        <span className="text-sm text-gray-500">
                          <Moment fromNow locale="Es">
                            {partyData?.created_at}
                          </Moment>
                        </span>
                      }
                      avatarProps={{
                        src: partyData?.user?.profile_path
                          ? partyData?.user?.profile_path?.startsWith("http")
                            ? partyData?.user?.profile_path
                            : import.meta.env.VITE_BASE_URL +
                              partyData?.user?.profile_path
                          : undefined,
                      }}
                    />
                  </div>
                  <div className="flex  gap-2">
                    <Button
                      className={`border-1 bg-color8 like ${
                        ckeck ? "checked" : ""
                      }`}
                      variant="bordered"
                      isIconOnly
                      aria-label="like"
                      onClick={handleLike}
                    >
                      <div className="checkmark flex items-center justify-center">
                        <HeartIcon height={24} width={24} />
                      </div>
                    </Button>

                    <Dropdown>
                      <DropdownTrigger>
                        <Button
                          className="border-1"
                          variant="bordered"
                          isIconOnly
                          aria-label="menu"
                        >
                          <MenuIcon />
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        variant="flat"
                        aria-label="Dropdown menu with icons"
                        onAction={async (key: Key) => {
                          if (key == "copy") {
                            await navigator.clipboard.writeText(location.href);
                          }
                        }}
                      >
                        {menuItems.map((e) => (
                          <DropdownItem
                            key={e.key}
                            startContent={e.icon}
                            color="primary"
                          >
                            {e.label}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>

                <div className="flex gap-3 flex-col w-full lg:w-9/12 lg:pr-8">
                  <div className="w-full flex flex-col mt-4">
                    <h1 className="text-4xl text-color3 font-bold flex items-center gap-4">
                      {partyData?.nombre}
                      {partyData?.precio == "0" && (
                        <Chip variant="flat" color="primary" className="mt-2">
                          Gratis
                        </Chip>
                      )}
                    </h1>

                    {/* <p className="mt-6 text-color1">{partyData?.descripcion}</p> */}
                    <div className="flex gap-2 mt-3">
                      {partyData?.fantasias?.map((fantasy) => (
                        <Link to={`/fantasy/${fantasy.id}`} key={fantasy.id}>
                          <Button
                            radius="sm"
                            variant="bordered"
                            className=" border-1 border-color1/20"
                            size="sm"
                          >
                            {fantasy.name}
                          </Button>
                        </Link>
                      ))}
                    </div>
                    <div className="flex flex-col md:flex-row gap-6 mt-5">
                      <span className="text-gray-500 flex gap-2 itesms-center">
                        <LocationIcon width={20} stroke={"#A24BFD"} />{" "}
                        {partyData?.direccion}
                      </span>

                      {partyData && (
                        <span className="text-gray-500  flex gap-2 items-center">
                          <CalendarIcon width={20} stroke={"#A24BFD"} />{" "}
                          {partyData.fecha_fin && partyData.fecha_inicio
                            ? formatter.formatRange(
                                new Date(partyData.fecha_inicio),
                                new Date(partyData.fecha_fin)
                              )
                            : "--"}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col mt-6">
                      <h3 className="text-2xl text-color3 font-semibold">
                        Descripción
                      </h3>
                      <p className="mt-6 text-color1">
                        {partyData?.descripcion}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col flex-wrap w-full lg:w-3/12 mt-6 lg:mt-0">
                  <div className="w-full max-w-sm lg:w-auto sm:pr-5 lg:pr-0">
                    {amJoined}
                    {partyData?.user_id == authData?.id && (
                      <SecondaryButton
                        className="w-full mt-4"
                        onClick={() => setIsOpenUsersModal(true)}
                      >
                        <FriendIcon />

                        <label className=" inline-flex text-color2">
                          Añadir un amigo
                        </label>
                      </SecondaryButton>
                    )}
                  </div>
                  <div className="border-1 mt-5 border-gray-300 w-full max-w-sm rounded-2xl lg:ml-0 ">
                    <div className="p-6">
                      <h3 className="font-bold text-[16px] text-black">
                        Usuarios apuntados ({partyData?.integrantes.length})
                      </h3>
                    </div>
                    <hr className="border-0.5 border-gray-300"></hr>
                    <div className="p-3  flex flex-col gap-6">
                      <div className="flex flex-wrap gap-8 justify-start px-2">
                        {partyData?.integrantes.map((user) => (
                          <Link to={`/profile/${user.id}`} key={user.id}>
                            <Avatar
                              showFallback
                              className="w-[48px] h-[48px] "
                              classNames={{
                                base: "bg-zinc-100",
                                icon: "text-black/50",
                              }}
                              src={
                                user?.profile_path
                                  ? user?.profile_path.startsWith("http")
                                    ? user?.profile_path
                                    : import.meta.env.VITE_BASE_URL +
                                      user?.profile_path
                                  : undefined
                              }
                              name={user.name}
                            />
                          </Link>
                        ))}
                        {partyData?.integrantes.length == 0 ? (
                          <p>Nadie se ha apuntado</p>
                        ) : (
                          <></>
                        )}
                      </div>

                      <SecondaryButton
                        className="w-full  mt-5"
                        onClick={() => setIsOpenMembersModal(true)}
                      >
                        <div className="flex items-center gap-2">
                          <UsersIcon width={20} height={20} />
                          <span className="">Ver todos</span>
                        </div>
                      </SecondaryButton>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
        {!!partyData && (
          <>
            <PartyRequestsModal
              isOpen={isOpenModal}
              party={partyData}
              closeModal={() => setIsOpenModal(false)}
              onRequestAction={onRequestAction}
            />
            <PartyMembersModal
              party={partyData}
              isOpen={isOpenMembersModal}
              closeModal={() => setIsOpenMembersModal(false)}
            />
            <PartyUsersModal
              party={partyData}
              isOpen={isOpenUsersModal}
              closeModal={() => setIsOpenUsersModal(false)}
              setPartyData={setPartyData}
            />
          </>
        )}
      </main>
      <Footer />
    </div>
  );
}
