import {
  Spinner,
  Selection,
  Dropdown,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  RangeValue,
  Divider,
} from "@nextui-org/react";
import NavBar from "../components/common/Navbar";
import SecondaryButton from "../components/common/SecondaryButton";
import Footer from "../components/common/Footer";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { useAuthStore } from "../stores/authStore";
import { ActivityPost, Post } from "../interfaces/Post";
import { getActivity, getPublicationsByUser } from "../api/publication";
import { toast } from "react-toastify";
import {
  getFantasies,
  getProvinces,
  getSexualOrientations,
} from "../api/formSelectsData";
import { SexualOrientation } from "../interfaces/SexualOrientation";
import { Province } from "../interfaces/Provice";
import { Fantasy } from "../interfaces/Fantasy";
import { useUserStore } from "../stores/userStore";
import { getUser } from "../api/user";
import { useParams } from "react-router-dom";
import ProfileCard from "../components/profile/ProfileCard";
import AboutUser from "../components/profile/AboutUser";
import ProfilePosts from "../components/profile/ProfilePosts";
import ProfileFeaturedContent from "../components/profile/ProfileFeaturedContent";
import PartyList from "../components/party/PartyList";
import TripList from "../components/trip/TripList";
import FantasiesList from "../components/fantasy/FantasiesList";
import FriendsTab from "../components/friend/FriendsTab";
import { Filters as TripFilters, Trip } from "../interfaces/Trip";
import { getTrips } from "../api/trip";
import { DateValue, parseDate } from "@internationalized/date";
import { getParties } from "../api/party";
import { Filters as PartyFilters, Party } from "../interfaces/Party";
// import PostImage from "../components/post/PostImage";
import { useHomeStore } from "../stores/homeData";
import { ProfileDetails } from "../components/profile/ProfileDetails";
import { Pagination } from "../interfaces/pagination";
import ProfileFourthStepForm from "../components/auth/ProfileInfoFourthStepForm";
import ProfileFiveStepForm from "../components/auth/ProfileInfoFiveStepForm";
import ProfileThirdStepForm from "../components/auth/ProfileInfoThirdStepForm";
import { useStepperStore } from "../stores/stepperStore";
import { useRegisterStore } from "../stores/registerData";
import LocalInfoForm from "../components/auth/LocalEditInfo";
import { saveUserInfo } from "../api/info";
import Galery from "../components/profile/Gallery";

const tabs = new Map();
tabs.set("activity", "Actividad");
tabs.set("about", "Sobre mí");
tabs.set("parties", "Fiestas");
tabs.set("trips", "Viajes");
tabs.set("fantasies", "Fantasías");
tabs.set("friends", "Amigos");
tabs.set("photos", "Fotos");

export default function MyProfile() {
  const { userId } = useParams();

  const authData = useAuthStore((state) => state.user);
  const setAuthData = useAuthStore((state) => state.saveUser);
  // const userData = useUserStore((state) => state.user);
  const setUserData = useUserStore((state) => state.saveUser);
  const [localData, setLocalData] = useState({
    owners_name: "",
    locals_name: "",
    movil: "",
    CIF: "",
    direccion: "",
    ubicacion: "",
  });

  const [info, setInfo] = useState({
    orientations: [] as SexualOrientation[],
    provinces: [] as Province[],
    fantasies: [] as Fantasy[],
  });
  const [partyFilters, setPartyFilters] = useState<PartyFilters>({
    fantasias_id: new Set<string>([]),
    types_id: new Set<string>([]),
    user_id: undefined,
    estado: 1,
    attended: false,
  });
  const [pagination, setPagination] = useState<Pagination>({
    current_page: 0,
    last_page: 0,
    total: 0,
    per_page: 1,
  });

  const [tripFilters, setTripFilters] = useState<TripFilters>({
    fantasias_id: new Set<string>([]),
    tipo_viaje: new Set<string>([]),
    user_id: undefined,
  });
  const [tripPagination, setTripPagination] = useState<Pagination>({
    current_page: 0,
    last_page: 0,
    total: 0,
    per_page: 1,
  });

  const [reactions, setReactions] = useState<ActivityPost[]>([]);
  const [lastPosts, setLastPosts] = useState<Post[]>([]);
  // const [_, setError] = useState("");
  const [isLoadingInfo, setIsLoadingInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPosts, setIsLoadingPosts] = useState(false);
  const [isLoadingreactions, setIsLoadingReactions] = useState(false);
  const [tab, setTab] = useState("activity");
  const userData = useUserStore((state) => state.user);
  const [selectedKeys, setSelectedKeys] = useState(new Set(["activity"]));

  const [data, setData] = useState({
    trips: [] as Trip[],
    totalTrips: 0,
    parties: [] as Party[],
    totalParties: 0,
  });
  const [isLoadingTrips, setIsLoadingTrips] = useState(false);
  const [isLoadingParties, setIsLoadingParties] = useState(false);
  const [isSavingLocal, setIsSavingLocal] = useState(false);

  const [date, setDate] = useState<RangeValue<DateValue>>({
    start: parseDate(new Date().toISOString().split("T")[0]),
    end: parseDate(
      new Date(new Date().setMonth(new Date().getMonth() + 4))
        .toISOString()
        .split("T")[0]
    ),
  });

  const newComment = useHomeStore((state) => state.newComment);
  const setNewComment = useHomeStore((state) => state.setNewComment);
  const setStep = useStepperStore((state) => state.setStep);
  const step = useStepperStore((state) => state.step);

  // all steps data
  const setStepTwoData = useRegisterStore((state) => state.setStepTwoData);
  const setStepThreeData = useRegisterStore((state) => state.setStepThreeData);
  const setStepFourData = useRegisterStore((state) => state.setStepFourData);

  useEffect(() => {
    setSelectedKeys(new Set(["activity"]));
    setTab("activity");
    // console.log(authData?.info?.fantasias_id);
    const getData = async () => {
      setIsLoading(true);

      const userRes = await getUser(
        userId ? parseInt(userId.toString()) : authData!.id
      );

      if (userRes) {
        setUserData(userRes.info.data[0]);
        setPartyFilters({
          fantasias_id: new Set<string>([]),
          types_id: new Set<string>([]),
          user_id: userRes.info.data[0].id,
          estado: 1,
        });
        setTripFilters({
          fantasias_id: new Set<string>([]),
          tipo_viaje: new Set<string>([]),
          user_id: userRes.info.data[0].id,
        });
      }
      if (userRes?.error == "true" || !userRes) {
        // setError("Error");
        toast.error(userRes?.info?.message ?? "Ha ocurrido un error");
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      setIsLoadingInfo(true);
      setIsLoadingPosts(true);
      setIsLoadingReactions(true);

      if (userRes.info.data[0]?.id == authData?.id) {
        setStep(2);
        if (authData?.role_id == "2") {
          if (authData?.info?.detalles) {
            const details = {
              ...authData?.info?.detalles,
              is_couple: authData.info.is_couple,
              fantasias_id: authData.info.fantasias_id,
              provincia_id: authData.info.provincia_id,
            };
            setStepTwoData(details);
          }
          if (authData?.info?.como_soy)
            setStepThreeData(authData?.info?.como_soy);

          if (authData?.info?.otros) {
            const others = {
              ...authData?.info?.otros,
              idiomas_id: authData?.info?.idiomas_id?.toString(),
              horoscopo_id: authData?.info?.horoscopo_id?.toString(),
              profesion_id: authData?.info?.profesion_id?.toString(),
              partner_idioma_id: authData?.info?.otros?.partner_idioma_id,
            };
            setStepFourData(others);
          }
        } else if (authData?.role_id == "3") {
          setLocalData({
            owners_name: authData?.info?.owners_name ?? "",
            locals_name: authData?.info?.locals_name ?? "",
            movil: authData?.info?.movil ?? "",
            CIF: authData?.info?.CIF ?? "",
            direccion: authData?.info?.direccion ?? "",
            ubicacion: authData?.info?.ubicacion ?? "",
          });
        }
      }
      const orientations = await getSexualOrientations();
      const provinces = await getProvinces();
      const fantasies = await getFantasies();
      setInfo({
        ...info,
        orientations: orientations?.info?.data[0] ?? [],
        provinces: provinces?.info?.data[0] ?? [],
        fantasies: fantasies?.info?.data[0] ?? [],
      });

      setIsLoadingInfo(false);

      // --- User Posts
      const postsRes = await getPublicationsByUser({
        user_id: userRes.info.data[0]?.id,
      });
      setIsLoadingPosts(false);
      if (postsRes?.error == "true" || !postsRes) {
        toast.error(postsRes.info.message + " ");
      }
      if (postsRes?.info?.data[0]?.data) {
        setLastPosts(postsRes.info.data[0].data);
        // console.log("postsRes.info.data[0]", postsRes.info.data[0].data);
      }

      // --- User Posts reactions
      const res = await getActivity(userRes.info.data[0]?.id);

      if (res.error == "true") {
        toast.error(res.info.message + " ");
      }
      if (res?.info?.data) {
        setReactions(res.info.data[0]);
      }
      setIsLoadingReactions(false);
    };
    if (userId) getData();
  }, [userId, authData]);

  useEffect(() => {
    // console.log(partyFilters);
    if (tab == "trips") getTripsData();
    if (tab == "parties") getPartiesData();
  }, [date, partyFilters, tripFilters, tab]);

  useEffect(() => {
    if (newComment) {
      // console.log("newComment", newComment);
      const upd = reactions.map((p) => {
        if (p.id == newComment.post && p.info) {
          p.info.comentarios += newComment.count;
        }
        return p;
      });

      setReactions(upd);
      const postUpd = lastPosts.map((p) => {
        if (p.id == newComment.post && p.info) {
          p.info.comentarios += newComment.count;
        }
        return p;
      });

      setLastPosts(postUpd);
      setNewComment(null);
    }
  }, [newComment]);

  const featuredContent = useMemo(() => {
    if (lastPosts.length)
      return lastPosts.filter((x) => x.media == "image").reverse();

    return [];
  }, [lastPosts]);

  const fantasies = useMemo(() => {
    if (info.fantasies && userData?.info?.fantasias_id)
      return info.fantasies.filter((e) =>
        userData?.info?.fantasias_id?.split(",").includes(e.id.toString())
      );
    return [];
  }, [userData, info.fantasies]);

  const selectedValue = useMemo(
    () => Array.from(selectedKeys).join(", ").replaceAll("_", " "),
    [selectedKeys]
  );
  const onSelectionChange = (key: Selection) => {
    // console.log(key != "all" ? key.keys() : "");
    const selected: string[] = [];

    if (key != "all")
      key.forEach((el) => {
        selected.push(el.toString());
      });

    setSelectedKeys(new Set([...selected]));
  };
  useEffect(() => {
    setTab(Array.from(selectedKeys).join(", ").replaceAll("_", " "));
  }, [selectedKeys]);

  const getTripsData = async (page?: number, orderColumn?: string) => {
    setIsLoadingTrips(true);

    const filters = {
      nombre: "",
      fecha_inicio: date.start,
      fecha_fin: date.end,
      page,
      orderColumn,
      fantasias_id:
        Array.from(tripFilters.fantasias_id).length > 0
          ? Array.from(tripFilters.fantasias_id).join(",")
          : undefined,
      user_id: userData?.id,
      tipo_viaje:
        Array.from(tripFilters.tipo_viaje).length > 0
          ? Array.from(tripFilters.tipo_viaje).join(",")
          : undefined,
    };

    const trips = await getTrips(filters);
    setData({
      ...data,
      trips: trips?.info?.data[0].data ?? [],
      totalTrips: trips?.info?.data[0].total,
    });
    setTripPagination({
      current_page: trips?.info?.data[0].current_page,
      last_page: trips?.info?.data[0].last_page,
      total: trips?.info?.data[0].total,
      per_page: trips?.info?.data[0].per_page,
    });
    setIsLoadingTrips(false);
  };

  const getPartiesData = async (page?: number, orderColumn?: string) => {
    setIsLoadingParties(true);

    const filtersData = {
      nombre: "",
      fecha_inicio: date.start,
      fecha_fin: date.end,
      page,
      fantasias_id:
        Array.from(partyFilters.fantasias_id).length > 0
          ? Array.from(partyFilters.fantasias_id).join(",")
          : undefined,
      user_id: userData?.id,
      orderColumn,
      tipo_id:
        Array.from(partyFilters.types_id).length > 0
          ? Array.from(partyFilters.types_id).join(",")
          : undefined,
      estado: partyFilters.estado,
      attended: partyFilters.attended,
    };
    //console.log(filtersData.user_id);
    const parties = await getParties(filtersData);
    setPagination({
      current_page: parties?.info?.data[0].current_page,
      last_page: parties?.info?.data[0].last_page,
      total: parties?.info?.data[0].total,
      per_page: parties?.info?.data[0].per_page,
    });
    setData({
      ...data,
      parties: parties?.info?.data[0].data ?? [],
      totalParties: parties?.info?.data[0].total,
    });
    setIsLoadingParties(false);
  };

  const onPageChange = async (page: number, orderColumn?: string) => {
    getPartiesData(page, orderColumn);
  };
  const onTripsPageChange = async (page: number, orderColumn?: string) => {
    getTripsData(page, orderColumn);
  };
  const backStep = () => {
    setStep(step - 1);
  };
  const onEditClick = () => {
    setSelectedKeys(new Set(["edit"]));
    setTab("edit");
    // console.log("ediiiiit");
    setStep(2);
  };
  // const handleLocalChange = (e: FormEvent<HTMLInputElement>) => {
  //   const target = e.target as HTMLInputElement;
  //   setLocalData({
  //     ...localData,
  //     [target.name]: target.value,
  //   });
  // };
  async function submitForLocal(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const data = {
      ...localData,
      user_id: authData?.id,
    };

    setIsSavingLocal(true);
    const response = await saveUserInfo(data);
    if (response.error == "true") {
      toast.error(response.info.message);
    } else {
      toast.success("Los datos del local se guardaron correctamente");
      setAuthData(response.info.data[0]);
    }
    setIsSavingLocal(false);
  }
  return (
    <>
      <NavBar />
      <div className="w-full">
        <main className=" flex justify-center w-full overflow-hidden">
          <div className="w-full max-w-8xl">
            {!isLoading && (
              <section className="w-full">
                <ProfileCard
                  orientations={info.orientations}
                  onEditClick={onEditClick}
                  child={
                    <div className="hidden lg:flex w-full gap-2">
                      <SecondaryButton
                        className={`font-semibold text-[14px] ${
                          tab == "activity" ? "text-color4" : "text-color2"
                        }`}
                        onClick={() => setTab("activity")}
                      >
                        Actividad
                      </SecondaryButton>
                      {userData?.role_id == "2" && (
                        <SecondaryButton
                          className={`font-semibold text-[14px] ${
                            tab == "about" ? "text-color4" : "text-color2"
                          }`}
                          onClick={() => setTab("about")}
                        >
                          Sobre mí
                        </SecondaryButton>
                      )}

                      <SecondaryButton
                        className={`font-semibold text-[14px] ${
                          tab == "friends" ? "text-color4" : "text-color2"
                        }`}
                        onClick={() => setTab("friends")}
                      >
                        Amigos
                      </SecondaryButton>

                      <SecondaryButton
                        className={`font-semibold text-[14px] ${
                          tab == "parties" ? "text-color4" : "text-color2"
                        }`}
                        onClick={() => setTab("parties")}
                      >
                        Fiestas
                      </SecondaryButton>
                      {userData?.role_id == "2" && (
                        <SecondaryButton
                          className={`font-semibold text-[14px] ${
                            tab == "fantasies" ? "text-color4" : "text-color2"
                          }`}
                          onClick={() => setTab("fantasies")}
                        >
                          Fantasías
                        </SecondaryButton>
                      )}
                      {userData?.role_id == "2" && (
                        <SecondaryButton
                          className={`font-semibold text-[14px] ${
                            tab == "trips" ? "text-color4" : "text-color2"
                          }`}
                          onClick={() => setTab("trips")}
                        >
                          Viajes
                        </SecondaryButton>
                      )}
                      <SecondaryButton
                        className={`font-semibold text-[14px] ${
                          tab == "photos" ? "text-color4" : "text-color2"
                        }`}
                        onClick={() => setTab("photos")}
                      >
                        Galería
                      </SecondaryButton>
                    </div>
                  }
                  mobileChild={
                    <div className="w-full flex gap-4 justify-end">
                      <Dropdown>
                        <DropdownTrigger>
                          <Button
                            variant="bordered"
                            radius="sm"
                            className="capitalize border-1 md:px-10"
                            endContent={
                              <svg
                                className="mt-1"
                                width="12"
                                height="8"
                                viewBox="0 0 12 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 1.5L6 6.5L11 1.5"
                                  stroke="#344054"
                                  strokeWidth="1.66667"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            }
                          >
                            {tabs.get(selectedValue)}
                          </Button>
                        </DropdownTrigger>
                        <DropdownMenu
                          aria-label="Single selection example"
                          variant="flat"
                          disallowEmptySelection
                          selectionMode="single"
                          selectedKeys={selectedKeys}
                          onSelectionChange={onSelectionChange}
                        >
                          <DropdownItem key="activity">Actividad</DropdownItem>
                          <DropdownItem key="about">Sobre mí</DropdownItem>
                          <DropdownItem key="friends">Amigos</DropdownItem>
                          <DropdownItem key="parties">Fiestas</DropdownItem>
                          <DropdownItem
                            hidden={userData?.role_id == "3"}
                            key="fantasies"
                          >
                            Fantasías
                          </DropdownItem>

                          <DropdownItem
                            hidden={userData?.role_id == "3"}
                            key="trips"
                          >
                            Viajes
                          </DropdownItem>

                          <DropdownItem key="photos">Galería </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  }
                />
                <Divider className="mt-4 max-w-8xl h-[2px] bg-zinc-200" />
                {tab == "about" && (
                  <>
                    <ProfileDetails
                      orientations={info.orientations}
                      userData={userData}
                      provinces={info.provinces}
                      fantasies={fantasies}
                      isLoading={isLoadingInfo}
                    />
                  </>
                )}

                {tab == "activity" && (
                  <>
                    <AboutUser
                      provinces={info.provinces}
                      fantasies={fantasies}
                      isLoading={isLoadingInfo}
                    />
                    {isLoadingPosts || lastPosts.length ? (
                      <>
                        <div className="mt-10 flex justify-start px-6">
                          <h3 className="font-bold text-[18px] text-color3 ">
                            Últimas publicaciones
                          </h3>
                        </div>

                        <ProfilePosts
                          isLoadingPosts={isLoadingPosts}
                          posts={lastPosts}
                        />
                      </>
                    ) : (
                      <div className="mt-10 flex justify-start px-6 flex-col ">
                        <h3 className="font-bold text-[18px] text-color3  mb-5">
                          Últimas publicaciones
                        </h3>
                        <p className="px-2">No hay publicaciones que mostrar</p>
                      </div>
                    )}
                    {isLoadingreactions || reactions.length ? (
                      <>
                        <div className="mt-10 flex justify-start px-6 flex-col">
                          <h3 className="font-bold text-[18px] text-color3 ">
                            Últimas reacciones
                          </h3>
                        </div>

                        <ProfilePosts
                          isLoadingPosts={isLoadingreactions}
                          posts={reactions}
                        />
                      </>
                    ) : (
                      <div className="mt-10 flex justify-start px-6 flex-col">
                        <h3 className="font-bold text-[18px] text-color3 mb-5">
                          Últimas reacciones
                        </h3>
                        <p className="px-2">No hay publicaciones que mostrar</p>
                      </div>
                    )}
                    {isLoadingPosts || featuredContent.length ? (
                      <>
                        <div className="mt-10 flex justify-start px-6">
                          <h3 className="font-bold text-[18px] text-color3 ">
                            Contenido Destacado
                          </h3>
                        </div>
                        <ProfileFeaturedContent
                          posts={featuredContent}
                          isLoadingPosts={isLoadingPosts}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                {tab == "parties" && (
                  <PartyList
                    isLoading={isLoadingParties}
                    parties={data.parties}
                    dateRange={date}
                    onChangeDate={setDate}
                    filters={partyFilters}
                    setFilters={setPartyFilters}
                    total={pagination.total}
                    pagination={pagination}
                    onPageChange={onPageChange}
                    showVisibiltySelect={authData?.id == userData?.id}
                    showTypeSelect
                  />
                )}
                {tab == "trips" && (
                  <TripList
                    isLoading={isLoadingTrips}
                    trips={data.trips}
                    dateRange={date}
                    onChangeDate={setDate}
                    filters={tripFilters}
                    setFilters={setTripFilters}
                    pagination={tripPagination}
                    total={tripPagination.total}
                    onPageChange={onTripsPageChange}
                  />
                )}
                {tab == "fantasies" && (
                  <FantasiesList isLoading={isLoading} fantasies={fantasies} />
                )}
                {tab == "photos" && userData && <Galery user={userData} />}
                {tab == "friends" && userData?.id != undefined && (
                  <FriendsTab userId={userData?.id.toString()} />
                )}
                {tab == "edit" && (
                  <div className="w-full flex justify-center">
                    {userData?.role_id == "2" ? (
                      <div className="max-w-screen-xl w-full">
                        {/*Third step for register user "Detalles del perfil" */}
                        <div className="w-full flex justify-center  hide-scroll lg:px-16">
                          <ProfileThirdStepForm
                            isEditing
                            isLocal={false}
                            participants={authData?.info?.is_couple ? 2 : 1}
                            backStep={backStep}
                            next={() => console.log("next")}
                          />
                        </div>
                        {/*Fourth step for register user "Como soy" */}
                        <div className="w-full flex justify-center lg:px-16">
                          <ProfileFourthStepForm
                            isEditing
                            participants={authData?.info?.is_couple ? 2 : 1}
                            backStep={backStep}
                          />
                        </div>
                        {/*Fifth step for register user "Otros datos" */}
                        <div className="w-full flex justify-center lg:px-16">
                          <ProfileFiveStepForm
                            isEditing
                            isLocal={false}
                            participants={authData?.info?.is_couple ? 2 : 1}
                            backStep={backStep}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="w-full">
                        <LocalInfoForm
                          isEditing
                          localData={localData}
                          loading={isSavingLocal}
                          handleSubmit={submitForLocal}
                          setLocalData={setLocalData}
                        />
                      </div>
                    )}
                  </div>
                )}
              </section>
            )}
            {isLoading && (
              <div className="w-full flex justify-center pt-20 h-[50vh]">
                <Spinner color="primary" />
              </div>
            )}
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}
