import { Card, CardBody, CardFooter, Image } from "@nextui-org/react";
import PrimaryButton from "../common/PrimaryButton";
import { Link } from "react-router-dom";
import SecondaryButton from "../common/SecondaryButton";
import { useState } from "react";
import { RequestData } from "../../interfaces/Login";
import { useAuthStore } from "../../stores/authStore";
import { deleteFriendRequest, respondFriendRequest } from "../../api/friends";
import { toast } from "react-toastify";
import { useHomeStore } from "../../stores/homeData";

export default function FriendCard({
  data,
  userId,
  type,
  setFriendToDelete,
  setFriendRequestsSent,
  onSuccessAccept,
  onSuccessReject,
}: {
  data: RequestData;
  userId?: number;
  type: "friend" | "request" | "requestSent";
  setFriendToDelete: () => void;
  setFriendRequestsSent: () => void;
  onSuccessAccept: () => void;
  onSuccessReject: () => void;
}) {
  const [isSendingrequest, setIsSendingrequest] = useState(false);
  const [isCancelingrequest, setIsCancelingrequest] = useState(false);
  const [isSendingReject, setIsSendingReject] = useState(false);

  const authData = useAuthStore((state) => state.user);

  const setHome = useHomeStore((state) => state.setHome);
  const home = useHomeStore((state) => state.home);

  const onCancelRequest = async () => {
    setIsCancelingrequest(true);
    const response = await deleteFriendRequest(data.reqId);
    if (response.error == "true" || !response.info)
      toast.error(response?.info?.message || "Ha ocurrido un error");
    else {
      toast.success("Solicitud cancelada con éxito");
      setFriendRequestsSent();
    }

    setIsCancelingrequest(false);
  };

  const sendRequest = async (status: string) => {
    if (status == "accept") {
      setIsSendingrequest(true);
    } else setIsSendingReject(true);
    const response = await respondFriendRequest(data.reqId, { status });
    if (response.error == "true" || !response.info)
      toast.error(response?.info?.message || "Ha ocurrido un error");
    else {
      toast.success(
        status == "accept"
          ? "Solicitud aceptada con éxito"
          : "Solicitud rechazada con éxito"
      );

      if (status == "accept") {
        onSuccessAccept();
        setHome({
          ...home,
          amigos: [...home.amigos, response?.info.data[0]],
        });
      } else onSuccessReject();
    }

    status == "accept" ? setIsSendingrequest(false) : setIsSendingReject(false);
  };
  return (
    <Card
      shadow="none"
      className="shadow-none border-1  border-gray-200 w-full"
    >
      <CardBody className="overflow-visible  p-1 ">
        <div className="relative object-fit">
          <Link to={`/profile/${data?.id}`}>
            <Image
              radius="md"
              width="100%"
              alt="post title here"
              isZoomed
              className="w-full object-cover h-[200px] md:h-[140px]"
              fallbackSrc="/assets/fantasyImage.jpeg"
              src={
                data?.profile_path
                  ? data?.profile_path.startsWith("http")
                    ? data?.profile_path
                    : import.meta.env.VITE_BASE_URL + data?.profile_path
                  : undefined
              }
            />
          </Link>
        </div>
      </CardBody>

      <CardFooter className="flex flex-col text-start items-start">
        <p className="text-lg font-bold mb-3">{data.name}</p>
        {type == "friend" ? (
          <>
            <PrimaryButton
              onClick={setFriendToDelete}
              className={`w-full ${userId != authData?.id ? "hidden" : ""}`}
            >
              Eliminar
            </PrimaryButton>
          </>
        ) : type == "request" ? (
          <>
            <PrimaryButton
              loading={isSendingrequest}
              onClick={() => sendRequest("accept")}
              className="w-full mb-2"
            >
              Aceptar
            </PrimaryButton>
            <SecondaryButton
              className="w-full "
              loading={isSendingReject}
              onClick={() => sendRequest("reject")}
            >
              Rechazar
            </SecondaryButton>
          </>
        ) : (
          <PrimaryButton
            onClick={onCancelRequest}
            className="w-full"
            loading={isCancelingrequest}
          >
            Cancelar
          </PrimaryButton>
        )}
      </CardFooter>
    </Card>
  );
}
