import { Chip, Image } from "@nextui-org/react";
import { Fantasy } from "../../interfaces/Fantasy";
import { Province } from "../../interfaces/Provice";
import { useAuthStore } from "../../stores/authStore";
import { infoDetals, useUserStore } from "../../stores/userStore";
import SecondaryButton from "../common/SecondaryButton";
import { useEffect, useMemo, useState } from "react";
import { Country } from "../../interfaces/Country";
import { getCountries } from "../../api/nationality";
import { Link } from "react-router-dom";
import { Map, Marker } from "@vis.gl/react-google-maps";
const INITIAL_CENTER = { lat: 40.416775, lng: -3.70379 };
export default function AboutUser({
  provinces,
  fantasies,
  isLoading = false,
}: {
  provinces: Province[];
  fantasies: Fantasy[];

  isLoading?: boolean;
}) {
  const userData = useUserStore((state) => state.user);
  const authData = useAuthStore((state) => state.user);

  const [countries, setCountries] = useState<Country[]>([]);
  const [loading, setLoading] = useState(false);
  const [center, setCenter] = useState<
    { lat: number; lng: number } | undefined
  >();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      if (userData?.role_id != "3") {
        const countriesRes = await getCountries();
        setCountries(countriesRes);
      } else
        try {
          const aux = userData?.info?.ubicacion?.split("|");
          if (aux && aux.length == 2) {
            const lat = parseFloat(aux[0]?.toString());
            const lng = parseFloat(aux[1]?.toString());
            // console.log(lat, lng);
            setCenter({ lat: lat, lng: lng });
          } else setCenter(INITIAL_CENTER);
        } catch (error) {
          console.error(error);
        }
      setLoading(false);
    };
    getData();
  }, []);

  const nationality = useMemo(() => {
    if (countries?.length && userData?.info?.detalles?.nacionalidad_id) {
      const aux = countries.find(
        (e) => e.cca2 == userData?.info?.detalles?.nacionalidad_id
      );
      if (aux) return aux.name.common;
    }
    return "España";
  }, [countries]);

  return (
    <div className="mt-10 flex justify-between  lg:px-14 w-full min-w-8xl flex-wrap">
      <div className="w-full lg:w-7/12 px-2 lg:px-0">
        {userData?.role_id != "3" ? (
          <>
            <h3 className="font-bold text-[16px] text-color3">Sobre Mi</h3>
            <p className="mt-3 font-normal text-16px text-color5">
              {infoDetals()?.description?.value}
            </p>
          </>
        ) : (
          <div className="flex flex-col gap-y-1">
            <div className="flex gap-2  items-center">
              <span className="font-medium lg:text-[14px] text-color1 mr-2">
                Nombre del local
              </span>
              <label>{userData?.info?.locals_name}</label>
            </div>
            <div className="flex gap-2 items-center">
              <span className="font-medium lg:text-[14px] text-color1 mr-2">
                Dueño/s
              </span>
              <label>{userData?.info?.owners_name}</label>
            </div>
            <div className="flex gap-2 items-center">
              <span className="font-medium lg:text-[14px] text-color1 mr-2">
                Dirección
              </span>
              <label>
                {userData?.role_id == "3" &&
                  (userData.info?.direccion ?? "...")}
              </label>
            </div>
            <div className="flex gap-2 items-center">
              <span className="font-medium lg:text-[14px] text-color1 mr-2">
                CIF
              </span>
              <label>{userData?.info?.CIF ?? "?"}</label>
            </div>
            <div className="flex gap-2 items-center">
              <span className="font-medium lg:text-[14px] text-color1 mr-2">
                Teléfono
              </span>
              <label>{userData?.info?.movil ?? "?"}</label>
            </div>
          </div>
        )}
        <div hidden={!userData?.info?.is_couple} className="w-full mt-6 mb-6">
          <h3 className="font-bold text-[16px] text-color3">Mi pareja</h3>
          <p className="mt-3 font-normal text-16px text-color5">
            {userData?.info?.detalles?.partner_description?.value}
          </p>
        </div>
      </div>
      <div className="w-full  flex flex-col lg:flex-row lg:justify-between gap-4 lg:gap-8 lg:w-5/12 mt-5 px-6 lg:mt-0 pl-2 lg:pl-6">
        {userData?.role_id == "3" ? (
          <div className="w-full  min-h-[260px] bg-zinc-300 border-gray-300  flex items-center justify-center rounded-xl border-2  overflow-hidden">
            {!center ? (
              "Cargando..."
            ) : (
              <Map
                defaultCenter={center}
                defaultZoom={10}
                gestureHandling={"greedy"}
                disableDefaultUI={true}
                scrollwheel={false}
                zoomControl={true}
              >
                <Marker position={center} />
              </Map>
            )}
          </div>
        ) : (
          <>
            <div className="flex flex-col items-start  gap-2">
              <span className="font-medium lg:text-[14px] text-color1 mr-2">
                Ubicación
              </span>
              <label>
                {provinces.length && userData?.info?.provincia_id
                  ? provinces.find((e) => e.id == userData?.info?.provincia_id)
                      ?.name
                  : "..."}
              </label>
              {userData?.id != authData?.id &&
                userData?.role_id != "3" &&
                authData?.role_id != "3" && (
                  <>
                    <div className="hidden min-w-[150px] mt-2 lg:mt-4 lg:flex  gap-4 p-2 justify-center items-center border-1 border-color4 rounded-lg">
                      <div className="flex justify-center items-center w-[50px] h-[50px] rounded-full border-1.5 border-color4">
                        <span className="font-medium text-[14px] text-black">
                          {userData?.afinidad
                            ? Math.round(userData?.afinidad)
                            : "?"}
                          %
                        </span>
                      </div>
                      <div className="flex flex-col gap-1 justify-center">
                        <span className="font-medium text-[14px] text-color1">
                          Afinidad
                        </span>
                        <span className="font-medium text-[16px] text-color2">
                          {(userData?.afinidad ?? 0) > 70
                            ? "Muy Alta"
                            : (userData?.afinidad ?? 0) > 50
                            ? "Alta"
                            : "Baja"}
                        </span>
                      </div>
                    </div>
                    <div className="flex lg:hidden gap-2 mt-3 items-center">
                      <Chip variant="flat" color="primary">
                        {userData?.afinidad
                          ? Math.round(userData?.afinidad)
                          : "?"}
                        %
                      </Chip>
                      <span className="font-medium  text-color1">Afinidad</span>
                      <span className="font-medium  text-color2">
                        {(userData?.afinidad ?? 0) > 70
                          ? "Muy Alta"
                          : (userData?.afinidad ?? 0) > 50
                          ? "Alta"
                          : "Baja"}
                      </span>
                    </div>
                  </>
                )}
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-wrap items-center lg:items-start  gap-2">
                <span className="font-medium lg:text-[14px] text-color1 mr-2">
                  Nacionalidad
                </span>
                {loading ? (
                  "Cargando..."
                ) : (
                  <div className="flex items-center gap-2">
                    <Image
                      radius="none"
                      src={`https://flagcdn.com/${infoDetals()?.nacionalidad_id?.toLocaleLowerCase()}.svg`}
                      fallbackSrc="https://flagcdn.com/es.svg"
                      className="w-6 rounded-none"
                      loading="lazy"
                      classNames={{
                        wrapper:
                          "bg-center bg-no-repeat bg-contain border-1 border-color1/20",
                      }}
                    />
                    <label>{nationality}</label>
                  </div>
                )}
              </div>

              <div className="flex items-center lg:items-start gap-3">
                <div className="flex flex-wrap items-center gap-2">
                  <span className="font-medium lg:text-[14px] text-color1 ">
                    Fantasías Destacadas
                  </span>
                  {isLoading ? (
                    "Cargando..."
                  ) : fantasies.length ? (
                    fantasies.map((x) => (
                      <Link key={x.id} to={`/fantasy/${x.id}`}>
                        <SecondaryButton className="text-[14px] p-2 max-h-[36px]">
                          {" "}
                          {x.name}
                        </SecondaryButton>
                      </Link>
                    ))
                  ) : (
                    <p>Sin Fantasías</p>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
