import { AxiosError } from "axios";
import { Api } from ".";
import axiosClient from "../utils/axios";

export async function createPublication(data: any) {
  try {
    return (await axiosClient.post(Api.publication.post, data)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function getPublicationsByUser(data: any, onlyMedia = false) {
  try {
    return (
      await axiosClient.get(
        `${Api.publication.post}?user_id=${data.user_id}&fantasias=0${
          onlyMedia ? "&media=1" : ""
        }`
      )
    ).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
export async function getPublications(exclude?: string) {
  try {
    return (
      await axiosClient.post(`${Api.publication.posts}`, {
        exclude: exclude ?? "0",
      })
    ).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
export async function getPublication(id: number) {
  try {
    return (await axiosClient.get(`${Api.publication.post}/${id}`)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
export async function getFantasiesPublications(exclude?: string, id?: string) {
  try {
    return (
      await axiosClient.post(
        `${Api.publication.posts}?fantasias=1${
          id ? "&fantasias_id=" + id : ""
        }`,
        {
          exclude: exclude ?? "0",
        }
      )
    ).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
export async function addPostReaction(data: any) {
  try {
    return (await axiosClient.post(`${Api.publication.reaction}`, data)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
export async function addPostComment(data: any) {
  try {
    return (await axiosClient.post(`${Api.publication.comment}`, data)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function getPostComments(id: number) {
  try {
    return (
      await axiosClient.get(`${Api.publication.comment}?publicacion_id=${id}`)
    ).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
export async function savePost(data: any) {
  try {
    return (await axiosClient.post(`${Api.publication.save}`, data)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
export async function getSavedPosts() {
  try {
    return (await axiosClient.get(`${Api.publication.save}`)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
export async function deletePostSaved(id: number) {
  try {
    return (await axiosClient.delete(`${Api.publication.save}/${id}`)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
export async function getActivity(id: number) {
  try {
    const res = (await axiosClient.get(`${Api.user.activity}/${id}`)).data;
    if (res?.info?.data[0]) {
      res.info.data[0] = (res?.info?.data[0] as any[]).map((e) => {
        if (e.tipo == "publicaciones") {
          return e;
        } else {
          return { ...e.publicacion, tipo: e.tipo };
        }
      });
    }
    return res;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
