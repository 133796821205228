import {
  // DatePicker,
  DateRangePicker,
  DateValue,
  Input,
  Modal,
  ModalContent,
  RangeValue,
  Select,
  SelectItem,
  Textarea,
} from "@nextui-org/react";
import PrimaryButton from "../common/PrimaryButton";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import FileInput from "../form/FileInput";
import { CalendarIcon } from "../../assets/icons/CalendarIcon";
import { FormEvent, useEffect, useRef, useState } from "react";
import { getLocalTimeZone, parseDate } from "@internationalized/date";
import { toast } from "react-toastify";
import { round } from "../../utils/useNumber";
import { uploadFile } from "../../api/upload";
import { Party, PartyType } from "../../interfaces/Party";
import SecondaryButton from "../common/SecondaryButton";
import CustomCheckBox from "../form/CustomCheckBox";
import { Link } from "react-router-dom";
import { Fantasy } from "../../interfaces/Fantasy";
import { getFantasies } from "../../api/formSelectsData";
import { useAuthStore } from "../../stores/authStore";
import { createParty, getPartyTypes } from "../../api/party";
import CustomRadioGroup from "../form/CustomRadioGroup";

export default function CreatePartyModal({
  isOpen = false,
  closeModal = () => {},
  onSuccessCreateParty,
}: {
  isOpen: boolean;
  closeModal?: () => void;
  onSuccessCreateParty: (trip: Party) => void;
}) {
  const [data, setData] = useState({
    nombre: "",
    descripcion: "",
    fecha_inicio: "",
    fecha_fin: "",
    localizacion: "",
    direccion: "",
    tipo_id: "",
    imagen: "",
    capacidad: "",
    precio: "",
    publica: "false",
    estado: "true",
    fantasias: "",
  });

  const inputRef = useRef(null);
  const [fileUrl, setFileUrl] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [info, setInfo] = useState({
    fantasies: [] as Fantasy[],
    partyTypes: [] as PartyType[],
  });
  const authData = useAuthStore((state) => state.user);

  useEffect(() => {
    // console.log(authData?.info?.fantasias_id);
    const getData = async () => {
      const fantasies = await getFantasies();
      const types = await getPartyTypes();
      setInfo({
        ...info,
        fantasies: fantasies?.info?.data[0] ?? [],
        partyTypes: types?.info?.data[0] ?? [],
      });
      //console.log(fantasies);
    };
    if (authData) getData();
  }, [authData]);

  const onCancel = () => {
    setFile(null);
    setFileUrl("");
    closeModal();
    setData({
      nombre: "",
      descripcion: "",
      fecha_inicio: "",
      fecha_fin: "",
      localizacion: "",
      direccion: "",
      tipo_id: "",
      imagen: "",
      precio: "",
      capacidad: "",
      publica: "false",
      estado: "true",
      fantasias: "",
    });
  };

  const onFileUpload = () => {
    if (inputRef.current) (inputRef.current as HTMLInputElement).click();
  };
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    if (
      e.nativeEvent.target instanceof HTMLInputElement &&
      e.nativeEvent.target.files
    ) {
      // Aquí puedes procesar los archivos seleccionados
      const selectedFiles = Array.from(e.nativeEvent.target.files);
      //console.log(selectedFiles);
      if (selectedFiles.length > 0) {
        setFile(selectedFiles[0]);
      }
      // console.log(file);
    }
  };
  useEffect(() => {
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      // console.log(fileUrl);

      setFileUrl(fileUrl);

      return () => {
        URL.revokeObjectURL(fileUrl);
      };
    }
  }, [file]);

  const setFormData = (name: string, value: string) => {
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleChangeInput = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    setFormData(data.name, data.value);
    // console.log(data.name);
  };
  const handleChangeSelect = (e: FormEvent<HTMLSelectElement>) => {
    const data = e.target as HTMLSelectElement;
    setFormData(data.name, data.value);
  };
  // const [date, setDate] = useState<DateValue>(now(getLocalTimeZone()));
  const [date, setDate] = useState<RangeValue<DateValue>>({
    start: parseDate(new Date(new Date()).toISOString().split("T")[0]),
    end: parseDate(
      new Date(new Date().setDate(new Date().getDate() + 3))
        .toISOString()
        .split("T")[0]
    ),
  });

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const maxSize = 7.1;

    if (file && round(file.size / (1024 * 1024)) > maxSize) {
      toast.error(`El archivo no debe superar los ${maxSize} MB`);
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();

      if (file) {
        formData.append("files[0]", file, file.name);
        formData.append("type", "image");
        formData.append("folder[0]", "parties");
      }

      const uploadResponse = await uploadFile(
        formData
        // ,
        // {
        // onUploadProgress: (progressEvent: AxiosProgressEvent) =>
        //   setPercentCompleted(
        //     Math.round((progressEvent.loaded * 100) / progressEvent.total!)
        //   ),
        // }
      );

      if (uploadResponse.error == "true") {
        toast.error(uploadResponse.info.message + " ");
        setLoading(false);
        return;
      }
      let payload = {
        ...data,
        imagen: uploadResponse.info.data[0].parties.file.content,
        nombre:
          info.partyTypes.find((x) => x.id.toString() == data.tipo_id)?.name ??
          "",
        fecha_inicio: date.start.toDate(getLocalTimeZone()), //date.toDate(getLocalTimeZone()),
        fecha_fin: date.end.toDate(getLocalTimeZone()), //date.toDate(getLocalTimeZone()),
        localizacion: data.direccion,
        publica: data.publica == "true",
        estado: data.estado == "true",
        //tipo_viaje: data.tipo_viaje == "true",
      };

      const responseData = await createParty(payload);
      // console.log(responseData);

      if (responseData.error == "true") {
        toast.error(responseData.info.message + " ");
      } else {
        toast.success(responseData.info.message);
        // if (isFantasy) setNewFantasyPost(responseData.info.data[0]);
        // else setNewPost(responseData.info.data[0]);

        //if (!data.isFantasia)
        /*setHomeData({
          ...homeData,
          posts: [responseData.info.data[0], ...homeData.posts],
        });*/
        onSuccessCreateParty(responseData.info.data[0]);
        setFile(null);
        setFileUrl("");
        setLoading(false);
        // setPercentCompleted(0);
        closeModal();
      }
    } catch (error) {
      toast.error("Error desconocido");
      // console.log(error);
    }
    //onCancel();
    setLoading(false);
  }

  const onCheck = (e: FormEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    const value = input.checked;
    setCheck(value);
  };

  return (
    <Modal
      className="max-w-[820px]"
      isOpen={isOpen}
      placement="center"
      onClose={onCancel}
      classNames={{ closeButton: "mt-4 mr-4", base: "rounded-xl border-none" }}
      closeButton={<CloseIcon />}
    >
      <ModalContent>
        <form onSubmit={handleSubmit} className="py-3">
          <div className="w-full px-6 my-5">
            <h3 className="font-semibold text-color3 text-xl ">
              Crear nueva Fiesta
            </h3>
            <p>Rellena los siguientes datos para crear tu Fiesta.</p>
          </div>

          <div className="w-full grid grid-cols-1 lg:grid-cols-2  gap-4  max-h-[400px] p-6 overflow-y-auto">
            <div className="w-full lg:col-span-2  my-3">
              <label className="mb-4 text-color1">
                Imagen de portada<span className="text-danger">*</span>
              </label>
              <FileInput
                onClick={onFileUpload}
                file={fileUrl}
                type="image"
                className="mt-3 w-full min-h-[200px] rounded-xl border-1 border-color4 cursor-pointer overflow-hidden"
              />

              <input
                ref={inputRef}
                className="hidden"
                type="file"
                accept="image/*"
                onChange={handleChange}
              ></input>
            </div>
            {/* <div className="w-full mt-[2px]">
              <Input
                placeholder="Introduce un título"
                name="nombre"
                label="Título"
                labelPlacement="outside"
                radius="sm"
                isRequired
                variant="bordered"
                color="primary"
                className="w-full"
                classNames={{
                  inputWrapper: "rounded-lg border-small",
                  label: "text-color2",
                  input: "border-0 focus:ring-1 focus:ring-transparent",
                }}
                onChange={handleChangeInput}
                value={data.nombre}
              />
            </div> */}
            <div className="w-full mt-1">
              <DateRangePicker
                label="¿Cuando será?"
                labelPlacement="outside"
                radius="sm"
                variant="bordered"
                color="primary"
                isRequired
                className="w-full"
                classNames={{
                  inputWrapper: "rounded-lg border-small",
                  label: "text-color2",
                  input: "border-0 focus:ring-1 focus:ring-transparent",
                }}
                selectorIcon={<CalendarIcon />}
                value={date}
                onChange={setDate}
              />
              {/* <DatePicker
                label="¿Cuando será?"
                labelPlacement="outside"
                radius="sm"
                variant="bordered"
                color="primary"
                isRequired
                className="w-full"
                hideTimeZone
                classNames={{
                  inputWrapper: "rounded-lg border-small",
                  label: "text-color2",
                  input: "border-0 focus:ring-1 focus:ring-transparent",
                }}
                selectorIcon={<CalendarIcon />}
                defaultValue={parseDate("2024-04-04")}
                value={date}
                onChange={setDate} 
              />*/}
            </div>
            <div className="w-full mt-1">
              <Input
                placeholder="Introduce una driección"
                label="¿Dónde será el evento?"
                labelPlacement="outside"
                name="direccion"
                radius="sm"
                variant="bordered"
                isRequired
                color="primary"
                className="w-full"
                classNames={{
                  inputWrapper: "rounded-lg border-small",
                  label: "text-color2",
                  input: "border-0 focus:ring-1 focus:ring-transparent",
                }}
                onChange={handleChangeInput}
                value={data.direccion}
              />
            </div>
            <div className="w-full mt-1 flex justify-center ">
              <Select
                variant="bordered"
                label="Tipo de Fiesta"
                labelPlacement="outside"
                onChange={handleChangeSelect}
                value={data.tipo_id}
                isRequired
                color="primary"
                classNames={{
                  trigger: "h-[44px] rounded-lg border-small",
                  label: "text-color2",
                  popoverContent: "rounded-md",
                }}
                name="tipo_id"
                className="w-full"
                placeholder={
                  info.partyTypes.length ? "Seleccionar.." : "Loading..."
                }
              >
                {info.partyTypes.map((x) => (
                  <SelectItem key={x.id} value={x.id}>
                    {x.name}
                  </SelectItem>
                ))}
              </Select>
            </div>
            <div className="w-full mt-1">
              <Input
                placeholder="Precio por persona"
                label="Precio"
                labelPlacement="outside"
                name="precio"
                radius="sm"
                variant="bordered"
                isRequired
                color="primary"
                className="w-full"
                classNames={{
                  inputWrapper: "rounded-lg border-small",
                  label: "text-color2",
                  input: "border-0 focus:ring-1 focus:ring-transparent",
                }}
                onChange={handleChangeInput}
                type="number"
                value={data.precio}
              />
            </div>
            <div className="w-full mt-1">
              <Input
                placeholder="Capacidad"
                label="Capacidad"
                labelPlacement="outside"
                name="capacidad"
                radius="sm"
                variant="bordered"
                isRequired
                color="primary"
                className="w-full"
                classNames={{
                  inputWrapper: "rounded-lg border-small",
                  label: "text-color2",
                  input: "border-0 focus:ring-1 focus:ring-transparent",
                }}
                type="number"
                onChange={handleChangeInput}
                value={data.capacidad}
              />
            </div>
            <div className="w-full mt-1 flex justify-start ">
              {/* <Select
                variant="bordered"
                placeholder="Seleccionar.."
                label="Visibilidad"
                labelPlacement="outside"
                onChange={handleChangeSelect}
                value={data.publica}
                isRequired
                color="primary"
                classNames={{
                  trigger: "h-[44px] rounded-lg border-small",
                  label: "text-color2",
                  popoverContent: "rounded-md",
                }}
                name="publica"
                className="w-full"
              >
                <SelectItem key="false" value="false">
                  Privada
                </SelectItem>
                <SelectItem key="true" value="true">
                  Pública
                </SelectItem>
              </Select> */}
              <CustomRadioGroup
                title="Tipo de Acceso"
                required
                name="publica"
                value={data.publica}
                showVisbility={false}
                onChange={handleChangeInput}
                items={[
                  { label: "Privada", value: "false" },
                  { label: "Pública", value: "true" },
                ]}
              />
            </div>
            <div className="w-full mt-1 flex justify-center ">
              <Select
                variant="bordered"
                placeholder="Seleccionar.."
                label="Estado"
                labelPlacement="outside"
                onChange={handleChangeSelect}
                value={data.estado}
                isRequired
                color="primary"
                classNames={{
                  trigger: "h-[44px] rounded-lg border-small",
                  label: "text-color2",
                  popoverContent: "rounded-md",
                }}
                name="estado"
                className="w-full"
              >
                <SelectItem key="false" value="false">
                  Oculta
                </SelectItem>
                <SelectItem key="true" value="true">
                  Visible
                </SelectItem>
              </Select>
            </div>
            <div className="w-full mt-1 flex justify-center ">
              <Select
                variant="bordered"
                label="Fantasías destacadas"
                labelPlacement="outside"
                onChange={handleChangeSelect}
                value={data.fantasias}
                isRequired
                color="primary"
                classNames={{
                  trigger: "h-[44px] rounded-lg border-small",
                  label: "text-color2",
                  popoverContent: "rounded-md",
                }}
                name="fantasias"
                selectionMode="multiple"
                className="w-full"
                placeholder={
                  info.fantasies.length ? "Seleccionar.." : "Loading..."
                }
              >
                {info.fantasies.map((x) => (
                  <SelectItem key={x.id} value={x.id}>
                    {x.name}
                  </SelectItem>
                ))}
              </Select>
            </div>
            <div className="w-full mt-1 lg:col-span-2 ">
              <Textarea
                id="description"
                name="descripcion"
                isRequired
                variant="bordered"
                placeholder="Descripción"
                label="Agrega una descripción"
                labelPlacement="outside"
                required
                minRows={4}
                color="primary"
                classNames={{
                  inputWrapper: "rounded-lg border-small",
                  label: "text-color2",
                  input: "border-0 focus:ring-1 focus:ring-transparent",
                }}
                onChange={handleChangeInput}
                value={data.descripcion}
              />

              <div className="mt-4 w-full">
                <CustomCheckBox isRequired checked={check} onChange={onCheck}>
                  <p>
                    He leído y acepto los{" "}
                    <Link
                      to="https://loouth.com/terminos-y-condiciones"
                      className="underline underline-offset-2 cursor-pointer"
                    >
                      Términos y Condiciones
                    </Link>
                  </p>
                </CustomCheckBox>
              </div>
            </div>
          </div>

          <div className="w-full grid grid-cols-1 lg:grid-cols-2 justify-center mt-3 gap-4 px-6 mb-5">
            <SecondaryButton
              onClick={onCancel}
              disabled={loading}
              className=" w-full self-end"
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              loading={loading}
              type="submit"
              className=" w-full self-end"
            >
              Aceptar
            </PrimaryButton>
          </div>
        </form>
      </ModalContent>
    </Modal>
  );
}
