import {
  Card,
  CardBody,
  CardFooter,
  Modal,
  ModalContent,
  Image,
  Pagination,
  Skeleton,
  Input,
  Button,
} from "@nextui-org/react";
import SecondaryButton from "../common/SecondaryButton";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import { Party } from "../../interfaces/Party";
import { Link } from "react-router-dom";
import { FormEvent, useEffect, useState } from "react";
import { Pagination as PaginationData } from "../../interfaces/pagination";
import { getUsers } from "../../api/user";
import { User } from "../../interfaces/Login";
import { useAuthStore } from "../../stores/authStore";
import PrimaryButton from "../common/PrimaryButton";
import { requestJoinParty } from "../../api/party";
import { toast } from "react-toastify";
import { SearchIcon } from "../../assets/icons/SearchIcon";
// getUsers
export default function PartyUsersModal({
  isOpen = false,
  closeModal = () => {},
  party,
  setPartyData,
}: {
  isOpen: boolean;
  closeModal?: () => void;
  party: Party;
  setPartyData: (party: Party) => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const authData = useAuthStore((state) => state.user);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState<PaginationData>({
    current_page: 0,
    last_page: 0,
    total: 0,
    per_page: 1,
  });

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    setSearch(data.value);
  };

  const getData = async (page: number) => {
    setIsLoading(true);

    const filters = {
      name: search ?? "",
    };
    const res = await getUsers(page, filters);
    setUsers(res.info.data[0].data);
    setPagination({
      current_page: res?.info?.data[0].current_page,
      last_page: res?.info?.data[0].last_page,
      total: res?.info?.data[0].total,
      per_page: res?.info?.data[0].per_page,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (authData) getData(1);
  }, [authData]);

  const onPageChange = async (page: number) => {
    getData(page);
  };
  const [isSendingRequest, setIsSendingRequest] = useState(0);

  const handleRequestJoin = async (user: User) => {
    setIsSendingRequest(user.id);
    const payload = {
      fiesta_id: party.id,
      user_id: user.id,
    };
    const res = await requestJoinParty(payload);
    // console.log(res);
    if (res.info && res.error == "false" && party && user) {
      toast.success("Usuario invitado con éxito");
      setPartyData({
        ...party,
        solicitudes: [
          ...party.solicitudes,
          {
            id: res.info.data[0].id,
            user: user,
            estado: "pendiente",
            invitacion: 1,
          },
        ],
      });
    } else {
      if (
        res.error == "true" &&
        res.info.message == "This user was invited before"
      ) {
        toast.error(
          "No puedes invitar este usuario, debido a una previa solicitud rechazada"
        );
      } else toast.error("Ha ocurrido un error");
    }
    setIsSendingRequest(0);
  };
  const isRequest = (id: number) => {
    const request = party.solicitudes.find((req) => req.user.id == id);
    if (request?.estado == "pendiente") return true;
    return false;
  };
  const isMember = (id: number) => {
    const user = party.integrantes.find((user) => user.id == id);
    // console.log(party.solicitudes.find((req) => id == 53));
    if (user) return true;
    return false;
  };

  return (
    <Modal
      className=""
      isOpen={isOpen}
      placement="center"
      onClose={closeModal}
      classNames={{
        closeButton: "mt-4 mr-4",
        base: "rounded-xl border-none",
      }}
      closeButton={<CloseIcon />}
      scrollBehavior="inside"
      size="4xl"
    >
      <ModalContent>
        <div className="">
          <div className="flex ">
            <div className="text-start w-full">
              <h3 className="mt-6 font-semibold text-color3 text-xl  px-6">
                Invita usuarios a unirse a {party.nombre}
              </h3>
              <p className="mt-2 font-normal text-[14px] text-color5"></p>
              <form
                onSubmit={(e: FormEvent<HTMLFormElement>) => {
                  e.preventDefault();
                  getData(1);
                }}
                className="px-4"
              >
                <Input
                  className="min-w-sm text-input w-full "
                  id="search"
                  type="text"
                  variant="bordered"
                  label=""
                  startContent={<SearchIcon />}
                  placeholder="Buscar..."
                  value={search}
                  onChange={handleChange}
                  endContent={
                    <Button
                      color="primary"
                      variant="solid"
                      className="rounded-lg "
                      type="submit"
                      size="sm"
                    >
                      Buscar
                    </Button>
                  }
                  classNames={{ inputWrapper: " border-1 rounded-lg" }}
                />
              </form>
              <div className="h-[330px] mt-4 py-2 gap-4 grid  grid-cols-1 sm:grid-cols-3 md:grid-cols-4 overflow-auto w-full  px-6">
                {isLoading
                  ? [0, 0, 0, 0].map((_, i) => (
                      <Card
                        className="w-full h-[200px] space-y-5 p-4 shadow-none border-1 border-gray-100"
                        radius="lg"
                        key={i}
                      >
                        <Skeleton className="rounded-lg">
                          <div className="h-24 rounded-lg bg-default-300"></div>
                        </Skeleton>
                        <div className="space-y-3">
                          <Skeleton className="w-3/5 rounded-lg">
                            <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                          </Skeleton>
                          <Skeleton className="w-4/5 rounded-lg">
                            <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                          </Skeleton>
                          <Skeleton className="w-2/5 rounded-lg">
                            <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                          </Skeleton>
                        </div>
                      </Card>
                    ))
                  : users.map((e) => (
                      <Card
                        shadow="sm"
                        key={e.id}
                        radius="md"
                        className="w-full h-[250px]"
                      >
                        <CardBody className="overflow-visible p-0">
                          <Link to={`/profile/${e.id}`} key={e.id}>
                            <Image
                              shadow="none"
                              radius="md"
                              width="100%"
                              isZoomed
                              alt="alt"
                              className="w-full object-cover h-[140px]"
                              fallbackSrc="/assets/fantasyImage.jpeg"
                              src={
                                e?.profile_path
                                  ? e?.profile_path?.startsWith("http")
                                    ? e?.profile_path
                                    : import.meta.env.VITE_BASE_URL +
                                      e?.profile_path
                                  : undefined
                              }
                            />
                          </Link>
                        </CardBody>
                        <CardFooter className="text-small flex flex-col items-start">
                          <Link to={`/profile/${e.id}`} key={e.id}>
                            <b className="hover:text-primary text-lg">
                              {e.name}
                            </b>
                          </Link>
                          {isRequest(e.id) && (
                            <PrimaryButton className="mt-2 w-full" disabled>
                              Pendiente
                            </PrimaryButton>
                          )}
                          {isMember(e.id) && (
                            <PrimaryButton className="mt-2 w-full" disabled>
                              Integrante
                            </PrimaryButton>
                          )}
                          {party.user_id == e.id && (
                            <PrimaryButton className="mt-2 w-full" disabled>
                              Autor
                            </PrimaryButton>
                          )}
                          {!isRequest(e.id) &&
                            !isMember(e.id) &&
                            party.user_id != e.id && (
                              <PrimaryButton
                                className="mt-2 w-full"
                                onClick={() => handleRequestJoin(e)}
                                loading={isSendingRequest == e.id}
                              >
                                Invitar
                              </PrimaryButton>
                            )}
                        </CardFooter>
                      </Card>
                    ))}
              </div>
              {pagination && pagination?.current_page > 0 && (
                <div className="px-4 mt-3 mb-2 w-full">
                  <div className="flex justify-center">
                    <Pagination
                      showControls
                      total={pagination.last_page}
                      initialPage={1}
                      onChange={onPageChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="p-6 pt-0 md:flex block justify-end ">
            <div className="md:mt-0 mt-4 flex gap-[12px]">
              <SecondaryButton
                className="md:w-auto w-full"
                onClick={closeModal}
              >
                Cancelar
              </SecondaryButton>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
