import { Avatar, CircularProgress } from "@nextui-org/react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { User } from "../../interfaces/Login";
// import { useEffect } from "react";
// import { uploadFile } from "../../api/upload";
// import { toast } from "react-toastify";
// import { AxiosProgressEvent } from "axios";
// import { sendMessageToUser } from "../../api/chat";
import { useChatStore } from "../../stores/chatStore";

export default function ChatBubbleMessage({
  isMine = false,
  content,
  user,
  dateString,
  isMedia = false,
  id,
}: {
  id: string;
  isMine?: boolean;
  content: string;
  user: User;
  dateString: string;
  isMedia?: boolean;
  percentCompleted?: number;
}) {
  // const [isSending, setIsSending] = useState(false);
  // const [isSent, setIsSent] = useState(false);
  // const [hasError, setHasError] = useState(false);
  // const [percentCompleted, setPercentCompleted] = useState(0);
  const pendingMessg = useChatStore((state) => state.pendingMessg);

  // useEffect(() => {
  //   // console.log("file", file);
  //   // if (isMedia && file && toUser && !isSent) {
  //   //   const postFile = async () => {
  //   //     setIsSending(true);
  //   //     setHasError(false);
  //   //     try {
  //   //       const formData = new FormData();
  //   //       if (file && content.startsWith("blob")) {
  //   //         formData.append("files[0]", file, file.name);
  //   //         formData.append("type", "image");
  //   //         formData.append("folder[0]", "chat");
  //   //       }
  //   //       const uploadResponse = await uploadFile(formData, {
  //   //         onUploadProgress: (progressEvent: AxiosProgressEvent) =>
  //   //           setPercentCompleted(
  //   //             Math.round((progressEvent.loaded * 100) / progressEvent.total!)
  //   //           ),
  //   //       });
  //   //       if (uploadResponse?.error == "true" || !uploadResponse) {
  //   //         toast.error(uploadResponse.info.message + " ");
  //   //         setIsSending(false);
  //   //         setHasError(true);
  //   //         return;
  //   //       }
  //   //       const uuid = Math.floor(Math.random() * 10000);
  //   //       const newMessage = await sendMessageToUser(toUser, {
  //   //         content: uploadResponse.info.data[0].chat.file.content,
  //   //         media: "image",
  //   //         uuid: uuid.toString(),
  //   //       });
  //   //       console.log("newMessage", newMessage);
  //   //       setIsSent(true);
  //   //       if (onImageSent)
  //   //         onImageSent(uploadResponse.info.data[0].chat.file.content);
  //   //       setIsSending(false);
  //   //     } catch (error) {
  //   //       setIsSending(false);
  //   //       console.error(error);
  //   //       toast.error("Ha ocurrido un error");
  //   //     }
  //   //   };
  //   //   postFile();
  //   // }
  //   // return () => {
  //   //   if (onImageSent && !isSent) {
  //   //     setIsSent(true);
  //   //     onImageSent(content);
  //   //   }
  //   // };
  //   console.log(percentCompleted);
  // }, [percentCompleted]);

  return (
    <>
      {isMine ? (
        <div className="pr-3 self-end my-2 flex gap-x-2.5">
          <div className="relative  max-w-80 rounded-lg bg-primary text-white text-start break-words">
            <p className="text-xs p-1 px-4 text-end text-gray-100">
              <Link to={`/profile/${user?.id}`}>Tú</Link>
            </p>
            {isMedia ? (
              <div
                className="h-[200px] w-[250px] flex justify-center bg-center bg-cover items-center bg-black/10"
                style={{
                  backgroundImage: ` url(${
                    content
                      ? content.startsWith("http") || content.startsWith("blob")
                        ? content
                        : import.meta.env.VITE_BASE_URL + content
                      : undefined
                  })`,
                }}
              >
                {pendingMessg[id]?.isSending && (
                  <div className="w-full h-full flex justify-center items-center bg-center bg-black/20">
                    <CircularProgress
                      aria-label="Loading..."
                      size="lg"
                      value={pendingMessg[id]?.percentCompleted}
                      color="primary"
                      showValueLabel={true}
                    />
                  </div>
                )}
                {pendingMessg[id]?.hasError && (
                  <div className="w-full h-full flex justify-center items-center bg-center bg-black/20">
                    ERROR
                  </div>
                )}
              </div>
            ) : (
              <div className="p-3 pt-1">{content}</div>
            )}
            <div className="absolute top-0 z-10 right-0">
              <div className="rounded-sm after:absolute after:top-1.5 after:-right-1 after:h-4 after:w-4 after:-rotate-45 after:transform after:after-r-2 after:after-t-2 after:after-primary after:bg-primary"></div>
            </div>
            <p className="text-xs p-1 px-2 text-white/60 text-end">
              <Moment fromNow locale="Es">
                {dateString}
              </Moment>
            </p>
          </div>
          <Link to={`/profile/${user?.id}`}>
            <Avatar
              showFallback
              classNames={{
                base: "bg-zinc-100",
                icon: "text-black/50",
              }}
              src={
                user.profile_path
                  ? user?.profile_path.startsWith("http")
                    ? user?.profile_path
                    : import.meta.env.VITE_BASE_URL + user?.profile_path
                  : undefined
              }
              className="cursor-pointer w-8 h-8"
            />
          </Link>
        </div>
      ) : (
        <div className="pl-3 my-2 flex gap-x-2.5">
          <Link to={`/profile/${user.id}`}>
            <Avatar
              showFallback
              classNames={{
                base: "bg-zinc-100",
                icon: "text-black/50",
              }}
              src={
                user.profile_path
                  ? user?.profile_path.startsWith("http")
                    ? user?.profile_path
                    : import.meta.env.VITE_BASE_URL + user?.profile_path
                  : undefined
              }
              className="cursor-pointer w-8 h-8"
            />
          </Link>

          <div className="relative overflow-visible max-w-md rounded-lg bg-zinc-100 text-black/80 text-start break-words">
            <Link
              to={`/profile/${user.id}`}
              className="text-gray-700 text-xs p-1 px-4 hover:text-gray-600"
            >
              {user.name}
            </Link>
            {isMedia ? (
              <div
                className="p-3 pt-1 h-[200px] w-[250px] flex  justify-center bg-center bg-cover items-center"
                style={{
                  backgroundImage: ` url(${
                    content
                      ? content.startsWith("http")
                        ? content
                        : import.meta.env.VITE_BASE_URL + content
                      : undefined
                  })`,
                }}
              ></div>
            ) : (
              <div className="p-3 pt-1">{content}</div>
            )}
            <p className="text-xs p-1 px-2 text-black/60">
              <Moment fromNow locale="Es">
                {dateString}
              </Moment>
            </p>
            <div className="absolute top-0 left-0 z-10">
              <div className="rounded-sm before:absolute before:top-1.5 before:-left-1 before:h-4 before:w-4 before:-rotate-45 before:transform before:border-l-2 before:border-t-2 before:border-zinc-100 before:bg-zinc-100">
                {" "}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
