import {
  FormEvent,
  ReactNode,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { Chat as ChatData, Message } from "../../interfaces/Chat";
import { useChatStore } from "../../stores/chatStore";
import { getChat, sendMessageOnChat, sendMessageToUser } from "../../api/chat";
import ChatBubbleMessage from "./ChatBubbleMessage";
import { useAuthStore } from "../../stores/authStore";
import ChatInput from "./ChatInput";
import { socket } from "../../utils/socket";

export default function ChatPanel({
  chatSelected,
  name,
  slot,
}: {
  chatSelected: string;
  name?: string;
  slot: ReactNode;
}) {
  const authData = useAuthStore((state) => state.user);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const container = useRef(null);
  const setChatsMessages = useChatStore((state) => state.setChatMessages);
  const chatsMessages = useChatStore((state) => state.chatMessages);
  const chats = useChatStore((state) => state.chats);
  const setChats = useChatStore((state) => state.setChats);
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const newMessages = useChatStore((state) => state.newMessages);
  const sendImage = useChatStore((state) => state.sendImage); //sendImage
  const pendingMessg = useChatStore((state) => state.pendingMessg);

  const [progressEv, setProgressEv] = useState<{
    [key: number]: number | undefined;
  }>({});

  const handleSetChatMessages = (id: number, data: ChatData) => {
    const idx = chatsMessages.findIndex((e) => e?.id == id);
    const messages = chatsMessages;
    if (idx != -1) {
      messages[idx] = data;
      //console.log("mess", messages);
      setChatsMessages(messages);
    } else {
      setChatsMessages([...messages, data]);
    }
    // forceUpdate();
  };

  const getMessages = async () => {
    setIsLoadingMessages(true);
    try {
      const id = parseInt(chatSelected);
      const response = await getChat(id);
      //console.log(response);
      if (response.error != false) {
        const rev = response.info.data["0"]?.messages.data.reverse();
        response.info.data["0"].messages.data = rev;
        console.log("rev", rev);
        let update = true;
        for (let i = 0; i < rev.length; i++) {
          const element = rev[i] as Message;
          if (pendingMessg[element.id]?.isSending) {
            update = false;
            break;
          }
        }
        if (update) {
          handleSetChatMessages(id, response.info.data["0"]);
          setHasNewMessage(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoadingMessages(false);
  };

  useEffect(() => {
    const id = parseInt(chatSelected);
    const chatData = chatsMessages.find((e) => e?.id == id);
    if (
      container.current &&
      chatData &&
      !hasNewMessage &&
      !newMessages.length
    ) {
      //
      (container.current as HTMLDivElement).scrollTo({
        behavior: "instant",
        top: (container.current as HTMLDivElement).scrollHeight,
      });
      setHasNewMessage(false);
    } //, newMessages.length
  }, [chatsMessages, chatSelected, isLoadingMessages]);

  useEffect(() => {
    const chat = chats.find((e) => e.id.toString() == chatSelected);
    if (!chat?.isNewChat && chatSelected) getMessages();
  }, [chatSelected]);

  const chatContent = useMemo(() => {
    if (chatSelected) {
      if (hasNewMessage) forceUpdate();
      const id = parseInt(chatSelected);
      const oldData = chatsMessages.find((e) => e?.id == id);
      // console.log("oldData", oldData);
      if (oldData) return oldData;
    }
    return null;
  }, [
    chatSelected,
    chatsMessages,
    hasNewMessage,
    isLoadingMessages,
    newMessages.length,
  ]);

  const [text, setText] = useState("");
  const [file, setFile] = useState<File | undefined>(undefined);

  const onEmojiClick = (event: any, _: any) => {
    //console.log(event);
    setText(text + event.emoji);
  };

  const handleSendMessage = async (event: FormEvent) => {
    //console.log(event);

    const uuid = Math.floor(Math.random() * 10000);
    event.preventDefault();
    const id = parseInt(chatSelected);
    const chatData = chatsMessages.find((e) => e?.id == id);
    if (chatData) {
      let user = chatData.integrantes.find((e) => e.user_id != authData?.id);
      const chat = {
        ...chatData,
        messages: {
          ...chatData.messages,
          data: [
            ...chatData.messages.data,
            {
              id: uuid,
              user_id: authData?.id ?? 0,
              chat_id: chatData.id,
              content: text,
              media: text.includes("blob:http") ? "image" : "text",
              user: authData!,
              created_at: new Date().toISOString(),
              file: file,
              toUser: user?.user_id,
            },
          ],
        },
      };
      // console.log("filew", file);
      handleSetChatMessages(id, chat);
      const chatMess = chats.find((e) => e.id == id);

      if (chatMess) {
        setChats([
          {
            ...chatMess,
            lastMessage: {
              id: uuid,
              user_id: authData?.id ?? 0,
              chat_id: chatData.id,
              content: text,
              media: text.includes("blob:http") ? "image" : "text",
              user: authData!,
              created_at: new Date().toISOString(),
            },
          },
          ...chats.filter((e) => e.id != id),
        ]);
      }

      setText("");
      setHasNewMessage(true);
      const isNewChat = chatMess?.isNewChat;
      setFile(undefined);
      if (!text.includes("blob")) {
        socket.emit("message", {
          clientId: user?.user_id,
          message: [
            {
              id: (Math.random() * 100) / 100, //TODO: fix this when back is ready
              user_id: authData?.id!,
              chat_id: isNewChat ? undefined : chatData.id,
              content: text,
              media: "text",
              user: authData!,
              uuid: uuid.toString(),
              created_at: new Date().toISOString(),
            },
          ],
        });
        const payload = {
          content: text,
          media: "text",
          uuid: uuid.toString(),
        };
        if (isNewChat && user?.user_id) {
          await sendMessageToUser(user?.user_id, payload);
          // console.log(newMessage);
        } else {
          await sendMessageOnChat(chatData.id, payload);
          // console.log(newMessage);
        }
      } else {
        sendImage(
          uuid.toString(),
          {
            file: file,
            toUser: user?.user_id!,
            chatId: chatData.id,
            content: "",
          },
          (percent) => {
            setProgressEv({
              ...progressEv,
              [uuid]: percent,
            });
          }
        );
      }
    }
  };

  useEffect(() => {
    //|| !newMessages.length
    if (container.current && (hasNewMessage || !newMessages.length)) {
      (container.current as HTMLDivElement).scrollTo({
        behavior: "smooth",
        top: (container.current as HTMLDivElement).scrollHeight,
      });
      setHasNewMessage(false);
    } //, newMessages.length
  }, [chatSelected, hasNewMessage, newMessages.length]);

  return (
    <>
      <div className="w-full p-4 py-2 border-b-1 border-zinc-200 ">
        <div>
          <div className="flex text-lg font-semibold">
            {" "}
            {slot}
            <h2 className="ml-2"> {name ?? "Selecciona un chat"}</h2>
            {isLoadingMessages ? (
              <span className="ml-3 mt-2 text-tiny font-thin">
                actualizando...
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div
        ref={container}
        className="h-[80%] w-full overflow-auto flex flex-col gap-8 border-b-1 border-zinc-200"
      >
        {chatContent ? (
          chatContent.messages.data.map((mess, idx) => (
            <div key={idx} className="w-full flex  flex-col">
              <ChatBubbleMessage
                user={mess.user}
                isMine={mess.user.id == authData?.id}
                dateString={mess.created_at}
                content={mess.content}
                isMedia={mess.media == "image"}
                id={mess.id.toString()}
                percentCompleted={progressEv[mess.id]}
                //file={mess.file}
                //toUser={mess.toUser}
                // onImageSent={(url: string) => {
                //   const id = parseInt(chatSelected);
                //   const chatData = chatsMessages.find((e) => e?.id == id);

                //   if (chatData) {
                //     const data = chatData.messages.data;
                //     const idx = data.findIndex((e) => e.id == mess.id);
                //     if (idx != -1) {
                //       let messJustSent = chatData.messages.data[idx];

                //       messJustSent = {
                //         ...messJustSent,
                //         file: undefined,
                //         content: url,
                //       };
                //       data.splice(idx, 1, messJustSent);
                //       const chat = {
                //         ...chatData,
                //         messages: {
                //           ...chatData.messages,
                //           data: [...data],
                //         },
                //       };
                //       handleSetChatMessages(id, chat);
                //     }
                //   }
                // }}
              />
            </div>
          ))
        ) : isLoadingMessages && !chatContent ? (
          <p className="p-2 text-center w-full">Cargando mensajes...</p>
        ) : (
          <p className="text-center p-2">
            {chatSelected ? "No hay mensajes que leer" : "Comienza a chatear"}
          </p>
        )}
        {/* <div className="w-full flex items-center justify-between gap-1">
                    <Divider className=" w-5/12" />
                    <div className=" w-1/12 text-center">hoy</div>
                    <Divider className=" w-5/12" />
                  </div> */}
      </div>
      <ChatInput
        text={text}
        isDisaled={!chatSelected}
        handleSendMessage={handleSendMessage}
        setText={setText}
        onEmojiClick={onEmojiClick}
        atachFile={setFile}
      />
    </>
  );
}
