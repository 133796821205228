import NavBar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import { useEffect, useState } from "react";
import { Fantasy } from "../interfaces/Fantasy";
import { getFantasies } from "../api/formSelectsData";
import FantasiesList from "../components/fantasy/FantasiesList";

export default function Fantasies() {
  const [info, setInfo] = useState({
    fantasies: [] as Fantasy[],
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);

      const fantasies = await getFantasies();
      setInfo({
        ...info,
        fantasies: fantasies?.info?.data[0] ?? [],
      });
      setIsLoading(false);
    };
    getData();
  }, []);

  return (
    <div className=" h-screen">
      <NavBar />
      <main className="flex items-center  w-full overflow-hidden pt-10 md:pt-18 min-h-[100vh] flex-col">
        <div className="px-4 md:px-14 w-full max-w-8xl">
          <section className="w-full ">
            <div className="mt-2 flex flex-col items-start">
              <h1 className="text-2xl font-semibold">Fantasías</h1>
              <p>
                Aquí encontrarás las fantasías que tienen planeados los usuarios
                Loouth. Publica tus propias fantasías y vive experiencias cerca
                de tí.
              </p>
            </div>
          </section>
        </div>
        <FantasiesList isLoading={isLoading} fantasies={info.fantasies} />
      </main>
      <Footer />
    </div>
  );
}
