import {
  Select,
  SelectItem,
  Button,
  Selection,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  // ButtonGroup,
  Card,
  Pagination,
  DateRangePicker,
  Skeleton,
  DateValue,
  RangeValue,
  User,
} from "@nextui-org/react";
// import BuildingIcon from "../../assets/icons/BuildingIcon";
import { CalendarIcon } from "../../assets/icons/CalendarIcon";
// import { LocationIcon } from "../../assets/icons/LockationIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import PrimaryButton from "../common/PrimaryButton";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { ArrowDroppdownIcon } from "../../assets/icons/ArrowIcon";
import { Filters, Trip } from "../../interfaces/Trip";
import Tripcard from "./Tripcard";
import { Pagination as DataPagination } from "../../interfaces/pagination";
import { parseDate } from "@internationalized/date";
import SecondaryButton from "../common/SecondaryButton";
import { Fantasy } from "../../interfaces/Fantasy";
import { getFantasies } from "../../api/formSelectsData";
import { Marker, Map, InfoWindow } from "@vis.gl/react-google-maps";
import { useDateFormatter } from "@react-aria/i18n";
import { Link } from "react-router-dom";

const INITIAL_CENTER = { lat: 40.416775, lng: -3.70379 };

export default function TripList({
  isLoading,
  trips,
  dateRange,
  onChangeDate,
  total,
  pagination,
  onPageChange,
  filters,
  setFilters,
}: {
  isLoading: boolean;
  trips: Trip[];
  dateRange: RangeValue<DateValue>;
  onChangeDate: (dateRange: RangeValue<DateValue>) => void;
  total?: number;
  pagination?: DataPagination;
  filters: Filters;
  setFilters: (value: Filters) => void;
  onPageChange?: (page: number, orderColumn?: string) => void;
}) {
  const [page, setPage] = useState(pagination?.current_page ?? 1);
  const [selectsData, setSelectsData] = useState({
    fantasies: [] as Fantasy[],
  });
  const [selectedKeys, setSelectedKeys] = useState<Set<string>>(new Set([]));

  const [center, setCenter] = useState(INITIAL_CENTER);

  const onSelectionChange = (key: Selection) => {
    // console.log(key != "all" ? key.keys() : "");
    const selected: string[] = [];

    if (key != "all")
      key.forEach((el) => {
        selected.push(el.toString());
      });
    setSelectedKeys(new Set([...selected]));

    if (onPageChange) {
      if (page == 1) onPageChange(1, selected[0] ?? undefined);
      else setPage(1);
    }
  };
  useEffect(() => {
    // console.log(Array.from(selectedKeys));

    if (onPageChange)
      onPageChange(page, Array.from(selectedKeys)[0] ?? undefined);
  }, [page]);

  useEffect(() => {
    const getSelectsData = async () => {
      const fantasies = await getFantasies();

      setSelectsData({
        fantasies: fantasies.info.data[0],
      });
    };
    getSelectsData();
  }, []);

  const clearFilters = () => {
    setSelectedKeys(new Set([]));
    setFilters({
      ...filters,
      fantasias_id: new Set([]),
      tipo_viaje: new Set([]),
    });

    onChangeDate({
      start: parseDate(new Date().toISOString().split("T")[0]),
      end: parseDate(
        new Date(new Date().setMonth(new Date().getMonth() + 4))
          .toISOString()
          .split("T")[0]
      ),
    });
  };
  const handleChangeSelect = (e: FormEvent<HTMLSelectElement>) => {
    const data = e.target as HTMLSelectElement;

    let aux = data.value.split(",");
    if (aux.length && aux[0] == "") aux = aux.slice(1);
    setFilters({
      ...filters,
      [data.name]: new Set(aux),
    });
    // console.log(filters);
  };

  const markers = useMemo(() => {
    if (!trips.length) return [];
    try {
      const resp = trips.map((trip) => {
        const aux = trip.localizacion.split("|");
        // console.log(aux.length == 2);
        if (aux.length == 2) {
          const lat = parseFloat(aux[0]?.toString());
          const lng = parseFloat(aux[1]?.toString());
          // console.log(lat, lng);
          //setCenter({ lat: lat, lng: lng });
          return {
            trip,
            position: {
              lat: lat,
              lng: lng,
            },
          };
        } else
          return {
            trip,
            position: {
              lat: INITIAL_CENTER.lat,
              lng: INITIAL_CENTER.lng,
            },
          };
      });
      return resp;
    } catch (error) {
      console.error(error);
    }
    return [];
  }, [trips]);

  useEffect(() => {
    if (markers?.length && markers[0]) {
      setCenter(markers[0].position);
    }
  }, [markers]);

  return (
    <>
      <div className=" w-full max-w-8xl px-4 md:px-14 object-contain mt-5  min-h-[300px]">
        <div className="w-full flex-row gap-4 flex border-gray-300 border-1 rounded-2xl ">
          <div className="w-full  min-h-[300px] bg-zinc-300 border-gray-300  flex items-center justify-center rounded-xl border-2 cursor-pointer overflow-hidden">
            <Map
              defaultCenter={center}
              defaultZoom={3}
              gestureHandling={"greedy"}
              disableDefaultUI={true}
              scrollwheel={false}
              zoomControl={true}
            >
              {markers?.map((e, i) => (
                <CustomMarker key={i + 1} {...e}></CustomMarker>
              ))}
            </Map>
          </div>
        </div>
      </div>
      <div className="mt-10 w-full max-w-8xl px-4 md:px-14">
        <section className="w-full flex justify-center">
          <div className=" mt-2 flex flex-col w-full">
            <div className="w-full my-3 grid sm:grid-cols-2 lg:grid-cols-8  gap-4">
              <div className="w-full lg:col-span-2">
                <DateRangePicker
                  label="Seleccione la fecha"
                  labelPlacement="outside"
                  color="primary"
                  radius="sm"
                  variant="bordered"
                  className="w-full"
                  selectorIcon={<CalendarIcon />}
                  value={dateRange}
                  onChange={onChangeDate}
                  classNames={{
                    inputWrapper: " rounded-lg border-small",
                    label:
                      "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                    input: "border-0 focus:ring-1 focus:ring-transparent",
                  }}
                />
              </div>
              <div className="w-full lg:col-span-2">
                <Select
                  name="fantasias_id"
                  selectionMode="multiple"
                  color="primary"
                  variant="bordered"
                  label="Fantasías"
                  labelPlacement="outside"
                  classNames={{
                    trigger: " rounded-lg border-small mt-0.5",
                    label:
                      "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                    popoverContent: "rounded-md",
                  }}
                  className="w-full"
                  onChange={handleChangeSelect}
                  placeholder={"Seleccione"}
                  selectedKeys={filters.fantasias_id}
                >
                  {selectsData.fantasies?.map((item) => (
                    <SelectItem
                      key={item.id.toString()}
                      value={item.id.toString()}
                    >
                      {item.name}
                    </SelectItem>
                  ))}
                </Select>
              </div>
              <div className="w-full lg:col-span-2">
                <Select
                  variant="bordered"
                  label="Tipo de Viaje"
                  labelPlacement="outside"
                  onChange={handleChangeSelect}
                  color="primary"
                  classNames={{
                    trigger: " rounded-lg border-small mt-0.5",
                    label:
                      "text-[14px] font-medium group-data-[filled-within=true]:text-color2",
                    popoverContent: "rounded-md",
                  }}
                  name="tipo_viaje"
                  className="w-full"
                  placeholder="Seleccione"
                  selectedKeys={filters.tipo_viaje}
                >
                  <SelectItem key="0" value="0">
                    Nacional
                  </SelectItem>
                  <SelectItem key="1" value="1">
                    Internacional
                  </SelectItem>
                </Select>
              </div>

              <div className="w-full flex items-end justify-center gap-2 md:justify-end lg:col-span-2">
                <SecondaryButton onClick={clearFilters} className="w-full ">
                  Resetear
                </SecondaryButton>

                <PrimaryButton className="w-full">
                  <div className="flex items-center gap-2">
                    <SearchIcon height={20} width={20} stroke={"white"} />
                    <span className="">Buscar</span>
                  </div>
                </PrimaryButton>
              </div>
            </div>

            <div className="w-full flex justify-between items-center">
              <div className="w-full my-3 text-lg">
                <span className="text-primary font-semibold">
                  {isLoading ? "..." : total}
                </span>{" "}
                resultados encontrados
              </div>
              <div className="flex gap-1">
                <Dropdown className="">
                  <DropdownTrigger>
                    <Button
                      radius="sm"
                      variant="bordered"
                      className="capitalize border-1 border-color1 flex items-center"
                      endContent={<ArrowDroppdownIcon />}
                    >
                      Ordenar por
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu
                    aria-label="Single selection example"
                    variant="flat"
                    disallowEmptySelection
                    selectionMode="single"
                    selectedKeys={selectedKeys}
                    onSelectionChange={onSelectionChange}
                  >
                    <DropdownItem key="fecha_inicio">
                      Fecha de inicio
                    </DropdownItem>
                    <DropdownItem key="fecha_fin">Fecha de fin</DropdownItem>
                    <DropdownItem key="nombre">Nombre</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                {/* <ButtonGroup>
                  {" "}
                  <Button
                    radius="sm"
                    variant="bordered"
                    className="capitalize border-1 border-color1 "
                    isIconOnly
                    aria-label="list-view"
                  >
                    <ListIcon />
                  </Button>
                  <Button
                    radius="sm"
                    variant="bordered"
                    className="capitalize border-1 border-color1 "
                    isIconOnly
                    aria-label="list-view"
                  >
                    <LocationIcon />
                  </Button>
                </ButtonGroup> */}
              </div>
            </div>
            <div className="w-full gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1  pt-3">
              {isLoading ? (
                [0, 0, 0, 0].map((_, i) => (
                  <Card
                    className="w-full flex-col lg:flex-row space-y-5 p-4 lg:gap-3 shadow-none border-gray-100 flex py-4 border-1 flex-grow-1"
                    radius="lg"
                    key={i}
                  >
                    <Skeleton className="rounded-lg lg:w-1/3  h-36">
                      <div className="h-24 w-24 rounded-lg bg-default-300"></div>
                    </Skeleton>
                    <div className="space-y-3 lg:w-2/3">
                      <Skeleton className="w-3/5 rounded-lg">
                        <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                      </Skeleton>
                      <Skeleton className="w-4/5 rounded-lg">
                        <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                      </Skeleton>
                      <Skeleton className="w-2/5 rounded-lg">
                        <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                      </Skeleton>
                    </div>
                  </Card>
                ))
              ) : trips.length ? (
                trips?.map((trip) => <Tripcard trip={trip} key={trip.id} />)
              ) : (
                <p className="mt-2">No hay viajes para mostrar 😕</p>
              )}
            </div>
          </div>
        </section>
      </div>
      {pagination && !isLoading && pagination.last_page > 1 && (
        <div className="px-4 md:px-14 mt-16 w-full max-w-8xl">
          <div className="flex  sm:justify-center">
            <Pagination
              showControls
              page={pagination.current_page}
              total={pagination.last_page}
              initialPage={1}
              onChange={setPage}
            />
          </div>
        </div>
      )}
    </>
  );
}
interface MarkerProps {
  trip: Trip;
  position: {
    lat: number;
    lng: number;
  };
}

function CustomMarker(params: MarkerProps) {
  const [show, setShow] = useState(true);
  const formatter = useDateFormatter({
    dateStyle: "short",
  });
  return (
    <>
      {show && (
        <InfoWindow
          headerContent={
            <Link to={`/profile/${params.trip?.user.id}`}>
              <User
                name={
                  params.trip?.user.name.slice(0, 8) +
                  (params.trip?.user.name.length > 8 ? "..." : "")
                }
                avatarProps={{
                  src: params.trip?.user?.profile_path
                    ? params.trip?.user?.profile_path.startsWith("http")
                      ? params.trip?.user?.profile_path
                      : import.meta.env.VITE_BASE_URL +
                        params.trip?.user?.profile_path
                    : undefined,
                  size: "sm",
                }}
              />
            </Link>
          }
          onClose={() => setShow(!show)}
          position={params.position}
        >
          <div className="flex flex-col mt-0">
            <Link to={`/trips/${params.trip.id}`}>
              <span className="text-primary font-medium">
                {params.trip.nombre}
              </span>
            </Link>

            <span className="text-gray-500 font-medium flex gap-2 mt-1.5 items-center">
              <CalendarIcon width={15} stroke={"#A24BFD"} />{" "}
              {params.trip.fecha_fin && params.trip.fecha_inicio
                ? formatter.formatRange(
                    new Date(params.trip.fecha_inicio),
                    new Date(params.trip.fecha_fin)
                  )
                : "--"}
            </span>
          </div>
        </InfoWindow>
      )}
      <Marker onClick={() => setShow(!show)} position={params.position} />
    </>
  );
}
