import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { HomeData } from "../interfaces/homeData";
import { Post } from "../interfaces/Post";

interface NewComment {
  post: number;
  count: number;
}

interface HomeState {
  home: HomeData;
  newPost: Post | undefined;
  newComment: NewComment | null;
  newFantasyPost: Post | undefined;
  setHome: (data: HomeData) => void;
  resetHome: () => void;
  setNewPost: (data: Post | undefined) => void;
  setNewComment: (data: NewComment | null) => void;
  setNewFantasyPost: (data: Post | undefined) => void;
}
const initialState = {
  global: {
    amigos: 0,
    nombre: "",
    perfil: "",
    portada: "",
    reacciones: 0,
    visitas: 0,
  },
  visitantes: [],
  amigos: [],
  nuevos_usuarios: [],
  posts: [],
  posts_photo: [],
  posts_video: [],
  fiestas: [],
};

export const useHomeStore = create<HomeState>()(
  devtools(
    persist(
      (set) => ({
        home: initialState,
        newPost: undefined,
        newFantasyPost: undefined,
        newComment: null,
        setHome: (data) => set(() => ({ home: data })),
        resetHome: () => set(() => ({ home: initialState })),
        setNewPost: (data) => set(() => ({ newPost: data })),
        setNewComment: (data) => set(() => ({ newComment: data })),
        setNewFantasyPost: (data) => set(() => ({ newFantasyPost: data })),
      }),
      { name: "HomeStore" }
    )
  )
);
