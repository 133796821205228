import { Modal, ModalContent } from "@nextui-org/react";
import SecondaryButton from "../common/SecondaryButton";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import { ShareSocial } from "react-share-social";
import { useAuthStore } from "../../stores/authStore";

const style = {
  root: {
    color: "#A24BFD !important",
  },
  copyContainer: {
    border: "1.5px solid #A24BFD",
    background: "white",
    borderRadius: "10px",
    color: "#A24BFD !important",
  },
  copyUrl: {
    color: "#344054",
  },
  copyIcon: {
    color: "#A24BFD",
  },
};

export default function ShareModal({
  isOpen = false,
  closeModal = () => {},
}: {
  isOpen: boolean;
  closeModal?: () => void;
}) {
  const onclose = () => {
    closeModal();
  };
  //referido
  const authData = useAuthStore((state) => state.user);
  const url = () =>
    `${window.location.origin}/register?code=${authData?.referido}`;

  return (
    <Modal
      className=""
      isOpen={isOpen}
      placement="center"
      onClose={onclose}
      classNames={{
        closeButton: "mt-4 mr-4",
        base: "rounded-xl border-none",
      }}
      closeButton={<CloseIcon />}
      scrollBehavior="inside"
      size="xl"
    >
      <ModalContent>
        <div className="">
          <div className="flex ">
            <div className="text-start w-full">
              <h3 className="mt-6 font-semibold text-color3 text-xl  px-6">
                Invita a un amigo
              </h3>
              <p className="mt-2 font-normal text-[14px] text-color5"></p>
              <ShareSocial
                url={url()}
                socialTypes={["whatsapp", "telegram", "email"]}
                style={style}
              />
            </div>
          </div>
          <div className="p-6 pt-0 md:flex block justify-end ">
            <div className="md:mt-0 mt-4 flex gap-[12px]">
              <SecondaryButton className="md:w-auto w-full" onClick={onclose}>
                Cancelar
              </SecondaryButton>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
