import { AxiosError } from "axios";
import { Api } from ".";
import axiosClient from "../utils/axios";

export async function sendFriendRequest(data: any) {
  try {
    return (await axiosClient.post(`${Api.friends.sendFriendRequest}`, data))
      .data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
export async function respondFriendRequest(id: number, data: any) {
  try {
    return (
      await axiosClient.put(`${Api.friends.sendFriendRequest}/${id}`, data)
    ).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
export async function getFriendRequests(enviadas: number) {
  try {
    return (
      await axiosClient.get(
        `${Api.friends.sendFriendRequest}?enviadas=${enviadas}`
      )
    ).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
export async function getFriends(id: string) {
  try {
    return (await axiosClient.get(`${Api.friends.getFriends}/${id}`)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function deleteFriend(id: number) {
  try {
    return (await axiosClient.delete(`${Api.friends.getFriends}/${id}`)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function deleteFriendRequest(id: number) {
  try {
    return (await axiosClient.delete(`${Api.friends.sendFriendRequest}/${id}`))
      .data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
export async function blockUser(data: any) {
  try {
    return (await axiosClient.post(`${Api.block.index}`, data)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function getBlockedUsers(page?: number) {
  try {
    return (await axiosClient.get(`${Api.block.index}?page${page ?? 1}`)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function deleteBlockUser(id: number) {
  try {
    return (await axiosClient.delete(`${Api.block.index}/${id}`)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
