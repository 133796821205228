// import React from "react";
import ReactDOM from "react-dom/client";
import { NextUIProvider } from "@nextui-org/react";
import Pages from "./pages";
import "./index.css";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import ToastConfig from "./components/common/ToastConfig";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "moment/locale/es";
import { APIProvider } from "@vis.gl/react-google-maps";
import * as Sentry from "@sentry/react";
import React from "react";
import ErrorFallback from "./components/common/FallBackError";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN as string,
  environment: "production",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),

    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app\.loouth\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Set moment to ES;
moment().locale("es");

const API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY as string;

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <APIProvider apiKey={API_KEY}>
      <NextUIProvider locale="es-ES">
        <ToastConfig />
        <BrowserRouter>
          <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <Pages />
          </Sentry.ErrorBoundary>
        </BrowserRouter>
      </NextUIProvider>
    </APIProvider>
  </React.StrictMode>
);
