import {
  DateRangePicker,
  DateValue,
  Input,
  Modal,
  ModalContent,
  RangeValue,
  Select,
  SelectItem,
  Textarea,
} from "@nextui-org/react";
import PrimaryButton from "../common/PrimaryButton";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import FileInput from "../form/FileInput";
import { CalendarIcon } from "../../assets/icons/CalendarIcon";
import { FormEvent, useEffect, useRef, useState } from "react";
import { getLocalTimeZone, parseDate } from "@internationalized/date";
import { toast } from "react-toastify";
import { round } from "../../utils/useNumber";
import { uploadFile } from "../../api/upload";
import { createtrip, editTrip } from "../../api/trip";
import { Trip } from "../../interfaces/Trip";
import { ControlPosition, Map, Marker } from "@vis.gl/react-google-maps";
import MapHandler from "./map-handler";
import { CustomMapControl } from "./map-control";
import { useNavigate } from "react-router-dom";

const INITIAL_CENTER = { lat: 40.416775, lng: -3.70379 };
export default function CreateTripModal({
  isOpen = false,
  closeModal = () => {},
  tripToEdid,
  onSuccessEdit,
}: {
  isOpen: boolean;
  closeModal?: () => void;
  onSuccessCreateTrip: (trip: Trip) => void;
  tripToEdid?: Trip;
  onSuccessEdit?: () => void;
}) {
  const [data, setData] = useState({
    nombre: tripToEdid ? tripToEdid.nombre : "",
    place: tripToEdid ? tripToEdid.place : "",
    descripcion: tripToEdid ? tripToEdid.descripcion : "",
    fecha_inicio: tripToEdid ? tripToEdid.fecha_inicio : "",
    fecha_fin: tripToEdid ? tripToEdid.fecha_fin : "",
    localizacion: tripToEdid ? tripToEdid.localizacion : "",
    tipo_viaje: tripToEdid ? tripToEdid.tipo_viaje : "",
    imagen: tripToEdid ? tripToEdid.imagen : "",
  });

  const inputRef = useRef(null);
  const [fileUrl, setFileUrl] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [customSearch, setCustomSearch] = useState("");
  const [center, setCenter] = useState(INITIAL_CENTER);
  const [initialCenter, setInitialCenter] = useState(INITIAL_CENTER);

  const onCancel = () => {
    setFile(null);
    setFileUrl("");
    closeModal();
    setData({
      nombre: "",
      descripcion: "",
      fecha_inicio: "",
      fecha_fin: "",
      localizacion: "",
      tipo_viaje: "",
      imagen: "",
      place: "",
    });
  };
  const onFileUpload = () => {
    if (inputRef.current) (inputRef.current as HTMLInputElement).click();
  };
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    if (
      e.nativeEvent.target instanceof HTMLInputElement &&
      e.nativeEvent.target.files
    ) {
      // Aquí puedes procesar los archivos seleccionados
      const selectedFiles = Array.from(e.nativeEvent.target.files);
      //console.log(selectedFiles);
      if (selectedFiles.length > 0) {
        setFile(selectedFiles[0]);
      }
      // console.log(file);
    }
  };
  useEffect(() => {
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      // console.log(fileUrl);

      setFileUrl(fileUrl);

      return () => {
        URL.revokeObjectURL(fileUrl);
      };
    }
  }, [file]);

  const setFormData = (name: string, value: string) => {
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleChangeInput = (e: FormEvent<HTMLInputElement>) => {
    const data = e.target as HTMLInputElement;
    //setFormData(data.name as VisibilityNm, data.value);
    setFormData(data.name, data.value);
  };
  const handleChangeSelect = (e: FormEvent<HTMLSelectElement>) => {
    const data = e.target as HTMLSelectElement;
    setFormData(data.name, data.value);
  };
  const [date, setDate] = useState<RangeValue<DateValue>>({
    start: parseDate(new Date().toISOString().split("T")[0]),
    end: parseDate(
      new Date(new Date().setDate(new Date().getDate() + 3))
        .toISOString()
        .split("T")[0]
    ),
  });
  const navigate = useNavigate();

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const maxSize = 7.1;

    if (file && round(file.size / (1024 * 1024)) > maxSize) {
      toast.error(`El archivo no debe superar los ${maxSize} MB`);
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();

      let imgUrl = fileUrl;
      if (file) {
        formData.append("files[0]", file, file.name);
        formData.append("type", "image");
        formData.append("folder[0]", "trips");
        const uploadResponse = await uploadFile(formData);

        if (uploadResponse.error == "true") {
          toast.error(uploadResponse.info.message + " ");
          setLoading(false);
          return;
        }
        imgUrl = uploadResponse.info.data[0].trips.file.content;
      }

      let payload: any = {
        ...data,
        imagen: imgUrl,
        fecha_inicio: date.start.toDate(getLocalTimeZone()),
        fecha_fin: date.end.toDate(getLocalTimeZone()),
        tipo_viaje: data.tipo_viaje == "true",
        localizacion: `${center.lat}|${center.lng}`,
        place: customSearch,
      };

      let responseData: any = undefined;

      if (tripToEdid) {
        payload = {
          ...payload,
          id: tripToEdid.id,
        };
        responseData = await editTrip(payload);
        // console.log(responseData);
      } else {
        responseData = await createtrip(payload);
        // console.log(responseData);
      }

      if (responseData?.error == "true" || !responseData) {
        toast.error(responseData.info.message + " ");
      } else {
        toast.success(responseData.info.message);

        // onSuccessCreateTrip(responseData.info.data[0]);
        setFile(null);
        setFileUrl("");
        setLoading(false);
        // setPercentCompleted(0);
        closeModal();

        if (onSuccessEdit) {
          onSuccessEdit();
        }
        navigate("/trips/" + responseData.info.data[0].id);
      }
    } catch (error) {
      toast.error("Error desconocido");
      console.error(error);
    }
    //onCancel();
    setLoading(false);
  }
  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);

  useEffect(() => {
    if (
      selectedPlace?.geometry?.location?.lat() &&
      selectedPlace?.geometry?.location?.lng()
    ) {
      setCenter({
        lat: selectedPlace?.geometry?.location?.lat(),
        lng: selectedPlace?.geometry?.location?.lng(),
      });
    }
    if (selectedPlace?.formatted_address)
      setCustomSearch(selectedPlace.formatted_address);
  }, [selectedPlace]);

  const getPlaceByLatLng = async (lat: number, lng: number) => {
    const geocoder = new google.maps.Geocoder();
    const results = await geocoder.geocode({ location: { lat, lng } });
    if (results) return results;
  };

  const getPlaceFormated = async (lat: number, lng: number) => {
    const place = await getPlaceByLatLng(lat, lng);

    // console.log(place);
    return place?.results[0].formatted_address;
  };

  useEffect(() => {
    // console.log(authData?.info?.fantasias_id);
    // console.log("tripToEdid", tripToEdid);
    const getData = async () => {
      if (tripToEdid) {
        // console.log("tripToEdidAAAAAAAAAAAA", tripToEdid);
        try {
          const aux = tripToEdid.localizacion.split("|");
          if (aux.length == 2) {
            const lat = parseFloat(aux[0]?.toString());
            const lng = parseFloat(aux[1]?.toString());
            // console.log(lat, lng);
            setCenter({ lat: lat, lng: lng });
            setInitialCenter({ lat: lat, lng: lng });
            const auxPlace = await getPlaceFormated(lat, lng);
            if (auxPlace) setCustomSearch(auxPlace);
          }
          setDate({
            start: parseDate(
              new Date(tripToEdid.fecha_inicio).toISOString().split("T")[0]
            ),
            end: parseDate(
              new Date(tripToEdid.fecha_fin).toISOString().split("T")[0]
            ),
          });
          setData({
            nombre: tripToEdid.nombre,
            descripcion: tripToEdid.descripcion,
            fecha_inicio: tripToEdid.fecha_inicio,
            fecha_fin: tripToEdid.fecha_fin,
            localizacion: tripToEdid.localizacion,
            tipo_viaje: "true",
            imagen: tripToEdid.imagen,
            place: tripToEdid.place,
          });
          setFileUrl(
            tripToEdid.imagen
              ? tripToEdid.imagen.startsWith("http")
                ? tripToEdid.imagen
                : import.meta.env.VITE_BASE_URL + tripToEdid.imagen
              : ""
          );
        } catch (error) {
          console.error(error);
        }
      }
    };
    if (tripToEdid && isOpen) getData();
  }, [isOpen]);

  return (
    <Modal
      className="max-w-[820px]"
      isOpen={isOpen}
      placement="center"
      onClose={onCancel}
      classNames={{ closeButton: "mt-4 mr-4", base: "rounded-xl border-none" }}
      closeButton={<CloseIcon />}
    >
      <ModalContent>
        <div className="py-3">
          <h3 className="font-semibold text-color3 text-xl px-6 my-5">
            Nueva Publicación de Viaje
          </h3>

          <div className="  max-h-[500px] p-6 overflow-y-auto">
            <div className="w-full flex-row gap-4 flex ">
              <div className="w-full min-h-[230px]  bg-zinc-300 flex items-center justify-center rounded-xl border-2 border-color4 text-primary cursor-pointer overflow-hidden">
                <Map
                  className=""
                  defaultCenter={initialCenter}
                  defaultZoom={10}
                  scrollwheel={false}
                  zoomControl={true}
                  gestureHandling={"greedy"}
                  disableDefaultUI={true}
                  onClick={async (e) => {
                    // console.log(e.detail.latLng);
                    if (e.detail.latLng) {
                      const pos = e.detail.latLng;
                      setCenter(pos);
                      const res = await getPlaceFormated(pos.lat, pos.lng);
                      setCustomSearch(res ?? "");
                    }
                  }}
                >
                  <Marker
                    position={center}
                    draggable
                    onDragEnd={async (e) => {
                      const res = await getPlaceFormated(
                        e.latLng?.lat() ?? 0,
                        e.latLng?.lng() ?? 0
                      );
                      setCustomSearch(res ?? "");
                    }}
                    onDrag={(e) =>
                      setCenter({
                        lat: e.latLng?.lat() ?? 0,
                        lng: e.latLng?.lng() ?? 0,
                      })
                    }
                  />
                  <CustomMapControl
                    controlPosition={ControlPosition.TOP}
                    onPlaceSelect={setSelectedPlace}
                    customValue={customSearch}
                  />
                  <MapHandler place={selectedPlace} />
                </Map>
              </div>
            </div>
            <div className="w-full flex-row md:flex-col gap-4 flex">
              <div className="w-full flex-col md:flex-row  gap-4 flex">
                <div className="flex flex-col w-full md:w-1/2 ">
                  <div className="w-full mt-5">
                    {/* onClick={onFileUpload} file={fileUrl} type={type} */}
                    <label className="text-sm pb-2">
                      Foto<span className="text-red-500">*</span>
                    </label>
                    <FileInput
                      onClick={onFileUpload}
                      file={fileUrl}
                      type="image"
                      className=" w-full min-h-[200px] rounded-xl border-2 border-color4 cursor-pointer overflow-hidden"
                    />

                    <input
                      ref={inputRef}
                      className="hidden"
                      type="file"
                      accept="image/*"
                      onChange={handleChange}
                    ></input>
                  </div>
                  <div className="w-full mt-5">
                    <Input
                      placeholder="Nombre"
                      name="nombre"
                      label="Introduce un nombre"
                      labelPlacement="outside"
                      radius="sm"
                      isRequired
                      variant="bordered"
                      color="primary"
                      className="w-full"
                      classNames={{
                        inputWrapper: "rounded-lg border-small",
                        label: "text-color2",
                        input: "border-0 focus:ring-1 focus:ring-transparent",
                      }}
                      onChange={handleChangeInput}
                      value={data.nombre}
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full md:w-1/2 md:justify-between ">
                  <div className="w-full mt-4">
                    <Textarea
                      id="description"
                      name="descripcion"
                      isRequired
                      variant="bordered"
                      placeholder="Descripción"
                      label="Agrega una descripción de viaje"
                      labelPlacement="outside"
                      required
                      minRows={4}
                      color="primary"
                      classNames={{
                        inputWrapper: "rounded-lg border-small",
                        label: "text-color2",
                        input: "border-0 focus:ring-1 focus:ring-transparent",
                      }}
                      onChange={handleChangeInput}
                      value={data.descripcion}
                    />
                  </div>
                  <div className="w-full mt-4">
                    <DateRangePicker
                      label="Fecha desde ~ hasta"
                      labelPlacement="outside"
                      radius="sm"
                      variant="bordered"
                      color="primary"
                      isRequired
                      className="w-full"
                      classNames={{
                        inputWrapper: "rounded-lg border-small",
                        label: "text-color2",
                        input: "border-0 focus:ring-1 focus:ring-transparent",
                      }}
                      selectorIcon={<CalendarIcon />}
                      value={date}
                      onChange={setDate}
                    />
                  </div>

                  {/* <div className="w-full mt-4">
                    <Input
                      placeholder="Ubicación"
                      label="Introduce una ubicación"
                      labelPlacement="outside"
                      name="localizacion"
                      radius="sm"
                      variant="bordered"
                      isRequired
                      color="primary"
                      className="w-full"
                      classNames={{
                        inputWrapper: "rounded-lg border-small",
                        label: "text-color2",
                        input: "border-0 focus:ring-1 focus:ring-transparent",
                      }}
                      onChange={handleChangeInput}
                      value={data.localizacion}
                    />
                  </div> */}
                  <div className="w-full mt-4 flex justify-center ">
                    <Select
                      variant="bordered"
                      placeholder="Seleccionar.."
                      label="Tipo de viaje"
                      labelPlacement="outside"
                      onChange={handleChangeSelect}
                      value={data.tipo_viaje}
                      isRequired
                      color="primary"
                      classNames={{
                        trigger: "h-[44px] rounded-lg border-small",
                        label: "text-color2",
                        popoverContent: "rounded-md",
                      }}
                      name="tipo_viaje"
                      className="w-full"
                      defaultSelectedKeys={
                        tripToEdid
                          ? [tripToEdid.tipo_viaje ? "true" : "false"]
                          : []
                      }
                    >
                      <SelectItem key="false" value="false">
                        Nacional
                      </SelectItem>
                      <SelectItem key="true" value="true">
                        Internacional
                      </SelectItem>
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form
            onSubmit={handleSubmit}
            className="w-full flex justify-center mt-3"
          >
            <PrimaryButton
              loading={loading}
              type="submit"
              className=" w-48 self-end"
            >
              Aceptar
            </PrimaryButton>
          </form>
        </div>
      </ModalContent>
    </Modal>
  );
}
