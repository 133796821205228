import { AxiosError } from "axios";
import { Api } from ".";
import axiosClient from "../utils/axios";

export async function createtrip(data: any) {
  try {
    return (await axiosClient.post(Api.trip.index, data)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
const addFilters = (data: any) => {
  let query = "";
  let count = 0;
  if (data.nombre) {
    query += `?nombre=${data.nombre}`;
    count++;
  }
  if (data.fecha_inicio) {
    query += `${count > 0 ? "&" : "?"}fecha_inicio=${data.fecha_inicio}`;
    count++;
  }
  if (data.fecha_fin) {
    query += `${count > 0 ? "&" : "?"}fecha_fin=${data.fecha_fin}`;
    count++;
  }
  if (data.orderColumn) {
    query += `${count > 0 ? "&" : "?"}orderColumn=${
      data.orderColumn
    }&order=ASC`;
    count++;
  }
  if (data.page) {
    query += `${count > 0 ? "&" : "?"}page=${data.page}`;
    count++;
  }
  if (data.tipo_viaje) {
    query += `${count > 0 ? "&" : "?"}tipo_viaje=${data.tipo_viaje}`;
    count++;
  }
  if (data.user_id) {
    query += `${count > 0 ? "&" : "?"}user_id=${data.user_id}`;
    count++;
  }
  if (data.fantasias_id) {
    query += `${count > 0 ? "&" : "?"}fantasias_id=${data.fantasias_id}`;
  }
  return query;
};

export async function getTrips(data: any) {
  try {
    let query = addFilters(data);
    return (await axiosClient.get(`${Api.trip.index}${query}`)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function getTrip(id: number) {
  try {
    return (await axiosClient.get(`${Api.trip.index}/${id}`)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function editTrip(data: any) {
  try {
    return (await axiosClient.put(`${Api.trip.index}/${data.id}`, data)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function deleteTrip(id: number) {
  try {
    return (await axiosClient.delete(`${Api.trip.index}/${id}`)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}

export async function likeTrip(id: number) {
  try {
    return (await axiosClient.post(`${Api.trip.index}/${id}/like`)).data;
  } catch (error) {
    return (error as AxiosError).response?.data;
  }
}
