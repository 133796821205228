import NavBar from "../components/common/Navbar";
import Footer from "../components/common/Footer";
import { useParams } from "react-router-dom";
import FriendsTab from "../components/friend/FriendsTab";

export default function Friends() {
  const { userId } = useParams();

  return (
    <div className=" h-screen ">
      <NavBar />
      <main className="flex justify-center">
        {userId != undefined && <FriendsTab userId={userId} />}
      </main>
      <Footer />
    </div>
  );
}
